import React from 'react';
import { PrivateRoute } from './privateRoute';

const CounterDashboard = React.lazy(() => import('../pages/Homes/counter/Dashboard/index'));

const counterRoutes = {
    // path: '/module/scheduler/appointments',
    path: '/module/counter/dashboard',
    name: 'Agenda',
    icon: 'mdi mdi-calendar-month',
    children: [
        {
            path: '/module/counter/dashboard',
            name: 'Minha Agenda',
            route: PrivateRoute,
            module: "/counter",
            match: 'exact',
            component: CounterDashboard,
        }
    ]
};

export {
    counterRoutes
};
