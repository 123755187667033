import React, { useContext, useEffect, useState } from 'react';
import "../../../assets/scss/custom/pages/financial/paymentTemplate.scss"
// import logo from '../../../assets/images/logo-mono-beta.svg';
import logo from '../../../assets/images/logo_ERP_Ligth.png';
import black_discont from "../../../assets/images/black_discont.png";

const NewAccountTemplate = ({ children, blackFriday, data }) => {

    return (
        <div className="payment-patern">
            <div className={(data.black || blackFriday) ? "payment-presentation-black" : "payment-presentation"} style={{
                backgroundImage: `url(${data.backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                width: '40%',
                height: '100vh',
                backgroundSize: "cover"
            }}>
                {blackFriday ?
                    <img src={black_discont} alt="logo" className="payment-black-friday" /> :
                    <>
                        <img src={logo} alt="logo" className="payment-logo" />
                        {data.image ? <img src={data.image} alt="logo" /> : null}
                        <span className="payment-text-one">{data.slogan}</span>
                        <span className="payment-logo-description">{data.description}</span>
                        <span className="payment-logo-termos">
                            Ao se cadastrar, você concorda com nossos<br />
                            <a target="blank" className="payment-logo-termos underline" href="/account/term-accept">Termos de Uso e Politica de Privacidade.</a>
                        </span>
                    </>}
            </div>
            <div className="payment-content">
                {children}
            </div>
        </div>
    );
};

export default NewAccountTemplate;
