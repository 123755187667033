import React, { useState, useEffect, useContext } from 'react';
import {
  Row, Col, Card, CardBody, Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import AuthContext from '../../../context/authContext';
import { default as img } from '../../../assets/images/primeiros-passos.png';
import './carousel.scss';

const RenderCarouselCommunication = () => {
  const items = [
    {
      step: 'Passo 01',
      title: 'Contas Correntes',
      icons: ['mdi mdi-poll'],
      text: 'Cadastre as principais contas correntes da sua empresa.'
    },
    {
      step: 'Passo 02',
      title: 'Clientes e Fornecedores',
      icons: ['dripicons-user-group'],
      text: 'Cadastre seus principais clientes e fornecedores.',
    },
    {
      step: 'Passo 03',
      title: 'Contas a Pagar / Receber',
      icons: ['mdi mdi-trending-down', 'mdi mdi-trending-up'],
      text: 'Cadastre seus primeiros compromissos financeiros.',
    },
    {
      step: 'Passo 04',
      title: 'Registro de Pagamentos / Recebimentos',
      icons: ['mdi mdi-currency-usd'],
      text: 'Registre os primeiros pagamentos e recebimentos cadastrados.'
    }
  ]
  const userService = useContext(AuthContext);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      var element1 = document.getElementsByClassName('carousel-control-next-icon')[0];
      element1.classList.add('mdi');
      element1.classList.add('mdi-chevron-right');

      var element2 = document.getElementsByClassName('carousel-control-prev-icon')[0];
      element2.classList.add('mdi');
      element2.classList.add('mdi-chevron-left');

      document.getElementsByClassName('carousel-indicators')[0].innerHTML = '';
    }, 50)
  }, [])

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className="container-content-carousel">
          <Row>
            <Col>
              <div className="actual-step">{item.step}</div>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: '-webkit-center' }}>
              {item.icons.map(x => {
                return <div className="container-icon"><i key={x} className={x} /></div>
              })}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="title">{item.title}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text">{item.text}</div>
            </Col>
          </Row>
        </div>

        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });


  return (
    <>
      <div className="container-carousel">
        <img src={img} alt="logo" width="500" className="image-header" />
        <div style={{ background: '#fff' }}>
          <div className="welcome-user">
            Olá {userService.getUser().nome},
          </div>
          <div className="text-user">
            Aqui vão os primeiros passos para uma gestão simplificada.<br />
            Planeje, acompanhe e organize seu negócio sem a necessidade <br />
            de ser um especialista em finanças para tomar as melhores decisões.
          </div>
        </div>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={false}
        >
          <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} color="black" />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} style={{ background: 'blue', color: 'red' }}>
          </CarouselControl>
        </Carousel>
      </div>
    </>
  )
}
export default RenderCarouselCommunication;