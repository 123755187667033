import {
    DetectFlagCard,
    GetCardNumber,
    GetCVVCreditCard,
    GetDueDateCreditCard,
    GetNumbers,
    maskCpfCnpj,
} from '../../../helpers/Utils';

export const PaymentCardMeta = [
    {
        label: 'Nome Impresso no Cartão',
        name: 'holder_name',
        mask: (e) => {
            return e.toUpperCase();
        },
    },
    {
        label: 'CPF / CNPJ',
        name: 'registry_code',
        mask: maskCpfCnpj,
    },
    {
        label: 'Número do Cartão',
        name: 'card_number',
        mask: GetCardNumber,
    },
    {
        label: 'Data de Validade (MM/AAAA)',
        name: 'card_expiration',
        mask: GetDueDateCreditCard,
    },
    {
        label: 'Codigo de Segurança',
        name: 'card_cvv',
        mask: GetCVVCreditCard,
    },
];

export const MounthPaymentBody = (data, user, plan_id) => {
    return {
        PaymentProfile: {
            holder_name: data.holder_name,
            card_expiration: data.card_expiration,
            card_number: GetNumbers(data.card_number),
            card_cvv: data.card_cvv,
            payment_method_code: 'credit_card',
            payment_company_code: DetectFlagCard(GetNumbers(data.card_number)),
        },
        Costumer: {
            name: user.nome,
            email: user.email,
            registry_code: GetNumbers(data.registry_code),
        },
        Plan_id: plan_id,
        User_id: user.usuario_id,
    };
};

export const VerifyData = (data) => {
    const { holder_name, card_expiration, card_number, card_cvv } = data;

    if (!holder_name || !card_expiration || !card_number || !card_cvv) {
        return false;
    }
    return true;
};

export const PlanGratuito = {
    title: 'Gratuito',
    ids: [],
    //subTitle: "",
    principalText:
        'Conheça o Simplific Gestão, o sistema de gestão financeira feito para micro e pequenos empresários.',
    value: 0,
    buttonNone: true,
    id: [],
    data: [
        {
            text: 'Emissão de nota fiscal',
            value: false,
        },
        {
            text: 'Emissão de boleto',
            value: false,
        },
        {
            text: 'Anexo de documentos / fotos',
            value: false,
        },
        {
            text: '1 Usuário',
            value: true,
        },
    ],
};

export const PlanCPF = {
    title: 'CPF',
    ids: ['A04940C8-9439-4257-868B-AF3B1E2EE2F3', 'D1039338-2743-4079-8416-E5D0EE3C9D99'],
    //subTitle: "",
    principalText:
        'Para autônomos e profissionais liberais que querem iniciar um controle financeiro do seu negócio.                           ',
    value: 19,
    id: [],
    data: [
        {
            text: 'Emissão de nota fiscal',
            value: false,
        },
        {
            text: 'Emissão de boleto',
            value: false,
        },
        {
            text: 'Anexo de documentos / fotos',
            value: true,
        },
        {
            text: 'Até 2 usuários',
            value: true,
        },
    ],
};

export const PlanMei = {
    title: 'Mei',
    ids: ['B8377BFE-2E95-4483-9E70-18E84BFD6420', 'D6601749-3F1A-4802-893C-434ECD56BF11'],
    //subTitle: "",
    principalText:
        'Para micro e pequenos empresários que fazem parte do MEI e querem um sistema de gestão simples e fácil de usar.',
    value: 49,
    id: [],
    data: [
        {
            text: 'Emissão de nota fiscal',
            value: true,
        },
        {
            text: 'Emissão de boleto',
            value: true,
        },
        {
            text: 'Anexo de documentos / fotos',
            value: true,
        },
        {
            text: 'Usuários ilimitados',
            value: true,
        },
    ],
};

export const PlanoPessoaFisica = {
    title: 'Pessoa Física',
    ids: ['95FAF389-C1B4-4614-8F9D-0DF462DAE23B', 'D103BEE2-0730-4BDE-9CE1-E7073AACDC80'],
    //subTitle: "",
    principalText: 'Para autônomos e profissionais liberais que querem iniciar um controle financeiro do seu negócio.',
    value: 39,
    id: [],
    data: [
        {
            text: 'Fluxo de Caixa',
            value: true,
        },
        {
            text: 'Contas a Pagar e Receber',
            value: true,
        },
        {
            text: 'Dashboards Inteligentes',
            value: true,
        },
        {
            text: 'Simplific Dicas',
            value: true,
        },
        {
            text: 'Boletim Semanal',
            value: true,
        },
        {
            text: 'Anexo de Documentos',
            value: true,
        },
        {
            text: 'Usuários Ilimitados',
            value: true,
        },
        {
            text: 'Emissão de nota fiscal (NFS-e)',
            value: false,
        },
        {
            text: 'Emissão de boleto',
            value: false,
        },
    ],
};

export const PlanoPessoaJuridica = {
    title: 'Pessoa Jurídica',
    ids: ['85D8CE9B-73D9-427B-B145-71B751C8433B', '49AD5CAC-078D-4432-9292-4116FF86127A'],
    //subTitle: "",
    principalText: 'Para micro e pequenos empresários que querem um sistema de gestão simples e fácil de usar.',
    value: 49,
    id: [],
    data: [
        {
            text: 'Fluxo de Caixa',
            value: true,
        },
        {
            text: 'Contas a Pagar e Receber',
            value: true,
        },
        {
            text: 'Dashboards Inteligentes',
            value: true,
        },
        {
            text: 'Simplific Dicas',
            value: true,
        },
        {
            text: 'Boletim Semanal',
            value: true,
        },
        {
            text: 'Anexo de Documentos',
            value: true,
        },
        {
            text: 'Usuários Ilimitados',
            value: true,
        },
        {
            text: 'Emissão de nota fiscal (NFS-e)',
            value: true,
        },
        {
            text: 'Emissão de boleto ( Em breve )',
            value: true,
        },
    ],
};

export const PlanSimplesLow = {
    title: 'Simples Nacional',
    ids: ['AFC5DDBA-51DF-4446-BBF1-20E6338037B4', 'F7B1A21C-116B-45A1-AD09-A87DD3208579'],
    subTitle: 'Até R$ 180 mil',
    principalText:
        'Para empresas, que estão em crescimento e precisam de um sistema de gestão que atenda suas necessidades atuais.',
    value: 99,
    id: [],
    data: [
        {
            text: 'Emissão de nota fiscal',
            value: true,
        },
        {
            text: 'Emissão de boleto',
            value: true,
        },
        {
            text: 'Anexo de documentos / fotos',
            value: true,
        },
        {
            text: 'Usuários ilimitados',
            value: true,
        },
    ],
};

export const PlanSimplesMedium = {
    ...PlanSimplesLow,
    ids: ['0B253CD2-EBEB-4081-82CD-F90CFD2B7A01', '72D2D6A3-01C1-4EC5-9785-6B4781A7F88E'],
    subTitle: 'Até R$ 360 mil',
    value: 199,
};

export const PlanSimplesHigh = {
    ...PlanSimplesLow,
    ids: ['81007B4F-BDF4-4692-86D5-BB1D6F249B50', 'CDB23483-797A-47F6-91FE-44EB0BFC2105'],
    subTitle: 'Até R$ 1,8 milhão',
    principalText:
        'Para empresas que estão em constante desenvolvimento e necessitam de um sistema de gestão que atenda suas necessidades atuais e futuras.',
    value: 299,
};

export const PlanLucroLow = {
    title: 'Lucro Real / Presumido',
    ids: ['E984E077-09B2-43A9-A384-68B3CA6944DF', '4D3CD302-ABC7-4245-8306-CA83A31F4A5D'],
    subTitle: 'Até R$ 1,8 milhão',
    principalText:
        'Para empresas que estão em constante desenvolvimento e necessitam de um sistema de gestão que atenda suas necessidades atuais.',
    value: 399,
    id: [],
    data: [
        {
            text: 'Emissão de nota fiscal',
            value: true,
        },
        {
            text: 'Emissão de boleto',
            value: true,
        },
        {
            text: 'Anexo de documentos / fotos',
            value: true,
        },
        {
            text: 'Usuários ilimitados',
            value: true,
        },
    ],
};

export const PlanLucroMedium = {
    ...PlanLucroLow,
    subTitle: 'de R$ 1,9 até R$ 10 milhões',
    principalText: 'Para empresas que alcançaram grandes faturamentos e querem continuar aumentando sua lucratividade.',
    value: 'Consulte',
};

export const PlanLucroHigh = {
    ...PlanLucroLow,
    subTitle: 'A partir de R$ 10 milhões',
    principalText: 'Para empresas que alcançaram grandes faturamentos e querem continuar aumentando sua lucratividade.',
    value: 'Consulte',
};

// export const PlanGratuitoItems = {
//     name: "Gratuito",
//     value: "R$ 0,00",
//     id: [],
//     items: [
//         {
//             text: "Módulo Finanças",
//             value: true
//         },
//         {
//             text: "Módulo Agenda",
//             value: true
//         },
//         {
//             text: "Insights",
//             value: true
//         },
//         {
//             text: "Dashboards",
//             value: true
//         },
//         {
//             text: "Relatórios",
//             value: true
//         },
//         {
//             text: "Usuários por empresa:",
//             meta: "1",
//             value: true
//         },
//         {
//             text: "Contas correntes por empresa:",
//             meta: "1",
//             value: true
//         },
//         {
//             text: "Agendas por usuário:",
//             meta: "1",
//             value: true
//         },
//         {
//             text: "Categoria de receita e despesas:",
//             meta: "25",
//             value: true
//         },
//         {
//             text: "Suporte via e-mail",
//             value: true
//         },
//         {
//             text: "Anexo por conta",
//             value: false
//         },
//         {
//             text: "Emissão de nota",
//             value: false
//         },
//         {
//             text: "Emissão de boleto",
//             value: false
//         },
//         {
//             text: "Suporte via WhatsApp",
//             value: false
//         },
//         {
//             text: "Fale com o especialista",
//             value: false
//         },
//         {
//             text: "Armazenamento:",
//             meta: "2 MB",
//             value: true
//         }
//     ]

// }

// export const PlanBasicoItems = {
//     name: "Básico",
//     value: "R$ 99,00 / mês",
//     url: "/payment/basico",
//     ids: [
//         "d148458c-c227-48dd-a675-2a20d5307ee7",
//         "c04e8836-518d-4789-9c5d-bb9ab0ee40ce",
//         "6d5f1478-7d26-4816-b8b1-109489a168f8",
//         "98ad12de-0405-4742-a17d-70f840eb06e3"
//     ],
//     items: [
//         {
//             text: "Módulo Finanças",
//             value: true
//         },
//         {
//             text: "Módulo Agenda",
//             value: true
//         },
//         {
//             text: "Insights",
//             value: true
//         },
//         {
//             text: "Dashboards",
//             value: true
//         },
//         {
//             text: "Relatórios",
//             value: true
//         },
//         {
//             text: "Usuários por empresa:",
//             meta: "3",
//             value: true
//         },
//         {
//             text: "Contas correntes por empresa:",
//             meta: "3",
//             value: true
//         },
//         {
//             text: "Agendas por usuário:",
//             meta: "3",
//             value: true
//         },
//         {
//             text: "Categoria de receita e despesas:",
//             meta: "40",
//             value: true
//         },
//         {
//             text: "Suporte via e-mail",
//             value: true
//         },
//         {
//             text: "Anexo por conta: ",
//             meta: "1",
//             value: true
//         },
//         {
//             text: "Emissão de nota:",
//             meta: "10 NFs / mês",
//             breve: true,
//             value: true
//         },
//         {
//             text: "Emissão de boleto:",
//             breve: true,
//             meta: "10 / mês",
//             value: true
//         },
//         {
//             text: "Suporte via WhatsApp",
//             value: false
//         },
//         {
//             text: "Fale com o especialista",
//             value: false
//         },
//         {
//             text: "Armazenamento:",
//             meta: "60 MB",
//             value: true
//         }
//     ]

// }

// export const PlanProoItems = {
//     name: "Pro",
//     value: "R$ 229,00 / mês",
//     url: "/payment/pro",
//     ids: [
//         "c67a3374-f116-41af-bea5-0b334e58c06c",
//         "bac50e66-3d98-4a9e-ab29-1553072999f2"
//     ],
//     items: [
//         {
//             text: "Módulo Finanças",
//             value: true
//         },
//         {
//             text: "Módulo Agenda",
//             value: true
//         },
//         {
//             text: "Insights",
//             value: true
//         },
//         {
//             text: "Dashboards",
//             value: true
//         },
//         {
//             text: "Relatórios",
//             value: true
//         },
//         {
//             text: "Usuários por empresa: ",
//             meta: "5",
//             value: true
//         },
//         {
//             text: "Contas correntes por empresa:",
//             meta: "5",
//             value: true
//         },
//         {
//             text: "Agendas por usuário:",
//             value: true
//         },
//         {
//             text: "Categoria de receita e despesas:",
//             meta: "50",
//             value: true
//         },
//         {
//             text: "Suporte via e-mail",
//             value: true
//         },
//         {
//             text: "Anexo por conta: ",
//             meta: "3",
//             value: true
//         },
//         {
//             text: "Emissão de nota:",
//             meta: "30 NFs / mês",
//             breve: true,
//             value: true
//         },
//         {
//             text: "Emissão de boleto:",
//             meta: "30 / mês",
//             breve: true,
//             value: true
//         },
//         {
//             text: "Suporte via WhatsApp",
//             value: true
//         },
//         {
//             text: "Fale com o especialista",
//             value: true
//         },
//         {
//             text: "Armazenamento:",
//             meta: "100 MB",
//             value: true
//         }
//     ]
// }

export const findPlan = (id, data = []) => {
    const len = data.filter((x) => {
        if (id.toUpperCase() === x.toUpperCase()) {
            return x;
        }
    });
    return len.length > 0;
};

export const findStorageSizeByPlanId = (id) => {
    // if (PlanProoItems.ids.indexOf(id?.toLowerCase()) > -1) {
    //     return  PlanProoItems.items.find(x => x.text == "Armazenamento:").meta;
    // }else if (PlanBasicoItems.ids.indexOf(id?.toLowerCase()) > -1) {
    //     return  PlanBasicoItems.items.find(x => x.text == "Armazenamento:").meta;
    // }else {
    //     return  PlanGratuitoItems.items.find(x => x.text == "Armazenamento:").meta;
    // }
    return 333333;
};
