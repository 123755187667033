import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Alert } from "reactstrap";

import "../../assets/scss/custom/components/Relevo/relevoCustomInfoModal.scss"


const CustomInfoModal = ({ type, header, text, visible, onClose }) => {

    let tipo = type

    switch (tipo) {

        case 1:
            return (
                <Modal
                    zIndex={9999}
                    className="sucess-modal-info"
                    toggle={() => { }}
                    onOpened={() => { }}
                    centered={true}
                    size="xl"
                    style={{ maxWidth: '900px', width: '100%', maxHeight: '100', height: '100%' }}
                    isOpen={visible}>
                    <ModalBody className="modal-notification-sucess-body">
                        <span><i class="mdi mdi-information modal-notification-data-circle-icon"></i></span>
                        <span className="modal-notification-info">{header}</span>
                        <span className="modal-notification-info-text">{text}</span>
                        <center>
                            <Button
                                title="Ok"
                                color="light"
                                style={{ background: "#2c8ef8", color: 'white' }}
                                onClick={() => onClose()}
                                className="confirm-info-button"
                            >Ok</Button>
                        </center>
                    </ModalBody>
                </Modal>
            );



        case 2:
            return (
                <Modal
                    zIndex={9999}
                    className="sucess-modal-info"
                    toggle={() => { }}
                    onOpened={() => { }}
                    size="xl"
                    style={{ maxWidth: '900px', width: '100%', maxHeight: '100', height: '100%' }}
                    isOpen={visible}>
                    <ModalBody className="modal-notification-sucess-body">
                        <span><i class="mdi mdi-information modal-notification-data-circle-icon-danger"></i></span>
                        <span className="modal-notification-danger">{header}</span>
                        <span className="modal-notification-info-text">{text}</span>
                        <center>
                            <Button
                                title="Ok"
                                color="light"
                                style={{ background: "#fa5c7c", color: 'white' }}
                                onClick={() => onClose()}
                                className="confirm-info-button"
                            >Ok</Button>
                        </center>
                    </ModalBody>
                </Modal>
            );

        default:
            return (
                <Modal
                    zIndex={9999}
                    className="sucess-modal"
                    toggle={() => { }}
                    onOpened={() => { }}
                    size="xl"
                    style={{ maxWidth: '900px', width: '100%', maxHeight: '100', height: '100%' }}
                    isOpen={visible}>
                    <ModalBody className="modal-notification-sucess-body">
                        <span><i class="mdi mdi-information modal-notification-data-circle-icon"></i></span>
                        <span className="modal-notification-info">{header}</span>
                        <span className="modal-notification-info-text">{text}</span>
                        <center>
                            <Button
                                title="Ok"
                                color="light"
                                style={{ background: "#2c8ef8", color: 'white' }}
                                onClick={() => onClose()}
                                className="confirm-info-button"
                            >Ok</Button>
                        </center>
                    </ModalBody>
                </Modal>
            );
    }

};

export default CustomInfoModal;
