import {observable} from 'mobx'
import React from 'react'


const transaction = {
    teste : 0
  }

class TransactionStore extends React.Component{
    constructor(){
        super()
        this._transaction = observable.box(transaction)
    }



    get transaction() { return this._transaction.get(); }
    set transaction(value) { this._transaction.set(value); }
}


const store = new TransactionStore()
export default store