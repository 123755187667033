import React from 'react';
export default function ListNoDataRender(element) {
    const noData = (
        <h4 style={{ fontSize: '1em' }}>
            <span className="k-icon k-i-warning" style={{ fontSize: '2.5em' }} />
            <br />
            <br />
            Sem dados encontrados
        </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
}
