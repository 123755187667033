import React, { useState, useContext } from 'react';
import RelevoModal from '../components/Relevo/RelevoModal';
import ModalSupportForm from './ModalSupportForm';
import { Alert } from 'reactstrap';
// import '../assets/scss/custom/pages/financial/talkToSpecialist.scss'
import AuthContext from '../context/authContext';
import { RelevoFreeUserWarn } from '../components/Relevo/UpgradePlan/RelevoFreeUserWarn';
import functionalitiesEnum from '../types/enum/functionalitiesEnum';
import { Loader } from 'react-bootstrap-typeahead';

import '../assets/scss/custom/pages/financial/modalSupport.scss'

const ModalSupport = () => {

  const [openAlert, setOpenAlert] = useState('')

  const [openModal, setOpenModal] = useState(false)
  const [errorConfirm, setErrorConfirm] = useState(false)
  const [errorTalk, setErrorTalk] = useState(false)
  const [loadRequest, setLoadRequest] = useState(false)

  const [responseTalk, setResponseTalk] = useState('')
  const [responseStatusTalk, setResponseStatusTalk] = useState(null)
  const [openCollapse, setOpenCollapse] = useState(false);
  
  const userService = useContext(AuthContext);

  const callbackTalkSupport = (msg, status) => {
    setResponseTalk(msg)
    setResponseStatusTalk(status)
    if (status == 200) setOpenCollapse(false)

    setTimeout(() => {
      setResponseTalk('')
      setResponseStatusTalk(null);
      setOpenModal(false);
    }, 3000)
  }

  const close = () => {
    setOpenModal(false);
  }
  const open = async () => {
    setOpenModal(true)
  }


  return (
    <>
      {/* <li className="notification-list">
        <button class="nav-link dropdown-toggle arrow-none btn btn-link" onClick={open}>
          <span data-toggle="tooltip" data-placement="left" title="Modal Suporte Comercial" id="fale_especialista">
            <i className="mdi mdi-whatsapp noti-icon"></i>
          </span>
        </button>
      </li> */}

      <button class="open-modal-support" onClick={open}>
        <span data-toggle="tooltip" data-placement="left" title="Modal de Suporte Comercial" id="fale_especialista">
          <i className="mdi mdi-headset noti-icon"></i>
        </span>
      </button>

      <RelevoModal
        modalId="talk-modal"
        confirmButtonId="confirm-talk-modal"
        cancelButtonId="cancel-talk-modal"
        isOpen={openModal}
        isError={errorConfirm}
        isOpenAlert={openAlert}
        messageAlert={errorConfirm ? openAlert : 'Enviado com sucesso!'}
        onCancel={close}
        // onConfirm={confirm}
        load={loadRequest}
        showFooter={false}
        className="modal-dialog-centered modal-first-talk-specialist"
      >
        <div style={{ padding: '10px' }}>
          <p style={{ fontWeight: 'bold', color: '#2c60d0' }}>Tem alguma dúvida?</p>
          <p style={{ color: '#2c60d0' }}>Neste canal você pode nos mandar a sua dúvida e responderemos você o mais breve possível.</p>
          {responseTalk && <Alert style={{ marginTop: 20, marginBottom: 20 }} color={responseStatusTalk == 200 ? 'success' : 'danger'}>
            {responseTalk}
          </Alert>}
          <ModalSupportForm callbackConfirm={callbackTalkSupport} onCancel={close} />
        </div>
      </RelevoModal>

    </>
  )
}

export default ModalSupport;