import api from '../services/api';

export const getCommunicationByUrl = async (url = window.location.pathname) => {

    try {
        const { data } = await api.post(`/comunications-erp/comunications/getbyurl`, { url });
        return data.data;
    }
    catch {
        return [];
    }
}

export const updateVisualizedUrl = async (id, url = window.location.pathname) => {
    return await api.put(`/comunications-erp/comunications/update/${id}`, { visualizeddate: new Date() })
        .then(p => {
            return true;
        })
        .catch((error) => {
            return error.response;
        })
}

export const getPopovers = async (url = window.location.pathname) => {
    try {
        const { data } = await api.post(`/comunications-erp/popvers/get-popvers`, { url });
        return data.data;
    } catch {
        return [];
    }
}
 