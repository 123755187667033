import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import { htmlParserTransform } from '../../../utils/htmlParseUtils';

const RenderHtmlCommunication = ({ html }) => {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            {html ? ReactHtmlParser(html, { transform: htmlParserTransform } ) : ''}
        </div >
    )

}


export default RenderHtmlCommunication