import React, { useState, useEffect } from 'react';



const DowngradeScreenAttachment = ({ title, label, textLink, onCLickDownload }) => {


  return (
    <>
      <span className="paragraph1">{title}</span>
      <br />
      <br />
      <p className="paragraph2">{label}</p>
      <br />
      <hr style={{ height: '1px', backgroundColor: '#d5d3d3' }} />
      <a href='#' style={{ textDecoration: 'undescore' }} onClick={onCLickDownload}>{textLink}</a>
    </>
  );
}

export default DowngradeScreenAttachment;