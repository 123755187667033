import React, { useState, useEffect } from 'react';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Switch } from '@progress/kendo-react-inputs';


import ActiveInactiveCell from '../../../../components/Relevo/kendo/table/activeInactiveCell';



const DowngradeSelectionScreen = ({
  title,
  label,
  data,
  quantLimit,
  columnId,
  columnStatus,
  valueActiveStatus,
  columnConfiguration,
  onSelect,
  isValidContinue,
}) => {

  const quantRegistrosAtivos = data.filter(x => x[columnStatus] == valueActiveStatus).length;


  const getCellSwitch = (dataItem) => {
    
    const checked = dataItem[columnStatus] == valueActiveStatus;
    return (
      <>
        <td>
          <Switch
            checked={checked}
            onChange={(e) => {
              onSelect(dataItem[columnId], e.target.value);
            }}
          />
        </td>
      </>
    )
  }

  return (
    <>
      <span className="paragraph1">{title}</span>
      <br />
      <br />
      <p className="paragraph2">{label}</p>
      <br />
      <hr style={{ height: '1px', backgroundColor: '#d5d3d3' }} />
      <p style={{ color: isValidContinue ? '#11d09a' : '#fa5c7c' }}>
        {`${quantRegistrosAtivos}/${quantLimit}`} registros ativos
      </p>
      <Grid data={data}>

        {columnConfiguration.map(x => <Column {...x} />)}

        <Column
          field={columnStatus}
          title="Status"
          cell={(e) => ActiveInactiveCell(e, valueActiveStatus)}
          width="90px"
        />
        <Column
          cell={(e) => getCellSwitch(e.dataItem)} width="100"
        />
      </Grid>

    </>
  )
}

export default DowngradeSelectionScreen;