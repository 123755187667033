// @flow
import React, { Component } from 'react';
import { DropdownMenu, DropdownToggle, DropdownItem, UncontrolledButtonDropdown, Button } from 'reactstrap';
import { ReactSVG } from 'react-svg';

class ListaModulos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ListModules: props.modules,
            moduleActive: props.moduleActive,
            isCounter: props.isCounter
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <UncontrolledButtonDropdown className="mr-1 mb-1 container-menu-dropdown">
                    <DropdownToggle color="#fff" caret={false}>
                        {/* {this.state.moduleActive.title} */}

                        <div className={`${this.state.moduleActive.textClassName} topnav-logo-text`} style={{ display: 'inline-flex' }}>
                            {/* <ReactSVG
                                src={this.state.moduleActive.img}
                                beforeInjection={(svg) => {
                                    svg.classList.add(this.state.moduleActive.svgClassName);
                                }}
                            /> */}
                            <img src={this.state.moduleActive.img} className={this.state.moduleActive.svgClassName} />
                            <span style={{ fontSize: '12px' }}>
                                {this.state.moduleActive.title}
                            </span>
                            <i className="mdi mdi-chevron-down caret-menu-dropdown"></i>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-animated">
                        {this.state.ListModules.map((item, index) => {
                            return (
                                <DropdownItem key={index} href={item.module}>
                                    <a aria-current="page" href={item.module}>
                                        <span className={`dropdown-top-menu-text ${item.textClassName}`}>
                                            {item.title}
                                        </span>
                                    </a>

                                </DropdownItem>
                            );
                        })}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </>
        );
    }
}

export default ListaModulos;
