const firstUser = {
    email: null,
    senha: null,
    nome: null,
    login: null,
    confirmarSenha: null,
    termoAceite: null,
    termo_aceite_id: null,
    idsProdutos: [],
};

export default firstUser;
