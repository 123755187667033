import api from '../services/api';

const controller = '/free-test-erp/freetest';

export const PopulateFreeTestDatabase = async () => {
    return await api
        .post(`${controller}/createFreeTest`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};
