import api from '../services/api';

export const GetAllCategoriesType = async () => {
    try {
        const { data } = await api.get(`/finance-erp/category-type-tree`);
        return data;
    } catch {
        return [];
    }
};

export const PostGroup = async (group) => {
    return await api
        .post(`/finance-erp/category-group`, group)
        .then((p) => {
            return {
                ...p,
                status: true,
            }
        })
        .catch((error) => {
            let retorno = { 
                res: error.response,
                status: false
            };
            
            return retorno;
        });
};

export const GetByIdGroup = async (id = null) => {
    if (id === null) return [];
    try {
        const { data } = await api.get(`/finance-erp/category-group/${id}`);
        return data;
    } catch {
        return [];
    }
};

export const EditGroup = async (id, group) => {
    return await api
        .put(`/finance-erp/category-group/${id}`, group)
        .then((p) => {
            return {
                ...p,
                status: true,
            }
        })
        .catch((error) => {
            let retorno = { 
                res: error.response,
                status: false
            };
            
            return retorno;
        });
};

export const DeleteGroup = async (id) => {
    if (!id) return false;
    return await api
        .delete(`/finance-erp/category-group/${id}`)
        .then((p) => {
            return true;
        })
        .catch((error) => {
            return error.response;
        });
};

export const PostCategory = async (category) => {
    return await api
        .post(`/finance-erp/category`, category)
        .then((p) => {
            return {
                ...p,
                status: true,
            }
            
        })
        .catch((error) => {
            let retorno = { 
                res: error.response,
                status: false
            };
            
            return retorno;
        });
};

export const EditCategory = async (id, category) => {
    return await api
        .put(`/finance-erp/category/${id}`, category)
        .then((p) => {
            return {
                ...p,
                status: true,
            }
        })
        .catch((error) => {
            let retorno = { 
                res: error.response,
                status: false
            };
            
            return retorno;
        });
};

export const GetByIdCategory = async (id = null) => {
    if (id === null) return [];
    try {
        const { data } = await api.get(`/finance-erp/category/${id}`);
        return data;
    } catch {
        return [];
    }
};

export const DeleteCategory = async (id) => {
    if (!id) return false;
    return await api
        .delete(`/finance-erp/category/${id}`)
        .then((p) => {
            return true;
        })
        .catch((error) => {
            return error.response;
        });
};

export const GetAllDRE = async (id) => {
    try {
        const { data } = await api.get(`/finance-erp/DRE/type=${id}`);
        return data;
    } catch {
        return [];
    }
};
