import axios from "axios";
import api from "../services/api";
import { baseUrl } from "../constants/globalConfig";

export const CreateNewPayment = async (body) => {
    try {
        const { data } = await api.post("/ms-billing/payments/create", body);
        return true;
    } catch (e) {
        return false;
    }
};

export const CreateAccountPayment = async (body) => {
    try {
        const { data } = await api.post("/entitlement/cadastrar-com-pagamento", body);

        return {
            status: true,
            token: data?.token
        };
    } catch (e) {
        return {
            status: false,
            message: e.response.data?.message,
            token: null
        };
    }
}

export const RenewPayment = async () => {
    try {
        const { data } = await api.get("/entitlement/usuario/renew-token");
        return {
            status: true,
            token: data.token
        };
    } catch (e) {
        return {
            status: false
        };
    }
};

export const Subscription = async (user, plan) => {
    try {
        const { data } = await api.get(`/ms-billing/credit-card/${user}/${plan}`);
        return {
            status: true,
            data: data
        };
    } catch (e) {
        return {
            status: false,
            data: null
        };
    }
}

export const CancelSubscription = async (user_id, id_plan, reason) => {
    try {
        const url = `/ms-billing/payments/cancel-subscription/${user_id}/${id_plan}/${reason}`;
        const { data } = await api.delete(url);
        
        return true;
    } catch (e) {
        return false;
    }
}

export const ChangeSubscriptionToFree = async (user_id) => {
    try {
        const { data } = await api.delete(`/ms-billing/payments/change-to-free-subscription/${user_id}`);
        
        return true;
    } catch (e) {
        return false;
    }
}

export const RenewSubscription = async (user_id, id_plan) => {
    try {
        const { data } = await api.post(`/ms-billing/payments/renew-subscription/${user_id}/${id_plan}`);
        return true;
    } catch (e) {
        return false;
    }
}

export const GetStorageUsage = async (user_id) => {
    try {
        const { data } = await api.get(`/finance-erp/account/verify-user-total-usage-size/${user_id}`);
        return data;
    } catch (e) {
        return false;
    }
}

export const AlterCreditCard = async (user_id, id_plan, card) => {
    try {
        const { data } = await api.post(`/ms-billing/credit-card/update`, {
            userId: user_id,
            planId: id_plan,
            credit_card: card
        });
        return {
            status: true,
            message: data.message
        };
    } catch (e) {
        return {
            status: false,
            message: e.response.data?.message,
            token: null
        };
    }
}

export const GetAllPlans = async () => {
    try {
        const { data } = await api.get(`/ms-billing/plan/get-all-plans`);
        return data;
    } catch (e) {
        return []
    }
}


export const SendMetricsForMarketing = async (body) => {
    try {
        if (baseUrl === 'https://app.simplificgestao.com') {
            const { data } = await axios.post(
                "https://hook.us1.make.com/ib63opbt3yo52z7mls4lsxuw9gzfg5br", {
                    "Nome": body.nome,
                    "Email": body.email,
                    "Telefone": body.celular,
                    "UTM_Source": body.utm_source,
                    "UTM_Medium": body.utm_medium,
                    "UTM_Campaign": body.utm_campaign,
                    "Etapa": body.step
                }
            );
                
            if (data === 'Accepted') {
                return true;
            }
        }
        return false;
    } catch (e) {
        return false
    }
}