import React, { useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import './../../assets/scss/custom/pages/Auth/login.scss';
// import { default as logo, default as logoDarkRelevo, default as logoSm } from './../../assets/images/logo-beta.svg'
import logoRelevo from './../../assets/images/simplific.png';
import { Button } from '@progress/kendo-react-buttons';
import { RetrieveEmailUser, PostResendMail } from '../../models/auth';
import { Alert } from 'reactstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import RegexValidateOnlySpace from './../../utils/regexValidateOnlySpace';
import SpacesForRequiredFields from './../../utils/spaceForRequired';
import { Label } from '@progress/kendo-react-labels';
import RelevoModal from '../../components/Relevo/RelevoModal';
export default function RecoveryEmailUser() {
    const [min, setMin] = useState(1);
    const [responseStatus, setResponseStatus] = useState({
        message: '',
        succsess: false,
        error: false,
    });

    const form = useForm({
        criteriaMode: 'all',
        shouldUnregister: false,
        defaultValues: { email: null },
    });

    const [load, setLoad] = useState(false);
    const { handleSubmit, control, errors, trigger } = form;

    const [userPending, setUserPending] = useState(false)
    const [isOpenAlert, setIsOpenAlert] = useState(false)
    const [isErrorResend, setIsErrorResend] = useState(false)
    const [errorResendMessage, setErrorResendMessage] = useState(false)
    const [mailUser, setMailUser] = useState()
    const [resendLoad, setResendLoad] = useState()

    const success = <Alert variant="succsess">{responseStatus.message}</Alert>;
    const error = (
        <div class="alert alert-danger" role="alert">
            {responseStatus.message}
        </div>
    );

    var onSubmit = async function (data, e) {
        e.preventDefault();
        setLoad(true);
        setMailUser(data.email)
        const response = await RetrieveEmailUser(data.email);
        if (response.status === 200) {
            setResponseStatus({ ...responseStatus, message: response.data.message, succsess: true });
            resetState(true);
        } else {
            const message = response ? response.data.message : 'Erro ao recuperar e-mail';
            if(message == 'O usuário ainda não foi ativado.'){
                setUserPending(true)
                resetState(false);
            }
            else{
                setResponseStatus({ ...responseStatus, message: message, error: true });
                resetState(false);
            }
            
        }
    };

    function resetState(success) {
        setTimeout(() => {
            if (success) {
                window.location = '/account/login';
            } else {
                setResponseStatus({ ...responseStatus, succsess: false, error: false });
                setLoad(false);
            }
        }, 3000);
    }


    const customBody = <div>
        <p className={'relevo-text-bold-modal'}>Antes, você precisa confirmar a ativação de usuário por e-mail.</p>
        <p>Você recebeu um e-mail para ativação e conclusão do cadastro.</p>
        <p className={'resend-modal-text-blue'}>Por favor, verifique seu e-mail e ative seu usuário.</p>
    </div>


    async function confirmResendMail(mail) {
        setResendLoad(true)
        const data = await PostResendMail(mail)
        if (data.status == 200) {
            setIsOpenAlert(true)
            setTimeout(() => {
                setUserPending(false)
                setIsOpenAlert(false)
                setResendLoad(false)
            }, 2000);
        }
        else {
            setIsOpenAlert(true)
            setErrorResendMessage(data.data.message)
            setIsErrorResend(true)
            setTimeout(() => {
                setResendLoad(false)
                setIsOpenAlert(false)
                setIsOpenAlert(false)
            }, 2000);
        }

    }

    return (
        <>
            <div className="limiter">
                <div className="container-login100">
                    <div>
                        <div className="wrap-login100">
                            <AvForm className="login100-form validate-form" onValidSubmit={handleSubmit(onSubmit)}>
                                <span className="login100-form-title p-b-26">
                                    <img src={logoRelevo} alt="logo" height="63" width="244" />
                                    <br />
                                    <br />
                                    <h3 className="titleForm">Recuperação de senha</h3>
                                </span>

                                {responseStatus.succsess ? success : null}
                                {responseStatus.error ? error : null}

                                <Row className="inputForm">
                                    <Col lg={12}>
                                        <Label>
                                            E-mail <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => (
                                                <Input
                                                    name="email"
                                                    id="email"
                                                    onChange={(e) => {
                                                        onChange(SpacesForRequiredFields(e));
                                                        //Chama validação
                                                        trigger(e.target.name);
                                                    }}
                                                    style={{ width: '100%' }}
                                                    pattern={RegexValidateOnlySpace()}
                                                    required={errors.email ? true : false}
                                                    placeholder="E-mail de cadastro"
                                                    maxLength="150"
                                                />
                                            )}
                                        />
                                        {errors.email && (
                                            <span className="requiredValidator">O campo E-mail é obrigatório</span>
                                        )}
                                    </Col>
                                </Row>

                                <div className="container-login100-form-btn">
                                    <Button
                                        className="k-primary k-button k-grid-edit-command"
                                        type="submit"
                                        disabled={load}
                                        data-toggle="tooltip" 
                                        data-placement="top" 
                                        title="Confirmar">
                                        {!load ? 'Confirmar' : <Loader />}
                                    </Button>
                                </div>
                            </AvForm>
                        </div>
                        <div className="regUser">
                            <a className="linksLogin" href="/account/login">
                                Voltar
                            </a>
                        </div>

                        {userPending ?
                            <RelevoModal
                                modalId="resend-modal"
                                confirmButtonId="confirm-resend-modal"
                                headerMessage="E-mail pendente de ativação"
                                bodyMessage={" "}
                                isOpen={userPending}
                                onConfirm={() => { confirmResendMail(mailUser) }}
                                onCancel={() => { setUserPending(false) }}
                                isOpenAlert={isOpenAlert}
                                messageAlert={isOpenAlert && isErrorResend ? errorResendMessage : 'E-mail enviado com sucesso!'}
                                confirmText={'Enviar novamente'}
                                customBody={customBody}
                                load={resendLoad}
                            />
                            : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
