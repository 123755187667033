import React, { createContext, useState } from "react";
import { modulesSystem } from "./Meta";
export const LayoutContext = createContext();

export default function LayoutProvider({ children }) {

    const [pageTitle, setPageTitle] = useState()
    const [hideSearch, setHideSearch] = useState(false)
    const [topBarModule, setTopBarModule] = useState()
    const [leftSideBarClass, setLeftSideBarClass] = useState()
    const [headChildren, setHeadChildren] = useState()
    const [isModule, setIsModule] = useState(true)
    const [rendered, setRendered] = useState()



    function getModules() {
        const res = modulesSystem.filter(element => {
            //`${window.location.origin}${element.module}` === window.location.href
            
            if (window.location.href.includes(`${element.module}`)) {
                return element;
            }
            // if (`${window.location.origin}${element.module}` === window.location.href) {
            //     return element;
            // }
        });
        if (res.length > 0) {
            return res[0]
        }
        return modulesSystem[0];
    }

    const financialBasics = () => {
        setHideSearch(false);
        setTopBarModule(getModules());
        setLeftSideBarClass("rigth-margin-finance");
        setIsModule(false);
        setRendered(true);
    }

    return (
        <LayoutContext.Provider
            value={
                {
                    financialBasics,

                    hideSearch,
                    setHideSearch,

                    pageTitle,
                    setPageTitle,

                    topBarModule,
                    setTopBarModule,

                    leftSideBarClass,
                    setLeftSideBarClass,

                    headChildren,
                    setHeadChildren,

                    isModule,
                    setIsModule,

                    rendered
                }
            }
        >
            {children}
        </LayoutContext.Provider>
    )

}