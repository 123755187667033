import React from 'react';
import { Route } from 'react-router-dom';
import { AuthConsumer } from '../context/authContext';
import { isMobile } from '../helpers/Utils';
import NotDesktopAlert from '../pages/Homes/financial/notDesktopAlert';

export const PrivateRoute = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) =>
    (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect, hasPermissions, logout, registerLog }) => {
                //Gravar log de determinadas rotas
                if (rest.codeId && rest.codeId !== parseInt(localStorage.getItem('log_code_id'))) {
                    registerLog(rest.codeId);
                    localStorage.setItem('log_code_id', rest.codeId);
                }

                //Redireciona para a rota se tiver permissão e tiver autenticado
                if (rest.roles && !hasPermissions(rest.roles)) {
                    logout();
                } else {
                    if (!!Component && isAuthenticated()) {
                        return <Component {...props} />;
                    } else {
                        signinRedirect();
                        return <span>loading</span>;
                    }
                }
            }}
        </AuthConsumer>
    );


    if (isMobile()) {
        return (
            <NotDesktopAlert>
            </NotDesktopAlert>);
    }

    return <Route {...rest} render={renderFn(component)} />;
};
