import React, { useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import './../../assets/scss/custom/pages/Auth/login.scss';
// import { default as logo, default as logoDarkRelevo, default as logoSm } from './../../assets/images/simplific.png';
import logoRelevo from './../../assets/images/simplific.png';
import { Button } from '@progress/kendo-react-buttons';
import { EditPasswordlUser } from '../../models/auth';
import { Alert } from 'reactstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import RegexValidateOnlySpace from './../../utils/regexValidateOnlySpace';
import newPassword from './../../types/auth/newPassword';
import SpacesForRequiredFields from './../../utils/spaceForRequired';
import { useParams } from 'react-router-dom';
import { Label } from '@progress/kendo-react-labels';
import { PasswordValidationMeta, ConfirmPasswordValidationMeta } from './passwordValidationMeta';

export default function NewPassWordUser() {
    const [min, setMin] = useState(1);
    const [responseStatus, setResponseStatus] = useState({
        message: '',
        succsess: false,
        error: false,
    });

    const [passwordValidation, setPasswordValidation] = useState(PasswordValidationMeta);
    const [confirmPasswordValidation, setConfirmPasswordValidation] = useState(ConfirmPasswordValidationMeta);

    const [configPassword, setConfigPassword] = useState({
        show: false,
        class: 'mdi mdi-eye-off-outline'
    })

    const form = useForm({
        criteriaMode: 'all',
        shouldUnregister: false,
        defaultValues: newPassword,
    });

    const [load, setLoad] = useState(false);
    const { handleSubmit, control, errors, trigger } = form;
    const token = useParams().token;

    const success = <Alert variant="succsess">{responseStatus.message}</Alert>;
    const error = (
        <div class="alert alert-danger" role="alert">
            {responseStatus.message}
        </div>
    );

    var onSubmit = async function (data, e) {
        e.preventDefault();
        setLoad(true);
        if (!validatePassword(data.senha, true)) {
            setLoad(false);
            return;
        }

        if (!validateConfirmPassword(data.confirmarSenha, data.senha, true)) {
            setLoad(false);
            return;
        }

        const response = await EditPasswordlUser(token, data);

        if (response.status === 200) {
            setResponseStatus({ ...responseStatus, message: response.data.message, succsess: true });
            resetState(true);
        } else {
            const message = response ? response.data.message : 'Erro Trocar senha';
            setResponseStatus({ ...responseStatus, message: message, error: true });
            resetState(false);
        }
    };

    function resetState(success) {
        setTimeout(() => {
            if (success) {
                window.location = '/account/login';
            } else {
                setResponseStatus({ ...responseStatus, succsess: false, error: false });
                setLoad(false);
            }
        }, 3000);
    }


    const validatePassword = (text, visible = true, update = true) => {
        let retorno = true;
        if (text.length == 0) {
            let aux = { ...PasswordValidationMeta };
            aux.value = '';
            aux.visible = true;
            aux.validations.forEach(x => {
                x.class = 'none-validated-field';
            })
            if (update) {
                setPasswordValidation(aux);
            }
            retorno = false;
        } else {
            let aux = { ...passwordValidation };
            aux.visible = true;
            aux.value = text;
            aux.validations.forEach(y => {
                let isValidated = y.validate(text);
                y.class = isValidated ? 'validated-field' : 'not-validated-field';
                retorno = retorno && isValidated;

            });
            if (update) {
                setPasswordValidation(aux);
            }
            if (update && aux.confirmPassword.length > 0 && text.length > 0) {
                let validatedConfirmPassword = getValidatedConfirmPassword(aux.confirmPassword, text);
                setConfirmPasswordValidation(validatedConfirmPassword);
            }

        }

        return retorno;
    }

    const getValidatedConfirmPassword = (confirmPassword, password) => {
        let retorno = '';
        let aux = { ...confirmPasswordValidation };
        aux.visible = true;
        aux.validations.forEach(y => {
            let isValidated = y.validate(password || passwordValidation.value, confirmPassword);
            y.class = isValidated ? 'validated-field' : 'not-validated-field';
            retorno = retorno && isValidated;
            aux.visible = !isValidated;
        });

        return aux;
    }

    const validateConfirmPassword = (text, pass = undefined, update = true) => {
        let retorno = true;
        if (text.length == 0) {
            if (update) {
                setConfirmPasswordValidation({ ...ConfirmPasswordValidationMeta, visible: false });
            }
            retorno = false;
        } else {
            let aux = getValidatedConfirmPassword(text, pass || passwordValidation.value);
            retorno = !aux.visible;
            if (update) {
                setConfirmPasswordValidation(aux);
            }
        }
        let password = { ...passwordValidation, confirmPassword: text };
        if (update) {
            setPasswordValidation(password);
        }
        return retorno;
    }

    const togglePassword = () => {
        let aux = { ...configPassword };
        aux.visible = !aux.visible;
        aux.class = aux.visible ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline';
        setConfigPassword(aux);
    }

    return (
        <>
            <div className="limiter">
                <div className="container-login100">
                    <div>
                        <div className="wrap-login100">
                            <AvForm className="login100-form validate-form" onValidSubmit={handleSubmit(onSubmit)}>
                                <span className="login100-form-title p-b-26">
                                    <img src={logoRelevo} alt="logo" height="63" width="244" />
                                    <br />
                                    <br />
                                    <h3 className="titleForm">Trocar Senha</h3>
                                </span>

                                {responseStatus.succsess ? success : null}
                                {responseStatus.error ? error : null}
                                <Row className="inputForm">
                                    <Col lg={12}>
                                        <Label>
                                            Senha <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="senha"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => {
                                                if (value && value.toString().trim().length >= 1 && !passwordValidation.visible) {
                                                    validatePassword(value);
                                                }
                                                return (
                                                    <>
                                                        <Input
                                                            name="senha"
                                                            type={configPassword.visible ? 'text' : 'password'}
                                                            style={{ width: '100%' }}
                                                            placeholder="********"
                                                            onChange={(e) => {
                                                                onChange(SpacesForRequiredFields(e));
                                                                //Chama validação
                                                                validatePassword(e.target.value);
                                                                trigger(e.target.name);
                                                            }}
                                                            maxLength="30"
                                                            pattern={RegexValidateOnlySpace()}
                                                            required={errors.senha ? true : false}
                                                        />
                                                        <span
                                                            class={configPassword.class + ' eye-show-password'}
                                                            onClick={() => togglePassword()}
                                                        >
                                                        </span>
                                                    </>
                                                )
                                            }}
                                        />
                                        {passwordValidation.visible &&
                                            passwordValidation.validations.map(x => {
                                                return (<>
                                                    <span className={x.class}>{x.text}</span><br />
                                                </>)
                                            })
                                        }
                                        {/* {errors.senha && (
                                            <span className="requiredValidator">O campo Senha é obrigatório</span>
                                        )} */}
                                    </Col>
                                </Row>
                                <Row className="inputForm">
                                    <Col lg={12}>
                                        <Label>
                                            Confirmar Senha <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="confirmarSenha"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => (
                                                <>
                                                    <Input
                                                        name="confirmarSenha"
                                                        type={configPassword.visible ? 'text' : 'password'}
                                                        style={{ width: '100%' }}
                                                        placeholder="********"
                                                        onChange={(e) => {
                                                            onChange(SpacesForRequiredFields(e));
                                                            //Chama validação
                                                            validateConfirmPassword(e.target.value)
                                                            trigger(e.target.name);
                                                        }}
                                                        maxLength="30"
                                                        pattern={RegexValidateOnlySpace()}
                                                        required={errors.confirmarSenha ? true : false}
                                                    />
                                                    <span
                                                        class={configPassword.class + ' eye-show-password'}
                                                        onClick={() => togglePassword()}
                                                    >
                                                    </span>
                                                </>

                                            )}
                                        />
                                        {confirmPasswordValidation.visible &&
                                            confirmPasswordValidation.validations.map(x => {
                                                return (<>
                                                    <span className={x.class}>{x.text}</span><br />
                                                </>)
                                            })
                                        }
                                        {/* {errors.confirmarSenha && (
                                            <span className="requiredValidator">
                                                O campo Confirmar senha é obrigatório
                                            </span>
                                        )} */}
                                    </Col>
                                </Row>

                                <div className="container-login100-form-btn">
                                    <Button
                                        className="k-primary k-button k-grid-edit-command"
                                        type="submit"
                                        disabled={load}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Confirmar">
                                        {!load ? 'Confirmar' : <Loader />}
                                    </Button>
                                </div>
                            </AvForm>
                        </div>
                        <div className="regUser">
                            <a className="linksLogin" href="/account/login">
                                Login
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
