import api from '../services/api';

const controller = '/entitlement/usuario';

export const PostGetCompany = async (user) => {
    return await api
        .post(`${controller}/lista-empresas-usuario`, user)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};


export const PostResendMail = async (mail) =>{
    return await api
        .get(`${controller}/enviar-novo-email/${mail}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
}

export const PostLogin = async (user) => {
    return await api
        .post(`${controller}/logar`, user)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const PostLoginFreeSimulation = async () => {
    return await api
        .post(`${controller}/login-teste-gratis`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
}

export const PostGoogleLogin = async (tokenId) => {
    return await api
        .post(`${controller}/google-login/${tokenId}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const PostFacebookLogin = async (tokenId) => {
    return await api
        // .post(`http://localhost:3001${controller}/facebook-login/${tokenId}`)
        .post(`${controller}/facebook-login/${tokenId}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const PostGoogleRegister = async (tokenId) => {
    return await api
        // .post(`http://localhost:3001${controller}/register-with-google/${tokenId}`)
        .post(`${controller}/register-with-google/${tokenId}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const PostFacebookRegister = async (tokenId) => {
    return await api
        // .post(`http://localhost:3001${controller}/register-with-facebook/${tokenId}`)
        .post(`${controller}/register-with-facebook/${tokenId}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const PostIncludeUser = async (user) => {
    return await api
        //.post(`http://localhost:3001${controller}`, user)
        .post(`${controller}`, user)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const ActivateUser = async (token) => {
    return await api
        .get(`${controller}/confirmar-usuario/${token}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const RetrieveEmailUser = async (email) => {
    return await api
        .get(`${controller}/enviar-email-recuperacao-senha/${email}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const EditPasswordlUser = async (token, password) => {
    return await api
        .patch(`${controller}/trocar-senha-com-base-no-token/${token}`, password)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const GetByIdUser = async (id) => {
    return await api
        .get(`${controller}/Obter/${id}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const EditPasswordByIdUser = async (id, password) => {
    return await api
        .patch(`${controller}/trocar-senha/${id}`, password)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const GetAllUser = async (numberPage, perPage, idCompany, incluirDeletados = false) => {
    return await api
        .get(`${controller}/listar/${idCompany}?pagina=${numberPage}&quantidade=${perPage}&incluirDeletados=${incluirDeletados}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Editar usuário de dentro do sistema
export const EditUserSystem = async (idUser, user) => {
    return await api
        .put(`${controller}/${idUser}`, user)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Incluir usuário de dentro sistema
export const PostUserSystem = async (user) => {
    return await api
        .post(`${controller}/cadatrar-por-dentro-do-sistema`, user)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Deletar usuário de dentro do sistema
export const DeleteUserSystem = async (idUser) => {
    return await api
        .delete(`${controller}/deletar/${idUser}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Ativar usuário criado dentro do sistema
export const ActivateUserSystem = async (token, password) => {
    return await api
        .patch(`${controller}/trocar-senha-com-base-no-token-ativar-usuario/${token}`, password)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const EditLastAcces = async (idUser) => {
    return await api
        .patch(`${controller}/remover-primeiro-acesso/${idUser}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const PostAcceptNewTerm = async (data) => {
    return await api
        .patch(`${controller}/aceitar-novo-termo/`, data)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};
