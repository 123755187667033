import React, { useState, useEffect } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import './../../assets/scss/custom/pages/Auth/login.scss';
// import { default as logo, default as logoDarkRelevo, default as logoSm } from './../../assets/images/logo-beta.svg';
import logoRelevo from './../../assets/images/simplific.png';
import { Button } from '@progress/kendo-react-buttons';
import { ActivateUser } from '../../models/auth';
import { Alert } from 'reactstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { useParams } from 'react-router-dom';
import { ProgressBar } from "@progress/kendo-react-progressbars";
import './activationUserStyle.scss';
import stepsEnum from '../../types/enum/useFlowSteps';
import { RegisterUseFlowStep } from '../../models/log';

async function _updateUseFlowStep(){
    const data = {'tag':stepsEnum.cadastro_simplific}
    await RegisterUseFlowStep(data)
}

export default function ActivationUser() {
    const [min, setMin] = useState(1);
    const { value, start, stop, reset } = useInterval(0, 50);
    const [responseStatus, setResponseStatus] = useState({
        message: '',
        succsess: false,
        error: false,
    });


    const [load, setLoad] = useState(true);
    const token = useParams().token;

    useEffect(() => {
        activateToken();
    }, []);

    const success = <Alert variant="succsess">{responseStatus.message}</Alert>;
    const error = (
        <div class="alert alert-danger" role="alert">
            {responseStatus.message}
        </div>
    );

    async function activateToken() {
        //setLoad(true);
        const response = await ActivateUser(token);

        if (response.status === 200) {
            await _updateUseFlowStep()
            setResponseStatus({ ...responseStatus, succsess: true });
            start();
            setTimeout(() => {
                window.location = '/account/login';
            }, 3200)
        } else {
            const message = response ? response.data.message : 'Erro ao efetuar login';
            setResponseStatus({ ...responseStatus, succsess: false });
        }
        setLoad(false);
        // resetState();
    }

    function resetState() {
        setTimeout(() => {
            setResponseStatus({ ...responseStatus, succsess: false, error: false });
            setLoad(false);
            window.location = '/account/login';
        }, 3000);
    }


    return (
        <>
            <div className="limiter">
                <div className="container-login100">
                    <div>
                        <div className="wrap-login100">
                            <span className="login100-form-title p-b-26">
                                <img src={logoRelevo} alt="logo" height="63" width="244" />
                                {!load && responseStatus.succsess &&
                                    <>
                                        <br />
                                        <br />
                                        <span class="k-icon k-i-check-circle icon-check"></span>
                                        <br />
                                        <br />
                                        <span className="span-success">SUCESSO!</span>
                                        <br />
                                        <span className="span-text">Usuário ativado com sucesso!</span>
                                        <br />
                                        <br />
                                        <ProgressBar value={value} labelVisible={false}
                                            progressClassName="progress-bar-reload"
                                        />
                                    </>}
                                {!load && !responseStatus.succsess &&
                                    <>
                                        <br />
                                        <br />
                                        <span class="k-icon k-i-warning icon-warning"></span>
                                        <br />
                                        <br />
                                        <span className="span-error">ERRO!</span>
                                        <br />
                                        <span className="span-text">Houve um erro ao ativar o usuário. Tente novamente mais tarde.</span>
                                    </>}


                                {load &&
                                    <>
                                        <br />
                                        <br />
                                        <span className="span-text">Ativando usuário!</span>
                                        <br />
                                        <br />
                                        <Loader />
                                    </>
                                }
                            </span>

                            {/* {responseStatus.succsess ? success : null}
                            {responseStatus.error ? error : null} */}

                            {/* <div className="container-login100-form-btn">
                                <Button
                                    className="k-primary k-button k-grid-edit-command"
                                    type="button"
                                    disabled={load}
                                    onClick={(e) => activateToken()}>
                                    {!load ? 'Ativar' : <Loader />}
                                </Button>
                            </div> */}
                        </div>
                        {!load && !responseStatus.succsess &&
                            <div className="regUser">
                                <a className="linksLogin" href="https://www.simplificgestao.com/">
                                    Voltar para página inicial
                                </a>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </>
    )

    return (
        <>
            <div className="limiter">
                <div className="container-login100">
                    <div>
                        <div className="wrap-login100">
                            <AvForm className="login100-form validate-form">
                                <span className="login100-form-title p-b-26">
                                    <img src={logoRelevo} alt="logo" height="63" width="244" />
                                    <br />
                                    <br />
                                    <h3 className="titleForm">Ativação de Usuário</h3>
                                </span>

                                {responseStatus.succsess ? success : null}
                                {responseStatus.error ? error : null}

                                <div className="container-login100-form-btn">
                                    <Button
                                        className="k-primary k-button k-grid-edit-command"
                                        type="button"
                                        disabled={load}
                                        onClick={(e) => activateToken()}
                                        data-toggle="tooltip" 
                                        data-placement="top" 
                                        title="Ativar">
                                        {!load ? 'Ativar' : <Loader />}
                                    </Button>
                                </div>
                            </AvForm>
                        </div>
                        <div className="regUser">
                            <a className="linksLogin" href="/account/login">
                                Login
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


function useInterval(initialValue, ms) {
    const [value, setValue] = React.useState(initialValue);
    const intervalRef = React.useRef(null);
    let val = initialValue
    const start = React.useCallback(() => {
        if (intervalRef.current !== null) {
            return;
        }

        intervalRef.current = setInterval(() => {
            if (val < 100) {
                setValue((v) => v + 1.6);
                val = val + 1.6;
            }
        }, ms);
    }, []);
    const stop = React.useCallback(() => {
        if (intervalRef.current === null) {
            return;
        }

        clearInterval(intervalRef.current);
        intervalRef.current = null;
    }, []);
    const reset = React.useCallback(() => {
        setValue(0);
        val = initialValue;
    }, []);
    return {
        value,
        start,
        stop,
        reset,
    };
}