import React from 'react';

import './../../assets/scss/custom/pages/Auth/login.scss';

const ShowMessageTerm = ({className}) => {


  return (
    <>
      <p className={'termoAceite '+className}>
        Ao utilizar o Simplific Gestão, você está de acordo com os{' '}
        <a href="/account/term-accept" target="_blank" className="linkTermo">
          Termos de Uso e Política de Privacidade
        </a>
      </p>
    </>
  )
}

export default ShowMessageTerm;