import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import React, { useContext, useEffect, useState } from 'react';
import { Loader } from 'react-bootstrap-typeahead';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';
import SimplificInput from '../../../components/Relevo/SimplificInput';
import { CreateAccountPayment } from '../../../models/billing';
import { LinkCompanyToAccountant } from '../../../models/counter'
import { GetCreateNewAccountTextByType, LoginMeta, VerifyDataLogin } from './meta';
import "../../../assets/scss/custom/pages/financial/paymentPage.scss";
import NewAccountTemplate from '../../../components/Templates/NewAccountTemplate';
import { Redirect } from '../../../helpers/Utils';
import logo from '../../../assets/images/simplific.png';
import jwt_decode from 'jwt-decode';

const CreateAccountSegment = (props) => {

    const { handleSubmit, control } = useForm({});
    const [load, setLoad] = useState(false);
    const [error, setError] = useState(null);
    const { tipo, params } = props.match.params;


    var objInviteAccount = undefined;
    if (params) {
        objInviteAccount = JSON.parse(decodeURIComponent(params))
    }


    const TEXTOS = GetCreateNewAccountTextByType(tipo)


    async function onSubmit(data) {
        setError(null);

        if (!VerifyDataLogin(data)) {
            setError("Por favor, preencha todos os campos.")
            return;
        }

        if (data.senha !== data.senha_confirm) {
            setError("As senhas não são iguais.")
            return;
        }

        if (data.senha_confirm.length < 8) {
            setError("As senhas devem ter no mínimo 8 caracteres.")
            return;
        }

        if (data.email?.indexOf("@") === -1 || data.email?.indexOf(".") === -1) {
            setError("Por favor, preencha o campo email corretamente.")
            return;
        }

        setLoad(true);
        const create = await CreateAccountPayment(data);

        if (!create.status) {
            setError(create.message);
            setLoad(false);
            return;
        }


        localStorage.setItem('token', JSON.stringify(create.token));
        const usuario = jwt_decode(create.token);

        if (objInviteAccount?.emailInvited != undefined) {
            await LinkCompanyToAccountant(usuario.usuarioDB.empresas[0].empresa_id, objInviteAccount.companyAccountId);
        }
        localStorage.setItem('first_access', JSON.stringify(usuario.usuarioDB.primeiro_acesso));
        setLoad(false);

        Redirect("/mkt/dashboard-2");

    }

    return (
        <NewAccountTemplate data={TEXTOS} >
            <div className="image-mobile-marca">
                <br />
                <br />
                <img src={logo} alt="logo" height="63" width="244" />
                <br />
                <br />
            </div>
            <span className="payment-title text-centered" style={{ fontWeight: "300" }}>
                {TEXTOS.title}
            </span>
            <br />
            <span className="payment-description text-centered gray-description">
                {TEXTOS.titleDescription}
            </span>
            <AvForm onValidSubmit={handleSubmit(onSubmit)} className="payment-form">
                {LoginMeta.map((i) => {

                    var value = undefined;
                    if (i.name == 'email' && objInviteAccount?.emailInvited != undefined) {
                        value = objInviteAccount?.emailInvited;
                    }
                    return (
                        <div className="payment-input-div" >
                            <SimplificInput
                                className="payment-inputs"
                                control={control}
                                label={i.label}
                                name={i.name}
                                width="100%"
                                type={i.type}
                                valueDefault={value}
                                disabled={value == undefined ? false : true}
                            />
                        </div>
                    )
                })}
                {error ? <span className="error-data">{error}</span> : null}
                <Button
                    title="Cadastrar"
                    className="payment-button payment-login"
                    color="primary"
                    disabled={load}
                    type="submit">{!load ? "Cadastrar" : <Loader />}</Button>
            </AvForm>
        </NewAccountTemplate>
    );
};

export default CreateAccountSegment;
