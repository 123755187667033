import React, { useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import './../../assets/scss/custom/pages/Auth/login.scss';
// import { default as logo, default as logoDarkRelevo, default as logoSm } from './../../assets/images/logo-beta.svg';
import logoRelevo from './../../assets/images/simplific.png';
import { Button } from '@progress/kendo-react-buttons';
import { ActivateUserSystem } from '../../models/auth';
import { Alert } from 'reactstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { useParams } from 'react-router-dom';
import RegexValidateOnlySpace from './../../utils/regexValidateOnlySpace';
import newPassword from './../../types/auth/newPassword';
import SpacesForRequiredFields from './../../utils/spaceForRequired';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';

export default function ActivationUserSystem() {
    const [min, setMin] = useState(1);
    const [responseStatus, setResponseStatus] = useState({
        message: '',
        succsess: false,
        error: false,
    });

    const [load, setLoad] = useState(false);
    const token = useParams().token;

    const form = useForm({
        criteriaMode: 'all',
        shouldUnregister: false,
        defaultValues: newPassword,
    });

    const { handleSubmit, control, errors, trigger } = form;

    const success = <Alert variant="succsess">{responseStatus.message}</Alert>;
    const error = (
        <div class="alert alert-danger" role="alert">
            {responseStatus.message}
        </div>
    );

    var onSubmit = async function (data, e) {
        e.preventDefault();
        setLoad(true);

        const response = await ActivateUserSystem(token, data);

        if (response.status === 200) {
            setResponseStatus({ ...responseStatus, message: response.data.message, succsess: true });
            resetState(true);
        } else {
            const message = response ? response.data.message : 'Erro Trocar senha';
            setResponseStatus({ ...responseStatus, message: message, error: true });
            resetState(false);
        }
    };

    function resetState(success) {
        setTimeout(() => {
            if (success) {
                window.location = '/account/login';
            }
            setResponseStatus({ ...responseStatus, succsess: false, error: false });
            setLoad(false);
        }, 3000);
    }

    return (
        <>
            <div className="limiter">
                <div className="container-login100">
                    <div>
                        <div className="wrap-login100">
                            <AvForm className="login100-form validate-form" onValidSubmit={handleSubmit(onSubmit)}>
                                <span className="login100-form-title p-b-26">
                                    <img src={logoRelevo} alt="logo" height="63" width="244" />
                                    <br />
                                    <br />
                                    <h3 className="titleForm">Ativação de Usuário</h3>
                                </span>

                                {responseStatus.succsess ? success : null}
                                {responseStatus.error ? error : null}

                                <Row className="inputForm">
                                    <Col lg={12}>
                                        <Label>
                                            Senha <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="senha"
                                            id="senha"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => (
                                                <Input
                                                    name="senha"
                                                    type="password"
                                                    style={{ width: '100%' }}
                                                    placeholder="********"
                                                    onChange={(e) => {
                                                        onChange(SpacesForRequiredFields(e));
                                                        //Chama validação
                                                        trigger(e.target.name);
                                                    }}
                                                    maxLength="30"
                                                    pattern={RegexValidateOnlySpace()}
                                                    required={errors.senha ? true : false}
                                                />
                                            )}
                                        />
                                        {errors.senha && (
                                            <span className="requiredValidator">O campo Senha é obrigatório</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="inputForm">
                                    <Col lg={12}>
                                        <Label>
                                            Confirmar Senha <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="confirmarSenha"
                                            id="confirmarSenha"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => (
                                                <Input
                                                    name="confirmarSenha"
                                                    type="password"
                                                    style={{ width: '100%' }}
                                                    placeholder="********"
                                                    onChange={(e) => {
                                                        onChange(SpacesForRequiredFields(e));
                                                        //Chama validação
                                                        trigger(e.target.name);
                                                    }}
                                                    maxLength="30"
                                                    pattern={RegexValidateOnlySpace()}
                                                    required={errors.confirmarSenha ? true : false}
                                                />
                                            )}
                                        />
                                        {errors.confirmarSenha && (
                                            <span className="requiredValidator">
                                                O campo Confirmar senha é obrigatório
                                            </span>
                                        )}
                                    </Col>
                                </Row>

                                <div className="container-login100-form-btn">
                                    <Button
                                        className="k-primary k-button k-grid-edit-command"
                                        type="submit"
                                        disabled={load}
                                        data-toggle="tooltip" 
                                        data-placement="top" 
                                        title="Ativar">
                                        {!load ? 'Ativar' : <Loader />}
                                    </Button>
                                </div>
                            </AvForm>
                        </div>
                        <div className="regUser">
                            <a className="linksLogin" href="/account/login">
                                Login
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
