import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Input, Label } from 'reactstrap';


function SimplificInput({ control, label, required, name, width, maxLength, type, className, valueDefault, maskFunction, disabled, placeholder }) {

    function changeMask(params, onChange) {
        let value = params.target.value;
        if (maskFunction) {
            value = maskFunction(value);
        }

        onChange({
            ...params,
            target: {
                ...params.target,
                value: value
            }
        });
    }
    return (
        <div>
            <Label>
                {label} <span className="requiredValidator">*</span>
            </Label>
            <Controller
                defaultValue={valueDefault}
                name={name}
                control={control}
                render={({ onChange, value }) => (
                    <Input
                        value={value}
                        type={type}
                        name={name}
                        style={{ width: width ?? '100%' }}
                        placeholder={placeholder ?? ""}
                        onChange={(e) => changeMask(e, onChange)}
                        maxLength={maxLength ?? "60"}
                        className={className}
                        disabled={disabled}
                    />
                )}
            />
        </div >
    )
}

export default SimplificInput;