import React, { useContext, useEffect, useState } from 'react';
import RelevoModal from '../../../../components/Relevo/RelevoModal';
import "../../../../assets/scss/custom/components/Relevo/relevoSubscriptions.scss";
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import SimplificInput from '../../../../components/Relevo/SimplificInput';
import { PaymentCardMeta, VerifyData } from '../Meta';
import { Loader } from 'react-bootstrap-typeahead';
import { Button, ModalBody } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { AlterCreditCard } from '../../../../models/billing';
import AuthContext from '../../../../context/authContext';

export default function CreditCardModal({ visible, toggle, onSucess, Plan, usuario_id, beforeSubmit, afterSubmit }) {

    const userService = useContext(AuthContext);
    if (!Plan) {
        Plan = userService.getPlanSubscription().Plan;
    }
    if (!usuario_id) {
        usuario_id = userService.getUserId();
    }

    const { handleSubmit, control } = useForm({});
    const [load, setLoad] = useState(false);
    const [error, setError] = useState(null);

    async function submit(data) {
        setError(null);
        if (!VerifyData(data)) {
            setError("Por favor preencha todos os campos.");
        }

        setLoad(true);
        if (beforeSubmit) {
            beforeSubmit()
        }

        const res = await AlterCreditCard(usuario_id, Plan.Id, data);
        if (res.status) {
            onSucess(onSucess);
        } else {
            setError(res?.message);
        }
        if (afterSubmit) {
            afterSubmit();
        }
        setLoad(false);
    }

    return (
        <RelevoModal
            modalId="rebew-modal"
            confirmButtonId="confirm-rebew-modal"
            isOpen={visible}
            headerMessage="Alterar Cartão de Crédito"
            load={load}
            showFooter={false} >
            <ModalBody className="relevo-modal-confirm">
                <AvForm onValidSubmit={handleSubmit(submit)}>
                    {PaymentCardMeta.map((i) => (
                        <div className="payment-inputs-data">
                            <SimplificInput
                                className="payment-inputs"
                                control={control}
                                label={i.label}
                                name={i.name}
                                width="100%"
                                maskFunction={i.mask}
                            />
                        </div>
                    ))}

                    {error ? <span className="error-data">{error}</span> : null}

                    <div style={{marginTop: '20px'}}>
                        <Button
                            title="Alterar"
                            className="payment-button"
                            color="primary"
                            disabled={load}
                            type="submit">
                            {!load ? "Alterar Cartão" : <Loader />}
                        </Button>

                        <Button
                            title="Cancelar"
                            className="payment-button"
                            color="light"
                            disabled={load}
                            onClick={() => toggle(false)}>Cancelar</Button>{' '}
                    </div>
                </AvForm>
            </ModalBody>
        </RelevoModal>
    )
}