import { observable } from 'mobx';
import React from 'react';

const account = {
    createdById: 0,
    institutionTypeId: 0,
    institutionId: 0,
    accountName: null,
    agency: null,
    accountNumber: null,
    status: true,
    openingBalance: 0,
    openingBalanceDate: null,
    creditLimit: null,
    considerCashFlow: false,
    notes: null,
    accountManager: null,
    phone: null,
    email: null,
    accountStatusId: 1,
    dueDatePis: null,
    forecastDatePis: null,
    dueDateConfins: null,
    forecastDateConfins: null,
    dueDateCsll: null,
    forecastDateCsll: null,
    dueDateIr: null,
    forecastDateIr: null,
    dueDateIss: null,
    forecastDateIss: null,
    dueDateInss: null,
    forecastDateInss: null,
    dueDateTaxes: null,
    forecastDateTaxes: null,
    typeRegisterTaxes: 1,
};

class AccountStore extends React.Component {
    constructor() {
        super();
        this._account = observable.box(account);
    }

    get account() {
        return this._account.get();
    }
    set account(value) {
        this._account.set(value);
    }
}

const store = new AccountStore();
export default store;
