import React, { useEffect, useContext, useState } from 'react';
import { Col, Row, Spinner, Button, ModalBody, ModalHeader } from 'reactstrap';


import user_is_free_warning from '../../../assets/images/user_is_free_warn.png';
import user_is_basic_warning from '../../../assets/images/user_is_basic_warn.png';
import user_is_pro_warning from '../../../assets/images/user_is_pro_warn.png';

import RelevoCommunicationCard from '../RelevoCommunicationCard';
import AuthContext from '../../../context/authContext';

import { GetMessagePlan } from './GetMessagePlan';


export const RelevoFreeUserWarn = ({ showWarn, setShowWarn, functionality }) => {

  const userService = useContext(AuthContext);

  let promotionBlackFriday = '';
  const limit = new Date(2021, 11, 1);

  if (new Date() < limit) {
    promotionBlackFriday = `
      <div class="modal-promotion-banner">
        Aproveite a Oferta Especial de BLACK FRIDAY
      </div>
    `
  }

  let imageSrc = user_is_free_warning;
  const plan = userService.getPlan();

  if (plan.Plan.Name.includes('Básico')) {
    imageSrc = user_is_basic_warning;
  }

  if (plan.Plan.Name.includes('Pró')) {
    imageSrc = user_is_pro_warning;
  }

  const modalContent = {
    img: imageSrc,
    title: 'Olá nome_do_usuario_',
    //text: 'Para ter acesso a essa funcionalidade, você precisa subir de plano!',
    text: GetMessagePlan(functionality),
    html: `
        <p style="color: #2c60d0;">
        Conheça os planos do Simplific Gestão e libere as funcionalidades que vão simplificar ainda mais a gestão do seu negócio.
        </p>

        <button onclick="window.open('/account/profile', '_blank').focus();" type="button" class="modal-promotion-button-check-plans">
            Ver Opções de Planos
        </button>
`,
  }
  return (
    <>
      <RelevoCommunicationCard
        type={4}
        comunicationId={new Date().getTime()}
        isOpen={showWarn}
        toggle={() => {
          if (setShowWarn && typeof setShowWarn == 'function') {
            setShowWarn(false);
          }
        }}
        content={JSON.stringify(modalContent)}
      />
    </>
  )
}