import React from 'react';

export const RelevoBadge = ({ text, type }) => {
  //Types possible: success, danger and warning
  return <span className={`badge badge-${type}-lighten badge-default`} data-toggle="tooltip" data-placement="top" title={text}>{text}</span>
  
  // <span className={`badge badge-${type}`}>{text}</span>
}

export const TYPES_BADGE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  SECONDARY: 'secondary',
}
