import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';

import { Button } from '@progress/kendo-react-buttons';

import { CancelSubscription, RenewSubscription, Subscription } from '../../../models/billing';

import Topbar from '../../../components/Topbar';
import welcomeImg from '../../../assets/images/Welcome.png';
import ShowMessageTerm from '../../../pages/auth/ShowMessageTerm';
import CreditCardModal from "../myProfile/Modals/CreditCardModal";



import '../../../assets/scss/custom/pages/Auth/apresentation.scss';

const UpdatePayment = ({ }) => {
  // const token = localStorage.getItem('token_temp');
  // if (!token) {
  //   localStorage.clear();
  //   window.location = '/account/login';
  // }
  const tokenDecoded = jwt_decode(JSON.parse(localStorage.getItem('token')));
  const Plan = tokenDecoded.payment_permissions.Plan;
  const usuario_id = tokenDecoded.usuarioDB.usuario_id;

  const [alterCard, setAlterCard] = useState(false);

  async function initial() {
    const res = await Subscription(usuario_id, Plan.Id);

    window.location = '/home/dashboard';
  }

  return (
    <>
      <Topbar
        hideLogo={false}
        navCssClasses={'topnav-navbar-dark topnav-full-screen'}
        hideSearch={true}
        hideNotifications={true}
        hideProfile={true}
        hideModule={true}
        hideSimplificTips={true}
        hideTalkToSpecialist={true}
      />

      <div className="apresentation" style={{ minHeight: '70vh', marginTop: '80px' }}>
        <img src={welcomeImg} height="300" alt="" style={{ marginTop: '30px' }} />
        <span>Ops!</span>
        <p className="paragraph1">Não conseguimos realizar a cobrança no cartão cadastrado.</p>
        <div style={{ width: '80%' }}>
          <p className="paragraph2">
            Por favor, revise os dados do seu cartão.
          </p>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <Button
            style={{ margin: '5px' }}
            className="k-primary k-button"
            type="button"
            onClick={(e => { setAlterCard(true) })}
            data-toggle="tooltip"
            data-placement="top"
            title="Revisar dados de pagamento">
            Revisar Dados de Pagamento
          </Button>
          {/* <Button
            style={{ margin: '5px' }}
            className="k-primary k-button"
            type="button"
            onClick={(e => { window.location = '/account/downgrade-payment' })}
            data-toggle="tooltip"
            data-placement="top"
            title="Iniciar">
            Iniciar Migração Para Plano Gratuito
          </Button> */}
        </div>
        <CreditCardModal
          visible={alterCard}
          toggle={(e) => setAlterCard(e)}
          onSucess={() => { initial(); setAlterCard(false) }}
          Plan={Plan}
          usuario_id={usuario_id}
        />
      </div>
      <ShowMessageTerm
        className="term-accept-footer"
      />
    </>
  )
}

export default UpdatePayment;