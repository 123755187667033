import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { allFlattenRoutes as routes } from './index';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import AuthLayout from '../layouts/Auth';
import VerticalLayout from '../layouts/LayoutDefault';
import HorizontalLayout from '../layouts/Horizontal';
import DetachedLayout from '../layouts/Detached';
import { LayoutContext } from '../context/LayoutContext';

import { getPopovers } from '../models/communication';
import { getCommunicationByUrl } from '../models/communication';


class Routes extends Component {
    static contextType = LayoutContext;
    state = {
        passLocation: null
    }
    // returns the layout
    getLayout = () => {
        const { isModule } = this.context;

        if (isModule) return AuthLayout;

        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    shouldComponentUpdate(nextProps, nextState) {
        let oldLayout = { ...this.props.layout };
        delete oldLayout.showRightSidebar;
        let newLayout = { ...nextProps.layout };
        delete newLayout.showRightSidebar;
        return (
            JSON.stringify(oldLayout) !== JSON.stringify(newLayout) ||
            JSON.stringify(this.props.user) !== JSON.stringify(nextProps.user)
        );
    }

    async init() {
        const popovers = await getPopovers();
        const communication = await getCommunicationByUrl();

        this.props.appcontext.setState({
            popovers,
            communication,
            modalOpen: communication.map((i) => true)
        });
        this.props.appcontext.forceUpdate();
    }


    componentDidUpdate() {
        const { passLocation } = this.state;
        if (passLocation !== window.location.pathname) {
            this.props.appcontext.setState({
                popovers: [],
                communication: [],
                modalOpen: []
            }, () => {
                this.init();
            });

            this.setState({
                passLocation: window.location.pathname
            })
        }
    }


    render() {
        const Layout = this.getLayout();

        // rendering the router with layout
        return (
            <>
                <BrowserRouter>
                    <LayoutContext.Consumer>
                        {(value) => {
                            return (
                                <Layout {...this.props} {...value}>
                                    <Switch>
                                        {routes.map((route, index) => {
                                            return (
                                                !route.children && (
                                                    <route.route
                                                        key={index}
                                                        path={route.path}
                                                        roles={route.roles}
                                                        exact={route.exact}
                                                        component={route.component}
                                                        codeId={route.codeId}></route.route>
                                                )
                                            );
                                        })}
                                    </Switch>
                                </Layout>
                            );
                        }}

                    </LayoutContext.Consumer>
                </BrowserRouter>
            </>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        layout: state.Layout,
        user: state.Auth.user,
    };
};

export default connect(mapStateToProps, null)(Routes);
