import api from '../services/api';

const controller = '/entitlement';

//Buscar perguntas para empresas
export const Questions = async () => {
    return await api
        .get(`${controller}/perguntas/`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Buscar perguntas por tipo
export const GetQuestionByType = async (type) => {
    return await api
        .get(`${controller}/perguntas/${type}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Buscar interesses para empresas
export const GetInterests = async () => {
    return await api
        .get(`${controller}/interesses/`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Buscar Estados
export const GetState = async () => {
    return await api
        .get(`${controller}/estado/`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Buscar Cidades
export const GetCityByState = async (id) => {
    return await api
        .get(`${controller}/cidade-por-Estado/${id}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Editar empresa
export const EditCompany = async (idCompany, company) => {
    return await api
        .put(`${controller}/empresa/${idCompany}`, company)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Atualizar Endereço da empresa
export const UpdateCompanyAddress = async (idCompany, company) => {
    return await api
        .put(`${controller}/empresa/atualizar-endereco/${idCompany}`, company)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

//Obter empresa
export const GetCompany = async (idCompany) => {
    return await api
        .get(`${controller}/empresa/obter/${idCompany}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const RequestInactivateCompany = async (idCompany, userId) => {
    return await api
        .patch(`${controller}/empresa/solicitar-inativar-empresa/${idCompany}/${userId}`)
        //.patch(`http://localhost:3001${controller}/empresa/solicitar-inativar-empresa/${idCompany}/${userId}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
}

export const DeleteWholeCompany = async (token) => {
    return await api
        .patch(`${controller}/empresa/inativar-empresa/${token}`)
        //.patch(`http://localhost:3001${controller}/empresa/inativar-empresa/${token}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
}

