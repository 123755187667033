import React, { useState, useEffect } from 'react';
import './../../assets/scss/custom/pages/Auth/login.scss';
import SimplificLogoFinal from './../../assets/images/SimplificLogoFinal.png';
import ImgWorkingOnIt from './../../assets/images/ImgWorkingOnIt.png';
import { Button } from '@progress/kendo-react-buttons';
import { PostLoginFreeSimulation } from '../../models/auth';
import { PopulateFreeTestDatabase } from '../../models/freeTest';
import { Loader } from 'react-bootstrap-typeahead';
import './activationUserStyle.scss';
import { NotDesktopAlert } from '../Homes/financial/notDesktopAlert';
import { isMobile } from '../../helpers/Utils';

const LoadingFreeTest = () => {
    const [responseStatus, setResponseStatus] = useState({
        message: '',
        success: false,
        error: false,
    });

    const [load, setLoad] = useState(true);

    useEffect(() => {
        loadFreeTest();
    }, []);

    async function loadFreeTest() {
        const response = await PostLoginFreeSimulation();

        if (response && response.status === 200) {
            localStorage.setItem('token', JSON.stringify(response.data.token));

            var request = await PopulateFreeTestDatabase();
            if (request.status == 200) {
                setResponseStatus({ ...responseStatus, success: true });
                setLoad(false);
            } else {
                setResponseStatus({ ...responseStatus, success: false });
                setLoad(false);
            }
        } else {
            setResponseStatus({ ...responseStatus, success: false });
            setLoad(false);
        }
    }

    function resetState() {
        setTimeout(() => {
            setResponseStatus({ ...responseStatus, success: false, error: false });
            setLoad(false);
            window.location = '/account/login';
        }, 3000);
    }

    if (isMobile()) {
        return <NotDesktopAlert />;
    }

    return (
        <>
            <div className="limiter">
                <div className="container-login100">
                    <div style={{ textAlign: 'center' }}>
                        <img
                            style={{ marginBottom: '15px' }}
                            src={SimplificLogoFinal}
                            alt="logo"
                            height="63"
                            width="244"
                        />
                        <div className="wrap-login100">
                            <span className="login100-form-title p-b-26" style={{ marginBottom: '0px' }}>
                                <img src={ImgWorkingOnIt} alt="logo" height="244" width="244" />
                                {!load && responseStatus.success && (
                                    <>
                                        <br />
                                        <br />
                                        <span class="k-icon k-i-check-circle icon-check"></span>
                                        <br />
                                        <br />
                                        <span className="span-success">SUCESSO!</span>
                                        <br />
                                        <br />
                                        <span className="span-text" style={{ fontSize: '1rem' }}>
                                            Ambiente de Simulação criado com sucesso!
                                        </span>
                                        <span
                                            className="login100-form-title"
                                            style={{ fontSize: '0.825rem', marginBottom: 0 }}>
                                            Vejamos o que você vai encontrar...
                                        </span>
                                        <br />
                                        <Button
                                            className="k-primary k-button"
                                            type="button"
                                            onClick={() =>
                                                // (window.location.href = '/entitlement/account/steps-free-test')
                                                (window.location.href = '/home/dashboard')
                                            }
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Iniciar Simulação"
                                            disabled={load}>
                                            {!load ? 'Iniciar Simulação' : <Loader />}
                                        </Button>
                                        <br />
                                        <br />
                                    </>
                                )}
                                {!load && !responseStatus.success && (
                                    <>
                                        <br />
                                        <br />
                                        <span class="k-icon k-i-warning icon-warning"></span>
                                        <br />
                                        <br />
                                        <span className="span-error">ERRO!</span>
                                        <br />
                                        <span className="span-text">
                                            Houve um erro ao carregar o ambiente de simulação.
                                            <br />
                                            Tente novamente mais tarde.
                                        </span>
                                        <br />
                                        <br />
                                    </>
                                )}

                                {load && (
                                    <>
                                        <br />
                                        <br />
                                        <span className="span-text" style={{ fontSize: '1rem' }}>
                                            Estamos criando um ambiente de simulação para você conhecer melhor o
                                            Simplific.
                                        </span>
                                        <br />
                                        <br />
                                        <span
                                            className="login100-form-title"
                                            style={{ fontSize: '0.825rem', marginBottom: 0 }}>
                                            O carregamento pode demorar em torno de 30 segundos.
                                            <br />
                                            Por favor aguarde.
                                        </span>
                                        <br />
                                        <Loader />
                                        <br />
                                        <br />
                                    </>
                                )}
                            </span>
                        </div>
                        {!load && !responseStatus.success && (
                            <div className="regUser">
                                <a className="linksLogin" href="https://www.simplificgestao.com/">
                                    Voltar para página inicial
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoadingFreeTest;
