import jwt_decode from 'jwt-decode';
import * as jwt from 'jsonwebtoken';
import { RegisterLog } from '../models/log';
import log from '../types/log/log';
import { plansFreeEnum } from '../types/auth/plansFree';

export default class AuthService {
    getTokenDecode() {
        return jwt_decode(JSON.parse(localStorage.getItem('token')));
    }

    getUserIsCounter = () => {
        try {
            const tokenDecoded = this.getTokenDecode();
            return tokenDecoded?.isCounterLogin || tokenDecoded.usuarioDB.empresas.some(x => x.isCounter);
        } catch (err) {
            return false;
        }
    }

    getUser = () => {
        const { usuarioDB } = this.getTokenDecode();
        if (!usuarioDB) {
            return this.signinRedirect();
        }
        return usuarioDB;
    };


    getRazaoSocial = () => {
        const { razao_social } = this.getTokenDecode();
        return razao_social
    }

    getUserId = () => {
        const { usuario_id } = this.getUser();

        return usuario_id;
    };

    getIsFirstAccess = () => {
        return JSON.parse(localStorage.getItem('first_access'));
    };

    getCompanyId = () => {
        const { empresa_da_vez } = this.getTokenDecode();

        return empresa_da_vez;
    };

    getPermissions = () => {
        const { permissoes } = this.getTokenDecode();

        return permissoes;
    };

    hasPassedDueDate = () => {
        const permissionsPay = this.getPlan();
        const user = this.getUser();

        if (new Date(permissionsPay.DueDate) < new Date()
            && !user.empresas?.some(x => x.teste_gratis)
            && !plansFreeEnum.includes(permissionsPay.Plan.Name)) {
            window.location = '/account/update-payment';
            return true;
        }
        return false;
    }

    hasPermissions = (permission) => {
        if (!this.hasPassedDueDate()) {
            if (this.getPermissions().filter((p) => p === permission).length > 0) {
                return true;
            }
            return false;
        }
    };

    getPlanSubscription = () => {
        const { payment_permissions } = this.getTokenDecode();
        return payment_permissions;
    };
    getPlan = () => {
        const { payment_permissions } = this.getTokenDecode();

        return payment_permissions;
    }

    getFunctionalities = () => {
        const payment_permissions = this.getPlan();
        if (payment_permissions.DueDate && payment_permissions.DueDate <= new Date()) {
            return [];
        }
        return payment_permissions.Plan.Permissions;
    }

    getFunctionalityValue = (functionality) => {
        const restrictedFunctionality = this.getFunctionalities().find((p) => p.Identified === functionality);
        if (restrictedFunctionality) {
            var number = parseInt(restrictedFunctionality.Value);
            if (isNaN(number)) {
                return restrictedFunctionality.Value == "true" || restrictedFunctionality.Value == true;
            } else {
                return number;
            }
        } else {
            return false;
        }
    }

    blockedFunctionality = async (functionality, funcVerification) => {
        const restrictedFunctionality = this.getFunctionalities().find((p) => p.Identified === functionality);
        let retorno = true;

        if (restrictedFunctionality) {
            if (funcVerification && typeof funcVerification == 'function') {
                //SE FOR LIMITADO, ESSA FUNÇÃO DEVE VERIFICAR SE JA ULTRAPASSOU O LIMITE;
                return await funcVerification(restrictedFunctionality.Value, functionality);
            }
            //SE NÃO FOI PASSADA UMA FUNÇÃO, QUER DIZER QUE É BLOQUEADO (SE O VALUE FOR "true")
            retorno = restrictedFunctionality.Value == "true" || restrictedFunctionality.Value == true;

        } else {
            //SE NÃO TEM NENHUM, SIGNIFICA QUE NÃO É BLOQUEADO NEM LIMITADO
            retorno = false;
        }

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(retorno);
            }, 1);
        })
    }



    getToken() {
        try {
            const token = localStorage.getItem('token')
            if (!token || token === 'undefined')
                return null;
            return JSON.parse(token);
        }
        catch {
            return null;
        }
    }

    signinRedirect = () => {
        if (this.isAuthenticated()) {
            window.location = '/financial';
        } else {
            window.location = '/account/login';
        }
    };

    //BY Julio \o/
    isAuthenticated = () => {
        const token = localStorage.getItem('token');

        if (!token)
            // Se não existe o token no LocalStorage
            return false; // significa que o usuário não está assinado.

        try {
            const { exp: expiration } = this.getTokenDecode();
            const isExpired = !!expiration && Date.now() > expiration * 1000;

            if (isExpired)
                // Se o token tem uma data de expiração e
                return false; // essa data é menor que a atual o usuário
            // não está mais assinado.
            return true;
        } catch (_) {
            // O "jwt-decode" lança erros pra tokens inválidos.
            return false; // Com um token inválido o usuário não está assinado.
        }
    };

    logout = () => {
        localStorage.removeItem('token');
        this.signinRedirect();
    };

    registerLog = async (code_id) => {
        log.user_id = this.getUserId();
        log.code_id = code_id;

        await RegisterLog(log);
    };
}
