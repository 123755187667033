import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioButton } from '@progress/kendo-react-inputs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { MaskedTextBox } from '@progress/kendo-react-inputs';
import RegexValidateOnlySpace from './../../../utils/regexValidateOnlySpace';
import SpacesForRequiredFields from './../../../utils/spaceForRequired';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ListNoDataRender from '../../../components/Relevo/kendo/multiselect/ListNoDataRender';
export default function RegistrationData({
    states,
    selectedValuePeople,
    setSelectedValuePeople,
    city,
    canChooseCpf,
    canChooseCnpj,
    getAllCityByState,
}) {
    const { control, errors, setValue, formState, trigger } = useFormContext();

    const [loading, setLoading] = useState(false);
    setValue('isValid', formState.isValid);

    async function getCity(stateId) {
        setValue('cidade', '');
        await getAllCityByState(stateId);
    }

    return (
        <>
            <Row className="inputForm">
                <Col xs={3} lg={6}>
                    <RadioButton
                        name="cnpj"
                        id="cnpj"
                        onChange={(e) => {
                            setSelectedValuePeople(e.value);
                            setValue('cpfCnpj', '');
                        }}
                        label="Pessoa Jurídica"
                        value="cnpj"
                        disabled={!canChooseCnpj}
                        checked={selectedValuePeople === 'cnpj'}
                    />
                </Col>
                <Col xs={3} lg={6}>
                    <RadioButton
                        name="cpf"
                        id="cpf"
                        onChange={(e) => {
                            setSelectedValuePeople(e.value);
                            setValue('cpfCnpj', '');
                        }}
                        label="Pessoa Física"
                        value="cpf"
                        disabled={!canChooseCpf}
                        checked={selectedValuePeople === 'cpf'}
                    />
                </Col>
            </Row>
            <Row className="inputForm">
                <Col xs={6} lg={12}>
                    <Label>
                        {selectedValuePeople === 'cnpj' ? 'CNPJ' : 'CPF'} <span className="requiredValidator">*</span>
                    </Label>
                    <br />
                    <Controller
                        name="cpfCnpj"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ onChange, value }) => (
                            <MaskedTextBox
                                name="cpfCnpj"
                                id="cpfCnpj"
                                width="100%"
                                onChange={(e) => {
                                    onChange(e.target.element.value.length > 0 ? e.target.value : null);
                                    trigger(e.target.name);
                                }}
                                mask={selectedValuePeople === 'cnpj' ? '00.000.000/0000-00' : '000.000.000-00'}
                                style={{ width: '100%' }}
                                required={errors.cpfCnpj ? true : false}
                                maxLength="15"
                                value={value || ''}
                            />
                        )}
                    />
                    {errors.cpfCnpj && (
                        <span className="requiredValidator">
                            O campo {selectedValuePeople === 'cnpj' ? 'CNPJ' : 'CPF'} é obrigatório
                        </span>
                    )}
                </Col>
            </Row>
            <Row className="inputForm">
                <Col xs={6} lg={12}>
                    <Label>
                        {selectedValuePeople === 'cnpj' ? 'Razão Social' : 'Nome Completo'}{' '}
                        <span className="requiredValidator">*</span>
                    </Label>
                    <br />
                    <Controller
                        name="razao_social"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ onChange, value }) => (
                            <Input
                                name="razao_social"
                                id="razao_social"
                                onChange={(e) => {
                                    onChange(SpacesForRequiredFields(e));
                                    //Chama validação
                                    trigger(e.target.name);
                                }}
                                style={{ width: '100%' }}
                                pattern={RegexValidateOnlySpace()}
                                required={errors.razao_social ? true : false}
                                value={value}
                                maxLength="250"
                            />
                        )}
                    />
                    {errors.razao_social && (
                        <span className="requiredValidator">
                            O campo {selectedValuePeople === 'cnpj' ? 'Razão Social' : 'Nome Completo'} é obrigatório
                        </span>
                    )}
                </Col>
            </Row>
            <Row className="inputForm">
                <Col xs={6} lg={12}>
                    <Label>
                        Telefone Celular <span className="requiredValidator">*</span>
                    </Label>
                    <br />
                    <Controller
                        name="celular"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ onChange, value }) => (
                            <MaskedTextBox
                                name="celular"
                                id="celular"
                                width="100%"
                                onChange={(e) => {
                                    onChange(e.target.element.value.length > 0 ? e.target.value : null);
                                    trigger(e.target.name);
                                }}
                                mask="(00) 00000-0000"
                                style={{ width: '100%' }}
                                required={errors.celular ? true : false}
                                maxLength="15"
                                value={value || ''}
                            />
                        )}
                    />
                    {errors.celular && (
                        <span className="requiredValidator">O campo Telefone Celular é obrigatório</span>
                    )}
                </Col>
            </Row>
            <Row className="inputForm">
                <Col xs={2} lg={4}>
                    <Label>
                        Estado <span className="requiredValidator">*</span>
                    </Label>
                    <br />
                    <Controller
                        name="estado_id"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ onChange, value }) => (
                            <DropDownList
                                name="estado_id"
                                id="estado_id"
                                style={{ width: '100%' }}
                                data={states}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                    trigger(e.target.name);
                                    getCity(e.target.value.estado_id);
                                }}
                                textField="nome"
                                dataItemKey="estado_id"
                                value={value}
                                required={errors.estado_id ? true : false}
                            />
                        )}
                    />

                    {errors.estado_id && <span className="requiredValidator">O campo Estado é obrigatório</span>}
                </Col>
                <Col xs={4} lg={8}>
                    <Label>
                        Cidade <span className="requiredValidator">*</span>
                    </Label>
                    <br />
                    <Controller
                        name="cidade"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ onChange, value }) => (
                            <DropDownList
                                name="cidade"
                                id="cidade"
                                style={{ width: '100%' }}
                                data={city}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                    trigger(e.target.name);
                                }}
                                textField="nome"
                                dataItemKey="cidade_id"
                                value={value}
                                required={errors.cidade ? true : false}
                                listNoDataRender={(e) => ListNoDataRender(e)}
                                loading={loading}
                            />
                        )}
                    />

                    {errors.cidade && <span className="requiredValidator">O campo Cidade é obrigatório</span>}
                </Col>
            </Row>
        </>
    );
}
