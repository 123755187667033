import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Label, Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { useForm, Controller } from 'react-hook-form';
import { Alert } from 'reactstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { Checkbox } from '@progress/kendo-react-inputs';
import { GetLastVersionActive } from '../../models/termAccept';
import { PostAcceptNewTerm } from '../../models/auth';
import { RegisterLog } from './../../models/log';
import log from './../../types/log/log';
import codesEnum from './../../types/enum/codesEnum';

export default function DialogNewTermAccept({ cancel, dataUser }) {
    const form = useForm({
        criteriaMode: 'all',
        shouldUnregister: false,
    });

    const [responseStatus, setResponseStatus] = useState({
        message: '',
        succsess: false,
        error: false,
    });

    const [loading, setLoading] = useState(false);
    const [termAccept, setTermAccept] = useState(false);

    const { handleSubmit, control, reset, errors } = form;
    let message = '';

    function resetState(succsess) {
        setTimeout(() => {
            setResponseStatus({ ...responseStatus, succsess: false, error: false });
            setLoading(false);

            if (succsess === true) {
                cancel();
            }
        }, 3000);
    }

    var onSubmit = async function (data, e) {
        setLoading(true);
        data.termo_aceite_id = termAccept.termo_aceite_id;
        data.usuario_id = dataUser.usuario_id;
        data.senha = dataUser.senha;
        data.socialLogin = dataUser.socialLogin;
        let response = await PostAcceptNewTerm(data);

        if (response.status === 200) {
            setResponseStatus({ ...responseStatus, message: response.data.message, succsess: true });
            resetState(true);
        } else {
            message = response.data ? response.data.message : 'Erro ao atualizar usuário com novo termo de uso';
            setResponseStatus({ ...responseStatus, message: message, error: true });
            resetState(false);
        }
    };

    async function getTerm() {
        setLoading(true);
        const response = await GetLastVersionActive();

        if (response.status === 200) {
            registerLog(codesEnum.aceite_termo_uso);
            setTermAccept(response.data.data);
        } else {
            const message = response ? response.data.message : 'Erro ao obter termo';
            setResponseStatus({ ...responseStatus, message: message, error: true });
        }

        resetState(false);
    }

    useEffect(() => {
        (async () => {
            await getTerm();
        })();
    }, []);
    const success = <Alert variant="succsess">{responseStatus.message}</Alert>;
    const error = (
        <div class="alert alert-danger" role="alert">
            {responseStatus.message}
        </div>
    );

    async function registerLog(code_id) {
        log.user_id = dataUser.usuario_id;
        log.code_id = code_id;

        await RegisterLog(log);
    }

    return (
        <Modal isOpen={true} id="new-term-accept-modal" load={true}>
            <AvForm onValidSubmit={handleSubmit(onSubmit)}>
                <ModalHeader>Novo Termo de Uso</ModalHeader>
                <ModalBody>
                    {responseStatus.succsess ? success : null}
                    {responseStatus.error ? error : null}
                    <Row>
                        <Col lg={12}>O termo de uso do Simplific Gestão foi atualizado.</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg={1}>
                            <Controller
                                name="termoAceite"
                                control={control}
                                rules={{ required: true }}
                                render={({ onChange, value }) => (
                                    <Checkbox
                                        name="termoAceite"
                                        onChange={(e) => {
                                            onChange(e.value);
                                        }}
                                        label={''}
                                        required={errors.termoAceite ? true : false}
                                    />
                                )}
                            />
                        </Col>
                        <Col lg={11}>
                            <p className="termoAceite" style={{ textAlign: 'left', marginLeft: '-15px' }}>
                                Concordo com os{' '}
                                <a href="/account/term-accept" target="_blank" className="linkTermo">
                                    Termos de Uso
                                </a>
                            </p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="gray-200" id="cancel-new-project" onClick={() => cancel()} data-toggle="tooltip" data-placement="top" title="Cancelar">
                        Cancelar
                    </Button>
                    <Button type="submit" disabled={responseStatus.load} color="primary" id="new-terma-accept" data-toggle="tooltip" data-placement="top" title="Salvar">
                        {!loading ? 'Salvar' : <Loader />}
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    );
}
