const functionalitiesEnum = {
  
  BLOCK_GROUP_CATEGORY: "BLOCK_GROUP_CATEGORY",
  LIMIT_APPOINTMENT_BOOK: "LIMIT_APPOINTMENT_BOOK",
  LIMIT_CURRENT_ACCOUNT: "LIMIT_CURRENT_ACCOUNT",
  BLOCK_TALK_SPECIALIST: "BLOCK_TALK_SPECIALIST",
  LIMIT_USER_COMPANY: "LIMIT_USER_COMPANY",
  BLOCK_DELETE_GROUP_CATEGORY: "BLOCK_DELETE_GROUP_CATEGORY",

  LIMIT_TICKET_PAYMENT: "LIMIT_TICKET_PAYMENT",
  LIMIT_NOTE_ISSUES: "LIMIT_NOTE_ISSUES",
  LIMIT_ATTACHMENT: "LIMIT_ATTACHMENT",
  LIMIT_CATEGORY: "LIMIT_CATEGORY",



  BLOCK_ACCOUNT_FREQUENCY: 'BLOCK_ACCOUNT_FREQUENCY',
  BLOCK_ATTACHMENT: 'BLOCK_ATTACHMENT',
  BLOCK_CASHFLOW_EXPECTED: 'BLOCK_CASHFLOW_EXPECTED',
  BLOCK_CASHFLOW_EXPORT: 'BLOCK_CASHFLOW_EXPORT',
  BLOCK_CASHFLOW_PROJECT_FILTER: 'BLOCK_CASHFLOW_PROJECT_FILTER',
  BLOCK_DRE_EXPORT: 'BLOCK_DRE_EXPORT',
  BLOCK_DRE_PROJECT_FILTER: 'BLOCK_DRE_PROJECT_FILTER',
  BLOCK_EXTRATO_EXPORT: 'BLOCK_EXTRATO_EXPORT',
  BLOCK_MENU_BANK_TRANSACTION: 'BLOCK_MENU_BANK_TRANSACTION',
  BLOCK_MENU_DASHBOARD_MANAGER: 'BLOCK_MENU_DASHBOARD_MANAGER',
  BLOCK_MENU_INVOICE: 'BLOCK_MENU_INVOICE',
  BLOCK_MENU_TICKET: 'BLOCK_MENU_TICKET',
  BLOCK_PAID_EDUCATIONS: 'BLOCK_PAID_EDUCATIONS',
  BLOCK_PAID_INSIGHTS: 'BLOCK_PAID_INSIGHTS',
  BLOCK_PAID_PERFORMANCES: 'BLOCK_PAID_PERFORMANCES',
  BLOCK_SUPPORT_CHAT: 'BLOCK_SUPPORT_CHAT',
  BLOCK_FINANCIAL_PLANNING: 'BLOCK_FINANCIAL_PLANNING',
  LIMIT_CATEGORY: 'LIMIT_CATEGORY',
  LIMIT_CATEGORY_APPORTIONMENTS: 'LIMIT_CATEGORY_APPORTIONMENTS',
  LIMIT_CURRENT_ACCOUNT: 'LIMIT_CURRENT_ACCOUNT',
  LIMIT_PROJECT_APPORTIONMENTS: 'LIMIT_PROJECT_APPORTIONMENTS',
  LIMIT_USER_COMPANY: 'LIMIT_USER_COMPANY',
}

export default functionalitiesEnum;