import { observable } from 'mobx';
import React from 'react';

const controlTypeAhead = {
    invalidSuplier: false,
    invalidAccount: false,
};
const controlSimulation = {
    isOpen: false,
    isSimulation: false,
    registerAndPay: false,
};
const billsToPay = {
    createdById: 0,
    dueDate: null,
    amount: null,
    paymentDate: null,
    paymentForecastDate: null,
    bankAccountId: null,
    categoryId: null,

    createdDate: new Date(),
    accountTypeId: 0, // Contas a pagar (débito)
    accountStatusId: 1,

    name: null,
    invoice: null,
    invoiceDate: null,
    competenceDate: null,
    projectId: null,
    frequencyId: 1,
    installmentsNumbers: null,
    firstDueDate: null,
    paymentDateFirst: null,
    antecipate: true,
    dayMonth: null,
    mensalPaymentDate: null,
    numberOfMonths: null,
    dayWeek: null,
    numberOfWeek: null,
    numberOfRepetitionDays: null,
    repeticoesOtherQuantity: null,
    notes: null,
    pis: 0,
    pisRetain: false,
    cofins: 0,
    cofinsRetain: false,
    csll: 0,
    csllRetain: false,
    ir: 0,
    irRetain: false,
    iss: 0,
    issRetain: false,
    inss: 0,
    inssRetain: false,
    accountStatusId: 2,
    frequencyLimitDate: null,
    deletedDate: null,
    typeAccount: -1,
    accountPayment: [],
    descriptionAccount: null,
    documentos: [],
    documentTypeId: null,
};

class BillsToPayStore extends React.Component {
    constructor() {
        super();
        this._billsToPay = observable.box(billsToPay);
        this._controlTypeAhead = observable.box(controlTypeAhead);
        this._controlSimulation = observable.box(controlSimulation);
    }

    get controlSimulation() {
        return this._controlSimulation.get();
    }
    set controlSimulation(value) {
        this._controlSimulation.set(value);
    }

    get controlTypeAhead() {
        return this._controlTypeAhead.get();
    }
    set controlTypeAhead(value) {
        this._controlTypeAhead.set(value);
    }

    get billsToPay() {
        return this._billsToPay.get();
    }
    set billsToPay(value) {
        this._billsToPay.set(value);
    }
}

const store = new BillsToPayStore();
export default store;
