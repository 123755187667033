import React from 'react';
import { Loader } from 'react-bootstrap-typeahead';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Alert } from "reactstrap";


export default class RelevoModal extends React.Component<RelevoModalType>{
    constructor(props) {
        super(props)
    }


    render() {
        const {
            modalId,
            confirmButtonId,
            cancelButtonId,
            onConfirm,
            onCancel,
            showFooter = true,
            confirmText = "Confirmar",
            cancelText = "Cancelar",
            alertColor = "success",
            hasCancel = true,
            className = '',
            isOpen = false,
            isConfirmRed = false,
            isConfirmOk = false,
            showCloseButton = false,
            headerMessage = null,
            bodyMessage = null,
            load,
            isOpenAlert,
            messageAlert,
            onOpen,
            isError,
            children,
            confirmDisabled = false,
            customBody = false,
        } = this.props

        return (
            <React.Fragment>
                <Modal
                    zIndex={9999}
                    id={modalId}
                    className={className}
                    toggle={onCancel}
                    onOpened={onOpen}
                    isOpen={isOpen}>
                    {showCloseButton ?
                        <button className="close" style={{ position: 'absolute', top: '-40px', right: '-40px', fontSize: 'xxx-large', fontWeight: 100, color: 'white' }} onClick={onCancel}>&times;</button>
                        : null}
                    {headerMessage ?
                        <ModalHeader className="relevo-modal-confirm">{headerMessage}</ModalHeader>
                        : null}
                    {bodyMessage ?
                        <ModalBody className="relevo-modal-confirm">{bodyMessage}
                            {customBody ? customBody : null}
                        </ModalBody>
                        : null}

                    {isOpenAlert ?
                        <Alert color={isError ? "danger" : alertColor} className="relevo-modal-alert">
                            {messageAlert ? messageAlert : "A operação foi realizada com sucesso!"}
                        </Alert>
                        : null}

                    {children}

                    {showFooter && <ModalFooter className="relevo-modal-confirm">
                        {hasCancel ?
                            <Button color="gray-200" id={cancelButtonId} onClick={onCancel} data-toggle="tooltip" data-placement="top" title={cancelText}>
                                {cancelText}
                            </Button>
                            : null}

                        {isConfirmOk ?
                            <Button color="danger" onClick={onCancel} disabled={load || confirmDisabled} data-toggle="tooltip" data-placement="top" title={confirmText}>
                                {!load ? confirmText : <Loader />}
                            </Button>
                            :
                            <Button color={isConfirmRed ? "danger" : "primary"} id={confirmButtonId} onClick={onConfirm} disabled={load || confirmDisabled} data-toggle="tooltip" data-placement="top" title={confirmText}>
                                {!load ? confirmText : <Loader />}
                            </Button>
                        }
                    </ModalFooter>}
                </Modal>
            </React.Fragment>
        )
    }
}

type RelevoModalType = {
    modalId: String,
    confirmButtonId: String,
    cancelButtonId: String,
    onConfirm: Function,
    onCancel: Function,
    confirmText: String,
    cancelText: String,
    alertColor: String,
    className: String,
    isOpen: Boolean,
    isConfirmRed: any,
    isConfirmOk: Boolean,
    headerMessage: String,
    bodyMessage: String,
    children: String,
    load: Boolean,
    isOpenAlert: Boolean,
    messageAlert: String,
    onOpen: Function,
    isError: Boolean,
    confirmDisabled: Boolean
}