
import React, { useState, useEffect, useRef, useContext } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import '../../../assets/scss/custom/components/Relevo/RelevoPopover.scss';
import AuthContext from '../../../context/authContext'

import ReactHtmlParser from 'react-html-parser';


const RelevoPopover = ({
  idReference = "",   //MUST-HAVE. Id do elemento ao qual o popover se refere
  placement = "right",  //top - bottom - left- right
  trigger = "",       //legacy (desaparece ao clicar na tela, aparece ao clicar no botão) || hover (aparece ao passar com mouse no botão)
  title = "Insert title",
  text = "Insert text",
  timeout = 2000,
  isHtml = false,
  hideOnFreeSimulation = false,
  setShowPopover = (val) => { }
}) => {

  const userService = useContext(AuthContext);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const popover = {
    idReference,
    title,
    text,
    placement,
    trigger,
  };

  const toggle = () => {
    setPopoverOpen(false);
    setShowPopover(false)
  }

  useEffect(() => {
    // Garante que o objeto seja renderizado antes de exibir o popover.
    // Este trecho de código evita as exceptions. 
    let observer = setInterval(() => {
      const res = document.getElementById(idReference);
      if (res) {
        if (hideOnFreeSimulation) {
          const isFreePlan = userService.isAuthenticated() && userService.getTokenDecode()?.teste_gratis ? true : false;
          if (isFreePlan) {
            clearTimeout(observer);
            return;
          }
        }

        setPopoverOpen(true);
        clearTimeout(observer);

        res.addEventListener("click", () => {
          toggle()
        });

      }
    }, timeout);
  }, [idReference])



  return (
    <>
      {popoverOpen ?
        <UncontrolledPopover id={popover.idReference} placement={popover.placement} target={`${popover.idReference}`}
          toggle={toggle}
          isOpen={popoverOpen}
          trigger={popover.trigger}
        >
          <PopoverHeader>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "98%"
            }}>
              <div style={{ display: 'inline-block' }}>{popover.title}</div>
              <div>
                <a href="#" className="popover-icon-close" onClick={() => toggle()}>x</a>
              </div>
            </div>
          </PopoverHeader>

          <PopoverBody>{isHtml ? ReactHtmlParser(popover.text) : popover.text}</PopoverBody>
        </UncontrolledPopover>
        : null}
    </>
  );
}

export default RelevoPopover;