import React, { Component } from 'react';
import AuthService from '../helpers/authService';

const AuthContext = React.createContext({
    logout: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    getUser: () => ({}),
    getPlanSubscription: () => ({}),
    getUserId: () => ({}),

    getCompanyId: () => ({}),

    getPermissions: () => ({}),
    hasPermissions: () => ({}),

    getPlan: () => ({}),
    getFunctionalities: () => ({}),
    blockedFunctionality: () => ({}),
    getFunctionalityValue: () => ({}),

    getToken: () => ({}),
    getIsFirstAccess: () => ({}),
    registerLog: (code_id) => ({}),
    getUserIsCounter: () => ({}),
});
export default AuthContext;
export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
    authService;
    constructor(props) {
        super(props);
        this.authService = new AuthService();
    }
    render() {
        return <AuthContext.Provider value={this.authService}>{this.props.children}</AuthContext.Provider>;
    }
}
