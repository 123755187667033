import React from 'react';
import { PrivateRoute } from './privateRoute';

const HomeScheduler = React.lazy(() => import('../pages/Homes/scheduler/HomeScheduler'));
const ConfigurationCategory = React.lazy(() => import('../pages/Homes/scheduler/ConfigurationCategory/index'));
const ConfigurationSchedule = React.lazy(() => import('../pages/Homes/scheduler/ConfigurationScheduler/index'));
const schedulerRoutes = {
    // path: '/module/scheduler/appointments',
    path: '/module/scheduler',
    name: 'Agenda',
    icon: 'mdi mdi-calendar-month',
    route: PrivateRoute,
    module: "/scheduler",
    match: 'exact',
    component: HomeScheduler,
    children: [
        {
            path: '/module/scheduler',
            name: 'Minha Agenda',
            //icon: 'mdi mdi-calendar-month',
            route: PrivateRoute,
            module: "/scheduler",
            match: 'exact',
            component: HomeScheduler,
        }
    ]
};

const configurationSchedulerRoutes = {
    //path: '/module/configuration',
    path: '/module/schedulers/categories',
    name: 'Configurações',
    icon: 'mdi mdi-settings-outline',
    //component: ConfigurationCategory,
    route: PrivateRoute,
    module: "/scheduler",
    match: 'exact',
    children: [
        {
            path: '/module/schedulers/schedules',
            name: 'Agendas',
            // roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: ConfigurationSchedule,
            //codeId: codesEnum.listar_categorias,
            module: "/scheduler",
        },
        {
            path: '/module/schedulers/categories',
            name: 'Categorias',
            // roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: ConfigurationCategory,
            //codeId: codesEnum.listar_categorias,
            module: "/scheduler",
        }
    ]
}

export {
    schedulerRoutes,
    configurationSchedulerRoutes
};
