import React, { useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import { useForm, Controller } from 'react-hook-form';
import login from './../../types/auth/login';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import './../../assets/scss/custom/pages/Auth/login.scss';
// import { default as logo, default as logoDarkRelevo, default as logoSm } from './../../assets/images/logo-beta.svg';
import logoRelevo from './../../assets/images/simplific.png';
import { Button } from '@progress/kendo-react-buttons';
import { PostGetCompany, PostLogin, PostResendMail, PostGoogleLogin, PostFacebookLogin } from '../../models/auth';
import { Alert } from 'reactstrap';
import { Loader } from 'react-bootstrap-typeahead';
import RegexValidateOnlySpace from './../../utils/regexValidateOnlySpace';
import SpacesForRequiredFields from './../../utils/spaceForRequired';
import { Label } from '@progress/kendo-react-labels';
import jwt_decode from 'jwt-decode';
import DialogNewTermAccept from './dialogNewTermAccept';
import { RegisterLog } from './../../models/log';
import log from './../../types/log/log';
import codesEnum from './../../types/enum/codesEnum';
import RelevoModal from '../../components/Relevo/RelevoModal';
import { GoogleLogin } from 'react-google-login';
import stepsEnum from '../../types/enum/useFlowSteps';
import { RegisterUseFlowStep } from './../../models/log';
import * as variablesConstants from '../../constants/variables';

import { plansFreeEnum } from '../../types/auth/plansFree';
import { Redirect, isMobile } from '../../helpers/Utils';
import { NotDesktopAlert } from '../Homes/financial/notDesktopAlert';

import { PlanoPessoaFisica, PlanoPessoaJuridica } from '../auth/myProfile/Meta';


async function _updateUseFlowStep() {
    const data = { 'tag': stepsEnum.login_simplific }
    await RegisterUseFlowStep(data)
}


export default function NewLogin() {
    const form = useForm({
        criteriaMode: 'all',
        shouldUnregister: false,
        defaultValues: login,
    });
    const [mostrarModalNewTerm, setMostrarModalNewTerm] = useState(false);
    const [dataUser, setDataUser] = useState({ senha: '', usuario_id: '', socialLogin: false });
    const [responseStatus, setResponseStatus] = useState({
        message: '',
        succsess: false,
        error: false,
    });

    const [load, setLoad] = useState(false);
    const [userPending, setUserPending] = useState(false)
    const [isOpenAlert, setIsOpenAlert] = useState(false)
    const [isErrorResend, setIsErrorResend] = useState(false)
    const [errorResendMessage, setErrorResendMessage] = useState(false)
    const [mailUser, setMailUser] = useState()
    const [resendLoad, setResendLoad] = useState()


    const { handleSubmit, control, errors, trigger } = form;

    var onSubmit = async function (data, e) {
        e.preventDefault();
        setLoad(true);

        const response = await PostGetCompany(data);

        if (response.status === 200) {
            const usuario = response.data.usuario;

            data.empresa_id = usuario.empresas[0].empresa_id;


            if (usuario.empresas.some(x => x.isCounter)) {
                data.empresa_id = usuario.empresas.find(x => x.isCounter).empresa_id;
            }
            
            await login(data);
        } else {
            const message = response ? response.data.message : 'Erro ao efetuar login';
            //TODO MUDAR IF
            if (response.data.message == "Esse usuário está inativo ou pendente de confirmação impossibilitando seu login") {
                setUserPending(true)
                setMailUser(data.email)
            }
            else {
                setResponseStatus({ ...responseStatus, message: message, error: true });
            }

            resetState(false);
        }
    };

    async function login(data) {
        const response = await PostLogin(data);

        if (response.status === 200) {

            await _updateUseFlowStep()
            const usuario = jwt_decode(response.data.token);
            registerLog(codesEnum.login_usuario, usuario.usuarioDB.usuario_id);
            localStorage.setItem('first_access', JSON.stringify(usuario.usuarioDB.primeiro_acesso));

            localStorage.setItem('token', JSON.stringify(response.data.token));
            if (!plansFreeEnum.includes(usuario.payment_permissions.Plan.Name)
                && new Date(usuario.payment_permissions.DueDate) <= new Date()) {
                //localStorage.setItem('token_temp', JSON.stringify(response.data.token));
                window.location = '/account/update-payment';
                return;
            }
            
            const isCreatedAfterNewPlans = new Date(usuario.usuarioDB.criado_em) > new Date('2024-03-05T00:00:00.000Z');

            // If the user has a free plan and was created after the new plans, redirect to the new plans page
            if (usuario.payment_permissions.Plan.Name === 'Plano Gratuito' && isCreatedAfterNewPlans) {
                const isCNPJ = usuario.usuarioDB.empresas[0].cnpj
                if (isCNPJ) {
                    // Redirect to Pessoa Juridica plan page
                    Redirect(`/payment/sign/["${PlanoPessoaJuridica?.ids[0]}","${PlanoPessoaJuridica?.ids[1]}"]`)
                    return;
                }
                // Redirect to Pessoa Fisica plan page
                Redirect(`/payment/sign/["${PlanoPessoaFisica?.ids[0]}","${PlanoPessoaFisica?.ids[1]}"]`)
                return;
            }

            setResponseStatus({ ...responseStatus, message: response.data.message, succsess: true });


            resetState(true, usuario.usuarioDB.primeiro_acesso);
        } else {
            const message = response ? response.data.message : 'Erro ao efetuar login';
            setResponseStatus({ ...responseStatus, message: message, error: true });

            resetState(false, response);

            if (response.data && response.data.termoVencido) {
                setDataUser({
                    senha: data.senha,
                    usuario_id: response.data.usuario_id,
                    socialLogin: false,
                });

                setTimeout(() => {
                    setMostrarModalNewTerm(true);
                }, 2000);
            }
        }
    }

    const success = <Alert variant="succsess">{responseStatus.message}</Alert>;
    const error = (
        <div class="alert alert-danger" role="alert">
            {responseStatus.message}
        </div>
    );
    const customBody = <div>
        <p className={'relevo-text-bold-modal'}>Antes, você precisa confirmar a ativação de usuário por e-mail.</p>
        <p>Você recebeu um e-mail para ativação e conclusão do cadastro.</p>
        <p className={'resend-modal-text-blue'}>Por favor, verifique seu e-mail e ative seu usuário.</p>
    </div>


    function resetState(success, isFirstAccess = false, isCounter = false) {
        setTimeout(() => {
            if (success) {

                if (isCounter) {
                    window.location = '/module/counter/dashboard';
                    return;
                }
                window.location = '/mkt/dashboard-1';

            } else {
                setResponseStatus({ ...responseStatus, succsess: false, error: false });
                setLoad(false);
            }
        }, 3000);
    }

    function cancel() {
        setMostrarModalNewTerm(false);
    }

    async function registerLog(code_id, user_id) {
        log.user_id = user_id;
        log.code_id = code_id;

        await RegisterLog(log);
    }

    async function confirmResendMail(mail) {
        setResendLoad(true)
        const data = await PostResendMail(mail)
        if (data.status == 200) {
            setIsOpenAlert(true)
            setTimeout(() => {
                setUserPending(false)
                setIsOpenAlert(false)
                setResendLoad(false)
            }, 2000);
        }
        else {
            setIsOpenAlert(true)
            setErrorResendMessage(data.data.message)
            setIsErrorResend(true)
            setTimeout(() => {
                setResendLoad(false)
                setIsOpenAlert(false)
                setIsOpenAlert(false)
            }, 2000);
        }
    }

    const handleGoogleLogin = async (googleData) => {
        const response = await PostGoogleLogin(googleData.tokenId);

        if (response) {
            if (response.status === 200) {
                await _updateUseFlowStep()
                localStorage.setItem('token', JSON.stringify(response.data.token));

                const usuario = jwt_decode(response.data.token);

                registerLog(codesEnum.login_usuario, usuario.usuarioDB.usuario_id);
                localStorage.setItem('first_access', JSON.stringify(usuario.usuarioDB.primeiro_acesso));
                setResponseStatus({ ...responseStatus, message: response.data.message, succsess: true });
                resetState(true, usuario.usuarioDB.primeiro_acesso);
            } else {
                const message = response ? response.data.message : 'Erro ao efetuar login';
                setResponseStatus({ ...responseStatus, message: message, error: true });

                resetState(false, response);

                if (response.data && response.data.termoVencido) {
                    setDataUser({
                        senha: '',
                        usuario_id: response.data.usuario_id,
                        socialLogin: true,
                    });

                    setTimeout(() => {
                        setMostrarModalNewTerm(true);
                    }, 2000);
                }
            }
        }
    }

    const handleFacebookLogin = async (data) => {
        const response = await PostFacebookLogin(data.authResponse.accessToken);

        if (response) {
            if (response.status === 200) {
                await _updateUseFlowStep()
                localStorage.setItem('token', JSON.stringify(response.data.token));

                const usuario = jwt_decode(response.data.token);

                registerLog(codesEnum.login_usuario, usuario.usuarioDB.usuario_id);
                localStorage.setItem('first_access', JSON.stringify(usuario.usuarioDB.primeiro_acesso));
                setResponseStatus({ ...responseStatus, message: response.data.message, succsess: true });
                resetState(true, usuario.usuarioDB.primeiro_acesso);
            } else {
                const message = response ? response.data.message : 'Erro ao efetuar login';
                setResponseStatus({ ...responseStatus, message: message, error: true });

                resetState(false, response);

                if (response.data && response.data.termoVencido) {
                    setDataUser({
                        senha: '',
                        usuario_id: response.data.usuario_id,
                        socialLogin: true,
                    });

                    setTimeout(() => {
                        setMostrarModalNewTerm(true);
                    }, 2000);
                }
            }
        }
    }


    if (isMobile()) {
        return (
            <NotDesktopAlert>
            </NotDesktopAlert>);
    }

    return (

        <>
            <div className="limiter">
                <div className="container-login100">
                    <div>
                        <div className="wrap-login100">
                            <AvForm className="login100-form validate-form" onValidSubmit={handleSubmit(onSubmit)}>
                                <span className="login100-form-title p-b-26">
                                    <img src={logoRelevo} alt="logo" height="63" width="244" />
                                </span>

                                {responseStatus.succsess ? success : null}
                                {responseStatus.error ? error : null}

                                {userPending ?
                                    <RelevoModal
                                        modalId="resend-modal"
                                        confirmButtonId="confirm-resend-modal"
                                        headerMessage="E-mail pendente de ativação"
                                        bodyMessage={" "}
                                        isOpen={userPending}
                                        onConfirm={() => { confirmResendMail(mailUser) }}
                                        onCancel={() => { setUserPending(false) }}
                                        isOpenAlert={isOpenAlert}
                                        messageAlert={isOpenAlert && isErrorResend ? errorResendMessage : 'E-mail enviado com sucesso!'}
                                        confirmText={'Enviar novamente'}
                                        customBody={customBody}
                                        load={resendLoad}
                                    />
                                    : null
                                }

                                <div className="div-header-login">
                                    <span>
                                        Login
                                    </span>
                                </div>

                                <div className="container-login100-form-btn div-button-facebook" style={{ marginTop: '15px' }}>
                                    <button
                                        type="button"
                                        onClick={() => window.FB.login((e) => handleFacebookLogin(e), { scope: 'public_profile,email', auth_type: 'reauthorize' })}>
                                        <span style={{ fontSize: '25px' }}>
                                            <i className="mdi mdi-facebook-box" ></i>
                                        </span>
                                        <span style={{ bottom: '4px', position: 'relative' }}>
                                            &nbsp;Entrar com o Facebook
                                        </span>
                                    </button>
                                </div>

                                <div className="col-button-google" style={{ marginTop: '15px' }}>
                                    <GoogleLogin
                                        clientId={variablesConstants.REACT_APP_GOOGLE_CLIENT_ID}
                                        buttonText="Entrar com o Google"
                                        onSuccess={(e) => handleGoogleLogin(e)}
                                        onFailure={(e) => handleGoogleLogin(e)}
                                    />
                                </div>

                                <div class="div-ou">
                                    <hr />
                                    <span>
                                        Ou
                                    </span>
                                </div>

                                <Row>
                                    <Col lg={12} className="inputForm">
                                        <Label>
                                            E-mail <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => (
                                                <Input
                                                    name="email"
                                                    onChange={(e) => {
                                                        onChange(SpacesForRequiredFields(e));
                                                        //Chama validação
                                                        trigger(e.target.name);
                                                    }}
                                                    style={{ width: '100%' }}
                                                    pattern={RegexValidateOnlySpace()}
                                                    required={errors.email ? true : false}
                                                    placeholder="E-mail de cadastro"
                                                    maxLength="150"
                                                />
                                            )}
                                        />
                                        {errors.email && (
                                            <span className="requiredValidator">O campo E-mail é obrigatório</span>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12} className="inputForm">
                                        <div className="alterPassword">
                                            <a className="linksLogin" href="/account/recovery-user">
                                                Esqueci minha senha
                                            </a>
                                        </div>
                                        <Label>
                                            Senha <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="senha"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => (
                                                <Input
                                                    name="senha"
                                                    type="password"
                                                    style={{ width: '100%' }}
                                                    placeholder="********"
                                                    onChange={(e) => {
                                                        onChange(SpacesForRequiredFields(e));
                                                        //Chama validação
                                                        trigger(e.target.name);
                                                    }}
                                                    maxLength="30"
                                                    pattern={RegexValidateOnlySpace()}
                                                    required={errors.senha ? true : false}
                                                />
                                            )}
                                        />
                                        {errors.senha && (
                                            <span className="requiredValidator">O campo Senha é obrigatório</span>
                                        )}
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col lg={12} className="inputForm">
                                        <Controller
                                            name="remember"
                                            control={control}
                                            rules={{ required: false }}
                                            render={({ onChange, value }) => (
                                                <Checkbox
                                                    name="remember"
                                                    onChange={(e) => {
                                                        onChange(e.value);
                                                    }}
                                                    label={'Lembre-me'}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row> */}

                                <div className="container-login100-form-btn">
                                    <Button
                                        className="k-primary k-button k-grid-edit-command"
                                        type="submit"
                                        disabled={load}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Entrar">
                                        {!load ? 'Entrar' : <Loader />}
                                    </Button>
                                </div>
                                {/* <div 
                                    class="fb-login-button" 
                                    data-width="" 
                                    data-size="large" 
                                    data-button-type="continue_with" 
                                    data-layout="default" 
                                    data-auto-logout-link="false" 
                                    data-use-continue-as="false">
                                </div> */}
                            </AvForm>
                        </div>
                        <div className="regUser">
                            <a className="linksLogin" onClick={() => Redirect('/payment/sign/["85D8CE9B-73D9-427B-B145-71B751C8433B", "49AD5CAC-078D-4432-9292-4116FF86127A"]')}>
                                Ainda não tenho um cadastro
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {mostrarModalNewTerm ? (
                <DialogNewTermAccept cancel={cancel} dataUser={dataUser}></DialogNewTermAccept>
            ) : null}
        </>
    );
}
