import api from '../services/api';

export const SendWelcome = async (companyId) => {
    const body =
    {
        'companyid': companyId,
        'theme': 'Dicas do Especialista',
        'themecolorid': 6,
        'typemessageid': 4,
        'title': 'Bem vindo ao Simplific Gestão',
        'metadata': "{\"flow_id\":3,\"cta\":\"Saiba Mais\"}",
        'text': 'Aqui estão os primeiros passos que separamos para você ter o melhor uso da nossa aplicação'
    }


    try {
        const { data } = await api.post(`/notify-erp/notifications/index`, body);
        return true;
    }
    catch {
        return false;
    }
}


export const SendModalRelatorioVazio = async (userId) => {
    const url_list = [
        '/report/cashflow', '/report/dre', '/conta/corrente/relatorio-extrato/', '/conta/corrente/extrato'
    ]

    for (const url of url_list) {
        const body = {
            "userid": userId,
            "text": "Modal de relatórios vazios",
            "url": url,
            "typeid": 4,
            "templateid": 2
        }
        await api.post(`/comunications-erp/comunications/insert`, body)
    }

}

export const SetModalVisto = async (modalId) => {
    try {
        return await api.put(`/comunications-erp/comunications/update/${modalId}`)
    }
    catch {
        return false
    }
}


export const SendWelcomeMail = async (name, mail) => {
    const body =
    {
        'name': name,
        'mail': mail,
    }

    try {
        const { data } = await api.post(`/notify-erp/notifications/sendMail/welcome`, body);
        return true;
    }
    catch {
        return false;
    }
}