import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import { useForm, FormProvider } from 'react-hook-form';
import './../../../assets/scss/custom/pages/Auth/company.scss';
import { Button } from '@progress/kendo-react-buttons';
import { Alert, Row, Col } from 'reactstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { Stepper } from '@progress/kendo-react-layout';
import AboutCompanyInsight from './aboutCompanyInsight.js';
import Interests from './interests';
import RegistrationData from './registrationData';
import {
    GetQuestionByType,
    GetInterests,
    GetState,
    EditCompany,
    GetCompany,
    GetCityByState,
} from '../../../models/company';
import { SendSectorInsight } from '../../../models/insights';
import { EditLastAcces } from '../../../models/auth';
import AuthContext from '../../../context/authContext';
// import { SendWelcome, SendWelcomeMail, SendModalRelatorioVazio } from '../../../models/notifications';
// import { RegisterUseFlowStep } from '../../../models/log';
// import stepsEnum from '../../../types/enum/useFlowSteps';
import Apresentation from './apresentation';

import {
    PlanCPF,
    PlanMei,
    PlanSimplesLow,
    PlanSimplesMedium,
    PlanSimplesHigh,
    PlanLucroLow,
    PlanLucroMedium,
    PlanoPessoaFisica,
} from '../../../../src/pages/auth/myProfile/Meta.js';

export default function WizardIncludeCompany({ onClickIgnore }) {
    const formAbout = useForm({
        criteriaMode: 'all',
        shouldUnregister: false,
    });

    const formInterests = useForm({
        criteriaMode: 'all',
        shouldUnregister: false,
    });

    const formRegistration = useForm({
        criteriaMode: 'all',
        shouldUnregister: false,
    });

    //Utilitários
    const [responseStatus, setResponseStatus] = useState({
        message: '',
        succsess: false,
        error: false,
    });

    const [loading, setLoading] = useState(false);
    const userService = useContext(AuthContext);

    //Listas
    const [sectorQuestion, setSectorQuestion] = useState({});
    const [interestsAll, setInterestsAll] = useState([]);
    const [states, setStates] = useState([]);
    const [city, setCity] = useState([]);

    //Wizard
    const [step, setStep] = useState(0);
    const [steps, setSteps] = useState([
        { label: 'Sobre seu negócio', isValid: undefined },
        { label: 'Interesses', isValid: undefined },
        { label: 'Dados cadastrais', isValid: undefined },
    ]);

    const [isConcluded, setIsConcluded] = useState(false);

    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;

    const canChooseCpf = () => {
        const { Plan } = userService.getPlanSubscription();
        if (
            PlanMei.ids.includes(Plan?.Id) ||
            PlanSimplesLow.ids.includes(Plan?.Id) ||
            PlanSimplesMedium.ids.includes(Plan?.Id) ||
            PlanSimplesHigh.ids.includes(Plan?.Id) ||
            PlanLucroLow.ids.includes(Plan?.Id) ||
            PlanLucroMedium.ids.includes(Plan?.Id)
        ) {
            return false;
        }
        return true;
    };

    const canChooseCnpj = () => {
        const { Plan } = userService.getPlanSubscription();
        if (PlanCPF.ids.includes(Plan?.Id) || PlanoPessoaFisica.ids.includes(Plan?.Id)) {
            return false;
        }
        return true;
    };

    const [selectedValuePeople, setSelectedValuePeople] = useState(canChooseCnpj() ? 'cnpj' : 'cpf');

    //Monta lista de interesses selecionados pelo usuário
    function listInterests() {
        let checkInterest = null;
        let list = [];
        interestsAll.map((item) => {
            checkInterest = formInterests.getValues('check' + item.interesse_id);
            if (checkInterest != undefined && checkInterest === true) {
                list.push(item.interesse_id);
            }
        });

        return list;
    }

    //Monta resposta do usuário para o setor em formato de lista
    function getSectorQuestion() {
        let question = formAbout.getValues('pergunta' + sectorQuestion.pergunta_id).resposta_id;
        return [question];
    }

    function getSectorAnswer() {
        let resposta = formAbout.getValues('pergunta' + sectorQuestion.pergunta_id);
        let objResposta = {
            pergunta_id: sectorQuestion.pergunta_id,
            resposta_id: sectorQuestion.respostas[0].resposta_id,
            resposta: resposta,
        };
        return [objResposta];
    }

    async function sendSectorInsight() {
        const sector = formAbout.getValues('pergunta' + sectorQuestion.pergunta_id);

        if (sector) {
            const empresaInfo = await GetCompany(userService.getCompanyId());
            if (empresaInfo.status !== 200) {
                return false;
            }

            const phoneNumber = empresaInfo.data.data.celular;
            SendSectorInsight(sector, phoneNumber);
            
        }

    }

    //Verifica se o passo 2 foi selecionado no minimo 1 interesse e no máximo 6 interesse
    function isValidStep2() {
        let isValid = true;
        if (listInterests().length > 6 && step === 1) {
            isValid = false;
            setResponseStatus({
                ...responseStatus,
                message: 'É permitido selecionar no máximo 6 interesses',
                error: true,
                succsess: false,
            });

            resetState();
        } else if (listInterests().length < 1 && step === 1) {
            isValid = false;
            setResponseStatus({
                ...responseStatus,
                message: 'É obrigatório selecionar no mínimo 1 interesse',
                error: true,
                succsess: false,
            });

            resetState();
        }
        return isValid;
    }

    //Validação de dados de cada passo do wizard
    async function getCurrentStepForm() {
        let isValid = true;
        if (step === 0) {
            await formAbout.trigger();

            console.log('formAbout values', formAbout.getValues());

            const f = Object.entries(formAbout.getValues()).filter((value) => {
                if (!value[1]) {
                    formAbout.setError(value[0], {
                        type: 'manual',
                        message: 'Dont Forget Your Username Should Be Cool!',
                    });

                    return value;
                }
            });
            isValid = f.length <= 0;
        } else if (step === 1) {
            isValid = isValidStep2();
        } else {
            await formRegistration.trigger();
            isValid = formRegistration.getValues('isValid');
        }

        const currentSteps = steps.map((currentStep, index) => ({
            ...currentStep,
            isValid: index === step ? isValid : currentStep.isValid,
        }));

        return currentSteps;
    }

    var onSubmit = useCallback(
        async (event) => {
            const currentSteps = await getCurrentStepForm();

            setSteps(currentSteps);

            if (!currentSteps[step].isValid) {
                return;
            }

            //Atualiza empresa a cada passo
            var salvo = await salvarEmpresa();
            if (salvo) {
                setStep(() => Math.min(step + 1, lastStepIndex));
                if (step == lastStepIndex) {
                    setIsConcluded(true);
                }
            }
        },

        [step, steps, setSteps, setStep, isLastStep, sectorQuestion, interestsAll]
    );

    //Atualiza último acesso do usuário após conclusão do cadastro de empresa
    async function updateLastAccesUser() {
        const response = await EditLastAcces(userService.getUserId());

        if (response.status === 200) {
            localStorage.setItem('first_access', JSON.stringify(false));
        } else {
            const message = response.data ? response.data.message : 'Erro ao atualizar último acesso usuário';
            setResponseStatus({ ...responseStatus, message: message, error: true, succsess: false });
            resetState(false);
        }
    }

    // async function sendWelcome(companyId) {
    //     await SendWelcome(companyId);
    // }
    // async function updateUseFlowStep() {
    //     const data = { tag: stepsEnum.concluiu_wizard };
    //     RegisterUseFlowStep(data);
    // }
    // async function sendWelcomeMail(name, mail) {
    //     await SendWelcomeMail(name, mail);
    // }
    // async function sendModalRelatorioVazio(userId) {
    //     await SendModalRelatorioVazio(userId);
    // }

    async function salvarEmpresa() {
        const data = {
            respostas: getSectorQuestion(),
            perguntas_respostas: getSectorAnswer(),
            interesses: listInterests(),

            cidade_id: !!formRegistration.getValues('cidade') ? formRegistration.getValues('cidade').cidade_id : null,

            razao_social: !!formRegistration.getValues('razao_social')
                ? formRegistration.getValues('razao_social')
                : formRegistration.getValues('razao_social_default'),
        };

        if (!!formRegistration.getValues('celular')) {
            data.celular = formRegistration.getValues('celular');
        }

        if (!!formRegistration.getValues('cpfCnpj')) {
            if (formRegistration.getValues('cpfCnpj').length === 18) {
                data.cnpj = formRegistration.getValues('cpfCnpj');
            } else {
                data.cpf = formRegistration.getValues('cpfCnpj');
            }
        }

        if (isLastStep) {
            data.enviaFilaFinance = true;
        } else {
            //Variável colocada para que as validações da ultima tela não travem a primeira
            data.skipLastStepValidations = true;
        }

        const response = await EditCompany(userService.getCompanyId(), data);

        if (response.status === 200) {
            if (isLastStep) {
                await updateLastAccesUser();
                await sendSectorInsight();
                //await sendWelcome(userService.getCompanyId()) //Entitlement
                //const userId = userService.getUserId()
                // await updateUseFlowStep(); // We do not have RD Station integration anymore '13/06/2024'
                //await sendModalRelatorioVazio(userId) //Fica no cadastro
            } else {
                setResponseStatus({
                    ...responseStatus,
                    message: 'Registro atualizado com sucesso',
                    succsess: true,
                    error: false,
                });
                resetState(false);
            }
        } else {
            const message = response.data ? response.data.message : 'Erro ao atualizar a empresa';
            setResponseStatus({ ...responseStatus, message: message, error: true, succsess: false });
            resetState(false);
            return false;
        }

        return true;
    }

    async function getInsighQuestion() {
        const response = await GetQuestionByType('insight_sector_analysis');
        if (response.status === 200) {
            setSectorQuestion(response.data.data[0]);
        } else {
            const message = !!response ? response.data.message : 'Erro ao obter perguntas';
            setResponseStatus({ ...responseStatus, message: message, error: true });

            resetState(false);
        }
    }

    async function getAllInterests() {
        const response = await GetInterests();
        if (response.status === 200) {
            const responseReturn = response.data.data.filter((item) => item.exibir == true);
            setInterestsAll(responseReturn);
        } else {
            const message = !!response ? response.data.message : 'Erro ao obter interesses';
            setResponseStatus({ ...responseStatus, message: message, error: true });

            resetState(false);
        }
    }

    async function getAllStates() {
        const response = await GetState();
        if (response.status === 200) {
            setStates(response.data.data);
        } else {
            const message = !!response ? response.data.message : 'Erro ao obter estados';
            setResponseStatus({ ...responseStatus, message: message, error: true });

            resetState(false);
        }
    }

    async function getAllCityByState(id) {
        setLoading(true);
        const response = await GetCityByState(id);
        if (response.status === 200) {
            setCity(response.data.data);
        }

        setLoading(false);
    }

    const success = <Alert variant="succsess">{responseStatus.message}</Alert>;
    const error = (
        <div className="alert alert-danger" role="alert">
            {responseStatus.message}
        </div>
    );

    function resetState(success) {
        setTimeout(() => {
            if (success) {
                window.location = '/conclusion';
            } else {
                setResponseStatus({ ...responseStatus, succsess: false, error: false });
                setLoading(false);
            }
        }, 3000);
    }

    const onPrevClick = useCallback(
        (event) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    );

    function stepPage() {
        {
            if (isConcluded) {
                return <Apresentation isWelcome={false} onClickIgnore={onClickIgnore} />;
            }
            switch (step) {
                case 0:
                    return (
                        <FormProvider {...formAbout}>
                            <AvForm className="company-form validate-form">
                                {/* <AboutCompany questionsAll={questionsAll} /> */}
                                <AboutCompanyInsight sectorQuestion={sectorQuestion} />
                            </AvForm>
                        </FormProvider>
                    );
                case 1:
                    return (
                        <FormProvider {...formInterests}>
                            <AvForm className="company-form validate-form">
                                <Interests interestsAll={interestsAll}></Interests>
                            </AvForm>
                        </FormProvider>
                    );

                default:
                    return (
                        <FormProvider {...formRegistration}>
                            <AvForm className="company-form validate-form">
                                <RegistrationData
                                    selectedValuePeople={selectedValuePeople}
                                    setSelectedValuePeople={setSelectedValuePeople}
                                    states={states}
                                    city={city}
                                    canChooseCpf={canChooseCpf()}
                                    canChooseCnpj={canChooseCnpj()}
                                    getAllCityByState={(e) => getAllCityByState(e)}></RegistrationData>
                            </AvForm>
                        </FormProvider>
                    );
            }
        }
    }

    // function onConclude() {
    //     setIsConcluded(true);
    // }

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getInsighQuestion();
            await getAllInterests();
            await getAllStates();
            setLoading(false);
        })();
    }, []);

    //Quando atualizar  as questões, obtém empresa
    useEffect(() => {
        (async () => {
            await getByIdCompany();
        })();
    }, [sectorQuestion]);

    async function getByIdCompany() {
        const responseEmpresa = await GetCompany(userService.getCompanyId());

        if (responseEmpresa.status === 200) {
            const empresa = responseEmpresa.data.data;
            let aboutCompanyForm = {};
            let interesseForm = {};
            let registrationDataForm = {};

            //Dados Passo 1*********************************************
            const responseAnswer = empresa.respostas.find((p) =>
                p.perguntas.find((x) => x.pergunta_id === sectorQuestion.pergunta_id)
            );
            aboutCompanyForm['pergunta' + sectorQuestion.pergunta_id] = responseAnswer;
            formAbout.reset(aboutCompanyForm);

            //Dados do 2° passo*******************************************
            empresa.interesses.map((interesse) => {
                interesseForm['check' + interesse.interesse_id] = true;
            });
            formInterests.reset(interesseForm);

            //Dados do 3° passo*******************************************
            registrationDataForm.razao_social = empresa.razao_social;
            registrationDataForm.razao_social_default = empresa.razao_social;
            if (empresa.celular != null) {
                registrationDataForm.celular = empresa.celular;
            }

            if (!!empresa.cpf || !!empresa.cnpj) {
                registrationDataForm.cpfCnpj = empresa.cpf === '' ? empresa.cnpj : empresa.cpf;
                setSelectedValuePeople(empresa.cpf === '' ? 'cnpj' : 'cpf');
            }

            if (empresa.cidade_id !== null) {
                registrationDataForm.estado_id = {
                    estado_id: empresa.cidade.estado.estado_id,
                    nome: empresa.cidade.estado.nome,
                };
                await getAllCityByState(empresa.cidade.estado.estado_id);
                registrationDataForm.cidade = { cidade_id: empresa.cidade.cidade_id, nome: empresa.cidade.nome };
            }
            formRegistration.reset(registrationDataForm);
        }
    }

    return (
        <>
            <Row>
                <Col md="1"></Col>
                <Col md="10">
                    <Stepper value={step} items={steps} />
                    <br />
                    <br />
                    {responseStatus.succsess ? success : null}
                    {responseStatus.error ? error : null}
                    {stepPage()}
                </Col>
            </Row>
            {!isConcluded && (
                <Row>
                    <hr className="button-divisor" />
                    <div className="company-form">
                        <h5 style={{ display: 'inline', position: 'absolute' }}>{`Passo ${step + 1} de 3`}</h5>
                        <div className="container-butons">
                            {step !== 0 ? (
                                <Button
                                    className="pull-button-right"
                                    style={{ marginRight: '16px' }}
                                    onClick={onPrevClick}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Voltar">
                                    Voltar
                                </Button>
                            ) : undefined}

                            <Button
                                primary={true}
                                onClick={(e) => onSubmit(e)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title={isLastStep ? 'Concluir' : 'Avançar'}>
                                {!loading ? isLastStep ? 'Concluir' : 'Avançar' : <Loader />}
                            </Button>
                        </div>
                    </div>
                </Row>
            )}
        </>
    );
}
