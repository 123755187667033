import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Button } from '@progress/kendo-react-buttons';
import { GoogleLogin } from 'react-google-login';
import ShowMessageTerm from './ShowMessageTerm';
import { Modal, ModalBody, ModalFooter, ModalHeader, Alert } from "reactstrap";
import RelevoModal from '../../components/Relevo/RelevoModal';
import { PostFacebookRegister, PostGoogleRegister, PostResendMail } from '../../models/auth';
import { RegisterLog } from './../../models/log';
import jwt_decode from 'jwt-decode';
import log from './../../types/log/log';
import codesEnum from './../../types/enum/codesEnum';
import Spinner from './../../components/Spinner';
import stepsEnum from '../../types/enum/useFlowSteps';
import { RegisterUseFlowStep } from './../../models/log';
import * as variablesConstants from '../../constants/variables';
import { SendModalRelatorioVazio } from './../../models/notifications';


import './../../assets/scss/custom/pages/Auth/login.scss';
// import { default as logo, default as logoDarkRelevo, default as logoSm } from './../../assets/images/logo-beta.svg';
import logoRelevo from './../../assets/images/simplific.png';


async function _updateUseFlowStep() {
  const data = { 'tag': stepsEnum.cadastro_simplific }
  await RegisterUseFlowStep(data)
}

const OptionsSignIn = () => {

  const [responseStatus, setResponseStatus] = useState({
    message: '',
    succsess: false,
    error: false,
  });

  const [load, setLoad] = useState(false);
  const [successModal, setSuccessModal] = useState(false)
  const [resendModal, setResendModal] = useState(false)
  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const [isErrorResend, setIsErrorResend] = useState(false)
  const [errorResendMessage, setErrorResendMessage] = useState(false)
  const [mailUser, setMailUser] = useState()
  const [resendLoad, setResendLoad] = useState()

  const success = <Alert variant="succsess">{responseStatus.message}</Alert>;
  const customBody = <p className={'resend-modal-text-blue'}>Por favor, verifique seu e-mail e ative seu usuário.</p>

  async function confirmResendMail(mail) {
    setResendLoad(true)
    const data = await PostResendMail(mail)
    if (data.status == 200) {
      setIsOpenAlert(true)
      setTimeout(() => {
        setResendModal(false)
        setIsOpenAlert(false)
        setResendLoad(false)
      }, 2000);
    }
    else {
      setIsOpenAlert(true)
      setIsErrorResend(true)
      setErrorResendMessage(data.data.message)
      setTimeout(() => {
        setResendLoad(false)
        setIsOpenAlert(false)
        setIsErrorResend(false)

      }, 2000);
    }
  }

  const error = (
    <div class="alert alert-danger" role="alert">
      {responseStatus.message}
    </div>
  );

  async function registerLog(code_id, user_id) {
    log.user_id = user_id;
    log.code_id = code_id;

    await RegisterLog(log);
  }

  function resetState(success) {
    setTimeout(() => {
      if (success) {
        setSuccessModal(true)
      } else {
        setResponseStatus({ ...responseStatus, succsess: false, error: false });
        setLoad(false);
      }
    }, 3000);
  }

  const handleGoogleLogin = async (googleData) => {

    const response = await PostGoogleRegister(googleData.tokenId);

    if (response) {
      if (response.status === 200) {
        localStorage.setItem('token', JSON.stringify(response.data.token));
        await _updateUseFlowStep()

        const res = response.data;

        await SendModalRelatorioVazio(res.data.usuario_id);

        registerLog(codesEnum.login_usuario, res.data.usuario_id);
        localStorage.setItem('first_access', JSON.stringify(res.data.primeiro_acesso));
        setResponseStatus({ ...responseStatus, message: res.message, succsess: true });
        // window.location = '/apresentation';
        window.location = '/home/dashboard?modal='+res.data.primeiro_acesso;
        resetState(true, res.data.primeiro_acesso);
      } else {
        const message = response ? response.data.message : 'Erro ao cadastrar usuário';
        setResponseStatus({ ...responseStatus, message: message, error: true });
        resetState(false);
      }
    }
  }

  const handleFacebookLogin = async (data) => {
    const response = await PostFacebookRegister(data.authResponse.accessToken);

    if (response) {
      if (response.status === 200) {
        await _updateUseFlowStep()
        localStorage.setItem('token', JSON.stringify(response.data.token));
        await _updateUseFlowStep();

        const res = response.data;

        await SendModalRelatorioVazio(res.data.usuario_id);

        registerLog(codesEnum.login_usuario, res.data.usuario_id);
        localStorage.setItem('first_access', JSON.stringify(res.data.primeiro_acesso));
        setResponseStatus({ ...responseStatus, message: res.message, succsess: true });
        // window.location = '/apresentation';
        window.location = '/home/dashboard?modal='+res.data.primeiro_acesso;
        resetState(true, res.data.primeiro_acesso);
      }
    } else {
      const message = response ? response.data.message : 'Erro ao cadastrar usuário';
      setResponseStatus({ ...responseStatus, message: message, error: true });
      resetState(false);

    }
  }


  return (
    <>
      <div className="limiter">
        <div className="container-login100" style={{ minHeight: '95vh' }}>
          <div>
            <div className="wrap-login100">
              <span className="login100-form-title p-b-26">
                <img src={logoRelevo} alt="logo" height="63" width="244" />
              </span>
              {responseStatus.error ? error : null}
              <Row>

                <Col className="col-button-google">
                  <GoogleLogin
                    style={{ background: 'red' }}
                    clientId={variablesConstants.REACT_APP_GOOGLE_CLIENT_ID}
                    buttonText="Cadastrar com o Google"
                    onSuccess={(e) => handleGoogleLogin(e)}
                    onFailure={(e) => handleGoogleLogin(e)}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '15px' }}>
                <Col className="col-button-facebook">
                  <button
                    type="button"
                    // onClick={()=> window.FB.login((e)=>handleFacebookLogin(e), {scope: 'public_profile,email'})}>
                    onClick={() => window.FB.login((e) => handleFacebookLogin(e), { scope: 'public_profile,email', auth_type: 'reauthorize' })}>
                    <span style={{ fontSize: '25px' }}>
                      <i className="mdi mdi-facebook-box" ></i>
                    </span>
                    <span style={{ bottom: '4px', position: 'relative' }}>
                      &nbsp;Cadastrar com o Facebook
                    </span>
                  </button>
                </Col>
              </Row>
              <Row style={{ marginTop: '15px' }}>
                <Col>
                  <Button
                    className="k-primary k-button k-grid-edit-command"
                    style={{ width: '100%' }}
                    type="button"
                    onClick={() => window.location.href = '/account/create-first-user'}
                    disabled={false}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Cadastrar">
                    Cadastrar com email e senha
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="regUser">
              <a className="linksLogin" href="/account/login">
                Já tenho um cadastro
              </a>
            </div>
          </div>

        </div>
        <ShowMessageTerm />
      </div>
    </>
  )
}

export default OptionsSignIn;