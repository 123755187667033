import api from '../services/api';

const controller = '/entitlement/termo_aceite';

export const GetLastVersionActive = async () => {
    return await api
        .get(`${controller}/obter-ultima-versao`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};
