import Axios from 'axios';
import _ from 'lodash';
import { FormaterReal } from '../helpers/Utils';
import api from '../services/api';

var CancelToken = Axios.CancelToken;
let payment_cancel, bankAccount_cancel;

export const GetAllInstitution = async (id = null) => {
    if (id === null) return [];
    try {
        const { data } = await api.get(`/finance-erp/institution/type/${id}`);
        return data;
    } catch {
        return [];
    }
};
export const GetOneInstituition = async (id = null) => {
    if (id === null) return [];
    try {
        const { data } = await api.get(`/finance-erp/institution/${id}`);
        return data;
    } catch {
        return [];
    }
};

export const DeleteBankAccount = async (id) => {
    if (!id) return false;
    try {
        const { data } = await api.delete(`/finance-erp/bank-account-new/${id}`);
        return true;
    } catch {
        return false;
    }
};

export const DeleteBillToPay = async (id) => {
    if (!id) return false;

    try {
        const { data } = await api.delete(`/finance-erp/account-payment/${id}`);
        return true;
    } catch {
        return false;
    }
};

export const GetAllInstitutionType = async () => {
    try {
        const { data } = await api.get(`/finance-erp/institution-type`);
        return data;
    } catch {
        return [];
    }
};

// export const GetAllAccounts = async () => {
//     try {
//         const { data } = await api.get(`/finance-erp/bank-account/all`);
//         return data;
//     } catch {
//         return [];
//     }
// };

export const GetSearchAccount = async (e) => {
    try {
        const { data } = await api.get(`/finance-erp/bank-account/search/${e}`);
        return data;
    } catch {
        return false;
    }
};

export const GetSearchSuplies = async (search = '') => {
    try {
        const { data } = await api.get(`/contacts-erp/contact-search/${search}`);
        return data.contact.result;
    } catch {
        return [];
    }
};

export const GetAllSuppliers = async () => {
    try {
        const { data } = await api.get(`/contacts-erp/contact/contact-list-all-contacts`);
        return data;
    } catch {
        return [];
    }
};

export const GetTableBankAccounts = async (numPage = 1, perPage = 10, e = '%20', params) => {
    try {
        bankAccount_cancel && bankAccount_cancel()
        const { data } = await api.post(
            `/finance-erp/bank-account-new/search/numerPage=${numPage}&perPage=${perPage}&search=${e}`,
            params, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                bankAccount_cancel = c;
            }
            )
        }
        );
        return data;
    } catch {
        return false;
    }
};

export const GetTableAccounts = async (typeAccount = 1, numPage = 1, perPage = 10) => {
    try {
        const { data } = await api.get(
            `/finance-erp/account-all-new/typeAccount=${typeAccount}&numerPage=${numPage}&perPage=${perPage}`
        );
        return data;
    } catch {
        return [];
    }
};

export const GetAllPayments = async (numPage = 1, perPage = 10, search = '%20', typeAccount, params) => {
    try {
        payment_cancel && payment_cancel()
        const { data } = await api.post(
            `/finance-erp/account-payment/typeAccount=${typeAccount}&numerPage=${numPage}&perPage=${perPage}&search=${search}`,
            params, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                payment_cancel = c;
            }
            )
        });
        return {
            data: data.data.result,
            pageCount: data.data.totalItemCount,
        };
    } catch {
        return [];
    }
};

export const GetAllCategories = async (billsType, categoryId = null) => {

    try {
        const { data } = await api.get(`/finance-erp/category-for-type/${billsType}/${categoryId}`);
        return data.data;
    } catch {
        return [];
    }
};

export const GetAllProjects = async () => {
    try {
        const { data } = await api.get(`/finance-erp/project`);
        return data.result;
    } catch {
        return [];
    }
};

export const PostProject = async (project) => {
    try {
        await api.post(`/finance-erp/project`, project);
        return true;
    } catch (e) {
        if (e.response.status === 409) {
            return false;
        } else {
            return null;
        }
    }
};

export const Simulate = async (billsToPay_forms) => {
    let newForm = await _.cloneDeep(billsToPay_forms);

    try {
        //const { data } = await api.post(`/finance-erp/account-new/simulator`, newForm);
        const { data } = await api.post(`/finance-erp/simulator/account`, newForm);
        return data;
    } catch (e) {
        return [];
    }
};

export const putAccount = async (id, account_forms) => {
    if (account_forms.agency) {
        account_forms.agency = account_forms.agency.toString();
    }

    if (account_forms.accountNumber) {
        account_forms.accountNumber = account_forms.accountNumber.toString();
    }

    try {
        const data = await api.put(`/finance-erp/bank-account-new/${id}`, account_forms);
        return true;
    } catch (e) {
        return e;
    }
};


export const putAccountNew = async (id, account_forms) => {
    if (account_forms.agency) {
        account_forms.agency = account_forms.agency.toString();
    }

    if (account_forms.accountNumber) {
        account_forms.accountNumber = account_forms.accountNumber.toString();
    }

    try {
        const data = await api.put(`/finance-erp/bank-account-new/${id}`, account_forms);
        return true;
    } catch (e) {
        return false;
    }
};


export const postAccount = async (account_forms) => {
    //cast dos dados bancarios para string
    if (account_forms.agency) {
        account_forms.agency = account_forms.agency.toString();
    }

    if (account_forms.accountNumber) {
        account_forms.accountNumber = account_forms.accountNumber.toString();
    }

    try {
        await api.post(`/finance-erp/bank-account-new`, account_forms);
        return true;
    } catch (e) {
        return e;
    }
};


///  METODO PARA A PAGINA NOVA. 
///  Criado para não interfirir no funcionamento de sua antecessora
export const CreateAccount = async (account_forms) => {
    //cast dos dados bancarios para string
    if (account_forms.agency) {
        account_forms.agency = account_forms.agency.toString();
    }

    if (account_forms.accountNumber) {
        account_forms.accountNumber = account_forms.accountNumber.toString();
    }

    try {
        await api.post(`/finance-erp/bank-account-new`, account_forms);
        return true;
    } catch (e) {
        return false;
    }
};


export const getFileForDownload = async (fileId) => {
    try {
        const { data } = await api.get(`/finance-erp/get-file-download/${fileId}`);
        return data;
    } catch (e) {
        return false;
    }
};

export const postBillToPay = async (billsToPay_forms) => {
    try {
        if (billsToPay_forms.documentTypeId && billsToPay_forms.documentTypeId.value)
            billsToPay_forms.documentTypeId = billsToPay_forms.documentTypeId.value
        const data = await api.post(`/finance-erp/account-new`, billsToPay_forms);

        const response = {
            status: true,
            id: data.data.id,
        };
        return response;
    } catch (e) {
        return e;
    }
};

export const GetContaCorreteById = async (id) => {
    try {
        const { data } = await api.get(`/finance-erp/bank-account-new/${id}`);
        return data.account;
    } catch {
        return false;
    }
};

export const DeleteFileAccount = async (id) => {
    if (!id) return false;
    try {
        const { data } = await api.delete(`/finance-erp/account/file/${id}`);
        return true;
    } catch {
        return false;
    }
};

export const GetAccountOptions = async () => {
    try {
        const { data } = await api.get(`/finance-erp/bank-account/options`);
        return data;
    } catch {
        return {
            data: []
        };
    }
};


export const DeleteBillList = async (params) => {
    try {
        const { data } = await api.post(`finance-erp/remove-bill-list`, params);
        return data;
    } catch {
        return false;
    }
};

export const SendMailHelp = async (body) => {
    try {
        const { data } = await api.post(`notify-erp/notifications/sendMail/support`, body);
        return data;
    } catch {
        return false;
    }
}
