import React from 'react';
import { RelevoBadge, TYPES_BADGE } from '../../../../components/Relevo/RelevoBadge';

export default function ActiveInactiveCell(e, strSituacaoAtivo) {

  const { dataItem, field } = e;
  let cellData = dataItem[field];
  if(strSituacaoAtivo){
    cellData = cellData == strSituacaoAtivo;
  }
  const badge = {
    text: cellData ? "Ativo" : "Inativo",
    type: cellData ? TYPES_BADGE.SUCCESS : TYPES_BADGE.DANGER
  }

  return (
    cellData !== undefined ?
      <td>
        <RelevoBadge {...badge} />
      </td>
      : <td></td>
  );
}

