import React from 'react';

import gestao_girl from '../../../../assets/images/gestao_girl.png';


const DowngradeStep0 = ({ }) => {


  return (
    <>
      <span className="downgrade-span-title">Migração de Plano</span>
      <br />
      <br />
      <img src={gestao_girl} alt="logo" height="290" width="290" />
      <br />
      <p className="paragraph1">Você precisa realizar alguns passos para retornar ao plano gratuito e continuar usando o Simplific Gestão.</p>
      <p className="paragraph2">
        Ajustar o número de usuários ativos, ajustar o número de contas correntes ativas,
        número de categorias ativas
        e selecionar todos os anexos que gostaria de fazer download,
        pois serão excluídos quando retornar ao plano gratuito.
      </p>
      
    </>
  )
}


export default DowngradeStep0;