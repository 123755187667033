
export const PasswordValidationMeta = {
  visible: true,
  value: '',
  confirmPassword: '',
  validations: [
    {
      id: 1,
      text: 'No mínimo 8 caracteres',
      class: 'none-validated-field',
      validate: (text) => {
        text = formatText(text);
        return (text.trim().length >= 8);
      }
    },
    // {
    //   id: 2,
    //   text: 'Letra maiúscula e minúscula',
    //   class: 'none-validated-field',
    //   validate: (text) => {
    //     text = formatText(text);
    //     return ((/[A-Z]/.test(text)) && (/[a-z]/.test(text)));
    //   }
    // },

    // {
    //   id: 3,
    //   text: 'Letra minúscula',
    //   class: 'none-validated-field',
    //   validate: (text) => {
    //     text = formatText(text);
    //     return ((/[a-z]/.test(text)));
    //   }
    // },
    // {
    //   id: 3,
    //   text: 'Numeral',
    //   class: 'none-validated-field',
    //   validate: (text) => {
    //     text = formatText(text);
    //     return ((/\d/.test(text)));
    //   }
    // },
    // {
    //   id: 4,
    //   text: 'Carácter especial',
    //   class: 'none-validated-field',
    //   validate: (text) => {
    //     text = formatText(text);
    //     return ((/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(text)));
    //   }
    // },
  ]
}

export const ConfirmPasswordValidationMeta = {
  visible: false,
  validations: [{
    text: 'A confirmação de senha não confere com a senha criada',
    validate: (password, confirmPassword) => {
      return password == confirmPassword;
    }
  }]
}

const formatText = (text) => {
  text = text == undefined ? '' : text;
  return text;
}