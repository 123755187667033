import React from "react";
import gestao_girl from '../../../assets/images/gestao_girl.png';
import pessoasFelizes from '../../../assets/images/pessoas-felizes.png';
import caraFeliz from '../../../assets/images/cara-feliz.png';
import stonksMan from '../../../assets/images/stonks-man.png';
import analiticMan from '../../../assets/images/analitic-man.png';

export const LoginMeta = [
    {
        label: "Email",
        name: "email",
        type: "email"
    },
    {
        label: "Nome de Usuário",
        name: "nome"
    },
    {
        label: "Senha",
        name: "senha",
        type: "password"
    },
    {
        label: "Confirmar Senha",
        name: "senha_confirm",
        type: "password"
    },

]

export const GetCreateNewAccountTextByType = (type) => {
    switch (type) {

        case 'marketing':
            return {
                slogan: (<>Faça mais<br /> com o Simplific.</>),
                image: null,
                backgroundImage: pessoasFelizes,
                description: (<>
                    Faça a gestão do seu financeiro do forma inteligente,<br />
                    prevendo o seu faturamento futuro e definindo as melhores<br />
                    estratégias para o seu negócio!
                </>),

                title: (<>
                    Analise suas receitas, despesas<br />
                    e fluxo de caixa rapidamente<br />
                    com o Simplific Gestão.
                </>),
                titleDescription: (<>
                    Crie uma conta e senha para acessar o Simplific em qualquer<br />
                    lugar e quando quiser.
                </>)
            }

        case 'ebook-fluxo-de-caixa':
            return {
                slogan: (<>Faça mais<br /> com o Simplific.</>),
                image: caraFeliz,
                backgroundImage: null,
                description: (<>
                    Planeje, acompanhe e organize seu negócio<br />
                    sem a necessidade de ser um especialista em <br />
                    finanças para tomar as melhores decisões.
                </>),

                title: (<>
                    <b>Obrigado!</b><br />
                    Você receberá o material por e-mail.<br />
                    <b>Teste grátis</b> o Simplific Gestão e <br />
                    analise seu fluxo de caixa rapidamente!
                </>),
                titleDescription: (<>
                    Crie uma conta e senha para acessar o Simplific em qualquer<br />
                    lugar e quando quiser.
                </>)
            }
        case 'ebook-agradecimento-teste-gratis':
            return {
                slogan: (<>Faça mais<br /> com o Simplific.</>),
                image: stonksMan,
                backgroundImage: null,
                black: true,
                description: (<>
                    Planeje, acompanhe e organize seu negócio<br />
                    sem a necessidade de ser um especialista em <br />
                    finanças para tomar as melhores decisões.
                </>),

                title: (<>
                    <b>Obrigado!</b><br />
                    Você receberá o material por e-mail.<br />
                    <b>Teste grátis</b> o Simplific Gestão e <br />
                    analise seu fluxo de caixa rapidamente!
                </>),
                titleDescription: (<>
                    Crie uma conta e senha para acessar o Simplific em qualquer<br />
                    lugar e quando quiser.
                </>)
            }

        case 'ebook-agradecimento-teste-gratis-2':
            return {
                slogan: (<>Faça mais<br /> com o Simplific.</>),
                image: analiticMan,
                backgroundImage: null,
                black: true,
                description: (<>
                    Planeje, acompanhe e organize seu negócio<br />
                    sem a necessidade de ser um especialista em <br />
                    finanças para tomar as melhores decisões.
                </>),

                title: (<>
                    <b>Obrigado!</b><br />
                    Você receberá o material por e-mail.<br />
                    <b>Teste grátis</b> o Simplific Gestão e <br />
                    analise seu fluxo de caixa rapidamente!
                </>),
                titleDescription: (<>
                    Crie uma conta e senha para acessar o Simplific em qualquer<br />
                    lugar e quando quiser.
                </>)
            }

        default:
            return {
                slogan: (<>Faça mais<br /> com o Simplific.</>),
                image: gestao_girl,
                description: (<>
                    Planeje, acompanhe e organize seu negócio<br />
                    sem a necessidade de ser um especialista em <br />
                    finanças para tomar as melhores decisões.
                </>),

                title: (<>Aumente a lucratividade do<br /> seu negócio com um sistema <br /> de gestão financeira<br /> inteligente.</>),
                titleDescription: (<>
                    Crie uma conta e senha para acessar o Simplific em qualquer<br />
                    lugar e quando quiser.
                </>)
            }
    }
}


export const VerifyDataLogin = (data) => {
    const { email, nome, senha, senha_confirm } = data;
    if (!email || !nome || !senha || !senha_confirm) {
        return false;
    }
    return true;
}