// @flow
import React, { Component } from 'react';
import { Loader } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import RelevoModal from './Relevo/RelevoModal';
import CustomInfoModal from './Relevo/RelevoNewInfoModal';

import SimpleBar from 'simplebar-react';

const notificationContainerStyle = {
    maxHeight: '230px',
    display: 'none',
};

const notificationShowContainerStyle = {
    maxHeight: '230px',
};

const smallTextStyle = {
    textOverflow: 'ellipsis',
    fontSize: '0.75rem',
    fontWeight: '400'
}

type NotificationItem = {
    id: number,
    text: string,
    subText: string,
    icon: string,
    bgColor: string,
};

type NotificationDropdownProps = {
    notifications: Array<NotificationItem>,
};

type NotificationDropdownState = {
    dropdownOpen?: boolean,
    notificationContainerStyle?: {},
};

class NotificationDropdown extends Component<NotificationDropdownProps, NotificationDropdownState> {
    static defaultProps = {
        notifications: [],
    };

    constructor(props: NotificationDropdownProps) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);

        this.state = {
            dropdownOpen: false,
            notificationContainerStyle: notificationContainerStyle,
        };
    }

    /*:: toggleDropdown: () => void */
    toggleDropdown() {
        if (this.state.dropdownOpen) {
            this.props.updateVisualizedDate();
        }

        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
            notificationContainerStyle:
                this.state.notificationContainerStyle === notificationContainerStyle
                    ? notificationShowContainerStyle
                    : notificationContainerStyle,
        });
    }

    getRedirectUrl = (item: NotificationItem) => {
        return `/notification/${item.id}`;
    };

    _handleCloseNotification(){
        this.setState({
            NotificationOpen:false,
            selectedNotification:null
        })
    }

    _renderModalNotification(){        
        return (
            this.state.NotificationOpen ?
                <CustomInfoModal
                        modalId="notification-modal"
                        header={this.state.selectedNotification.Title}
                        text={this.state.selectedNotification.Text}
                        type={this.state.selectedNotification.ThemeColorId}
                        visible={this.state.NotificationOpen}
                        onClose={()=> this._handleCloseNotification()}
                        load={false}
                    />
                    :
                    null
        )
    }

    _renderNotifications() {
        const { notifications = [] } = this.props;
        if (notifications.length <= 0) {
            return (
                <div style={{ paddingBottom: 10, fontSize: 12 }}>
                    <center>
                        <span>Não há nenhuma notificação!</span>
                    </center>
                </div>
            )
        }

        return notifications.map((item, i) => (
            <>
                {!item.VisualizedDate &&
                    <span className="noti-icon-badge notification-item"></span>
                }
                <Link
                    data-toggle="tooltip"
                    data-placement="top"
                    title={item.Text}
                    to={item.Url} //{this.getRedirectUrl(item)}
                    className="dropdown-item notify-item"
                    style={{ marginTop: (!item.VisualizedDate) ? '-18px' : '0px' }}
                    key={i + '-noti'}
                    onClick={(e)=>{
                        console.log(item)
                        this.setState({
                            NotificationOpen:true,
                            selectedNotification:item
                        })
                    }}
                    >

                    <div className={`notify-icon`} style={{ backgroundColor: item.ColorHex }}>
                        <i className="mdi mdi-comment-account-outline"></i>
                    </div>
                    <p className="notify-details">
                        {item.Title}
                        <span className="text-muted" style={smallTextStyle}>{item.Text}</span>
                    </p>
                    
                </Link>
                {this._renderModalNotification()}
            </>
        ));
    }

    render() {
        const { load } = this.props;
        return (
            <>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle
                        data-toggle="dropdown"
                        tag="button"
                        className="nav-link dropdown-toggle arrow-none btn btn-link"
                        onClick={this.toggleDropdown}
                        aria-expanded={this.state.dropdownOpen}>
                        <span data-toggle="tooltip" data-placement="left" title="Notificações">
                            <i className="mdi mdi-bell noti-icon"></i>
                        </span>

                        {/* <span className="noti-icon-badge" style={{ margin: -3 }}></span> */}
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-animated dropdown-lg">
                        <div onClick={this.toggleDropdown}>
                            <div className="dropdown-item noti-title">
                                <h5 className="m-0">
                                    <span className="float-right">
                                        {/* <Link to="/notifications" className="text-dark">
                                        <small>Clear All</small>
                                    </Link> */}
                                    </span>
                                    Notificações
                                </h5>
                            </div>
                            <SimpleBar style={this.state.notificationContainerStyle}>
                                {load ?
                                    <>
                                        {this._renderNotifications()}
                                    </> :
                                    <center>
                                        <div style={{ paddingBottom: 20 }}>
                                            <Loader />
                                        </div>
                                    </center>}
                            </SimpleBar>


                            {/* <Link to="/" className="dropdown-item text-center text-primary notify-item notify-all">
                            View All
                        </Link> */}
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </>
        );
    }
}

export default NotificationDropdown;
