import React, { useState, useEffect, useContext } from 'react';
import jwt_decode from 'jwt-decode';
import { saveAs } from 'file-saver';

import AuthContext from '../../../../context/authContext';

import { Button } from '@progress/kendo-react-buttons';
import { Row, Col, Alert } from 'reactstrap';
import { Loader } from 'react-bootstrap-typeahead';

import { GetAllUser } from '../../../../models/auth';
import { GetTableBankAccounts } from '../../../../models/account';
import { GetAllPlans } from '../../../../models/billing';
import {
  UpdateStatusUsers,
  UpdateStatusAccounts,
  GetAllFilesAccounts,
  GetAllFilesContacts,
  UpdateStatusCategories,
  DeleteAllAttachmentsAccounts,
  DeleteAllAttachmentsContacts
} from '../../../../models/downgrade';
import { CancelSubscription, ChangeSubscriptionToFree, RenewPayment } from '../../../../models/billing';
import { GetAllCategoriesType } from '../../../../models/category';

import ShowMessageTerm from '../../../../pages/auth/ShowMessageTerm';
import Topbar from '../../../../components/Topbar';

import DowngradeStep0 from './downgradeStep0';
import DowngradeSelectionScreen from './downgradeSelectionScreen';
import DowngradeScreenAttachment from './downgradeScreenAttachment';
import functionalitiesEnum from '../../../../types/enum/functionalitiesEnum';

import gestao_girl from '../../../../assets/images/gestao_girl.png';
import '../../../../assets/scss/custom/pages/Auth/apresentation.scss';
import '../../../../assets/scss/custom/pages/Auth/login.scss';

const DowngradePayment = ({ }) => {

  const userService = useContext(AuthContext);
  const company_id = userService.getCompanyId();
  const user_id = userService.getUserId();
  const plan_id = userService.getPlan().Plan.Id;

  const [step, setStep] = useState(0);
  const [validContinue, setValidContinue] = useState(true);
  const [loading, setLoading] = useState(true);
  const [responseStatus, setResponseStatus] = useState({
    message: '',
    succsess: false,
    error: false,
  });

  const [resultAttachmentsAccounts, setResultAttachmentsAccounts] = useState(false);
  const [resultAttachmentsContacts, setResultAttachmentsContacts] = useState(false);


  // ============== USERS

  const [users, setUsers] = useState([]);
  const [quantUsersLimit, setQuantUsersLimit] = useState(1);


  const getUsers = async () => {
    const response = await GetAllUser(1, 999999, company_id, true);
    setUsers(groupsFormat(response.data.data));
  }


  const onSelectUser = (userId, value) => {

    const usersTemp = [...users];
    usersTemp.find(x => x.usuario_id == userId).situacao = (value ? 'ATIVO' : 'INATIVO');


    setUsers(usersTemp);
  }

  // ============== END USERS


  // ============== ACCOUNTS

  const [accounts, setAccounts] = useState([]);
  const [quantAccountsLimit, setQuantAccountsLimit] = useState(1);

  const getAccounts = async () => {
    const response = await GetTableBankAccounts(1, 999999, '%20', {});
    setAccounts(response.data.result);
  }


  const onSelectAccount = (Id, value) => {
    const accountsTemp = [...accounts];
    accountsTemp.find(x => x.id == Id).status = value;

    setAccounts(accountsTemp);
  }

  // ============== END ACCOUNTS

  // ============== CATEGORIES

  const [categories, setCategories] = useState([]);

  const [quantCategoriesLimit, setQuantCategoriesLimit] = useState(1);

  const getCategories = async () => {
    const responseCategory = await GetAllCategoriesType();
    const result = responseCategory.result;

    const aux = [];
    result.forEach(type => {
      type.children.forEach(group => {
        group.children.forEach(category => {
          aux.push({
            id: category.categoryId,
            status: category.status,
            name: category.name,
            //name: group.name + ' > ' + category.name,
            group: group.name,
            type: type.name,
            dre: category.dre,
          })
        })
      })
    })

    setCategories(aux);
  }
  const onSelectCategory = (Id, value) => {
    const categoriesTemp = [...categories];
    categoriesTemp.find(x => x.id == Id).status = value;

    setCategories(categoriesTemp);
  }

  // ============== END CATEGORIES

  const onClickDownload = async () => {
    if (!resultAttachmentsAccounts && !resultAttachmentsContacts) {
      setResponseStatus({ ...responseStatus, message: "Nenhum anexo foi encontrado!", succsess: false, error: true });
      setTimeout(() => {
        setResponseStatus({ ...responseStatus, succsess: false, error: false });
      }, 4000);
    }
    if (resultAttachmentsAccounts) {
      onDownload(resultAttachmentsAccounts, 'ContasPagarReceber.zip');
    }
    if (resultAttachmentsContacts) {
      onDownload(resultAttachmentsContacts, 'ClientesFornecedores.zip');
    }
  }

  async function onDownload(attachment, fileName) {
    const byteCharacters = atob(attachment);

    const byteNumbers = new Array(attachment.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "octet/stream" });

    saveAs(blob, fileName);
  }

  const steps = [
    {
      component: <DowngradeStep0 />
    },
    {
      component: <DowngradeSelectionScreen
        title={`Somente ${quantUsersLimit} usuário${quantUsersLimit >= 2 ? 's' : ''} pode${quantUsersLimit >= 2 ? 'm' : ''} ter acesso ao Simplific Gestão no plano gratuito.`}
        label={
          quantUsersLimit == 1
            ? `Neste caso é preciso deixar habilitado apenas o usuário administrador que continuará com acesso.`
            : `Neste caso é preciso deixar habilitado apenas ${quantUsersLimit} usuários, incluindo ao menos 1 administrador, que continuarão com acesso`
        }
        data={users}
        quantLimit={quantUsersLimit}
        isValidContinue={validContinue}
        columnId="usuario_id"
        columnStatus="situacao"
        valueActiveStatus="ATIVO"
        onSelect={onSelectUser}
        columnConfiguration={[{
          field: 'nome',
          title: 'Nome',
        }, {
          field: 'email',
          title: 'E-mail',
        }, {
          field: "groups_format",
          title: "Grupo de Permissões",
        }
        ]}
      />
    },
    {
      component: <DowngradeSelectionScreen
        title={
          quantAccountsLimit == 1
            ? `Somente uma conta corrente pode ficar ativa no plano gratuito.`
            : `Somente ${quantAccountsLimit} contas correntes podem ficar ativa no plano gratuito.`
        }
        label={
          quantAccountsLimit == 1
            ? `Neste caso é preciso deixar habilitado apenas a conta corrente que ficará ativa.`
            : `Neste caso é preciso deixar habilitado apenas as contas correntes que ficarão ativas`
        }
        data={accounts}
        quantLimit={quantAccountsLimit}
        isValidContinue={validContinue}
        columnId="id"
        columnStatus="status"
        valueActiveStatus={true}
        onSelect={onSelectAccount}
        columnConfiguration={[{
          field: 'accountName',
          title: 'Nome',
        }, {
          field: 'typeName',
          title: 'Tipo de Conta',
        }, {
          field: "institutionName",
          title: "Instituição",
        }
        ]}
      />
    },
    {
      component: <DowngradeSelectionScreen
        title={`Somente ${quantCategoriesLimit} categorias podem ficar ativa no plano gratuito.`
        }
        label={`Neste caso é preciso deixar habilitado apenas as categorias que ficarão ativas`
        }
        data={categories}
        quantLimit={quantCategoriesLimit}
        isValidContinue={validContinue}
        columnId="id"
        columnStatus="status"
        valueActiveStatus={true}
        onSelect={onSelectCategory}
        columnConfiguration={[
          {
            field: 'type',
            title: 'Tipo',
            width: '130'
          },
          {
            field: 'group',
            title: 'Grupo'
          },
          {
            field: 'name',
            title: 'Nome',
          }, {
            field: "dre",
            title: "Apuração de Resultados",
          }
        ]}
      />
    },
    {
      component: <DowngradeScreenAttachment
        title="Não é possível manter os anexos aqui no Simplific Gestão no plano gratuito."
        label="Os arquivos serão excluídos! Abaixo você pode fazer o download de tudo que for precisar."
        textLink="Você pode baixar todos os seus anexos clicando aqui!"
        onCLickDownload={onClickDownload}
      />
    }
  ]

  useEffect(() => {
    verifyValidContinue();
  }, [step, users, accounts, categories])

  useEffect(async () => {
    await getPlan();
    await getUsers();
    await getAccounts();
    await getCategories();

    const resAccounts = await GetAllFilesAccounts(company_id);
    const resContacts = await GetAllFilesContacts(company_id);

    setResultAttachmentsAccounts(resAccounts);
    setResultAttachmentsContacts(resContacts);

    setLoading(false);

  }, [])


  const verifyValidContinue = () => {
    switch (step) {
      case 0:
        setValidContinue(true);
        break;
      case 1:
        isValidContinueUsers();
        break;
      case 2: // USUÁRIOS
        //isValidContinueAccounts();
        isValidContinueGeneric(accounts, 'status', quantUsersLimit);
        break;
      case 3: // CATEGORIAS
        isValidContinueGeneric(categories, 'status', quantCategoriesLimit);
        break;
    }
  }

  const isValidContinueUsers = () => {
    const hasAtLeast1Admin = (
      users
        .filter(x => x.groups_format.includes('Administrador') && x.situacao == 'ATIVO')
        .length >= 1
    );

    const quantActiveUsersIsLessThanLimit = users.filter(x => x.situacao == "ATIVO").length <= quantUsersLimit;
    setValidContinue(quantActiveUsersIsLessThanLimit && hasAtLeast1Admin);
  }

  const isValidContinueGeneric = (arr, fieldStatus, quantLimit) => {
    const quantIsLessThanLimit = arr.filter(x => x[fieldStatus] == true).length <= quantLimit;
    setValidContinue(quantIsLessThanLimit);
  }

  const getPlan = async () => {
    const plans = await GetAllPlans();
    const freePlan = plans.find(x => x.Name == 'Plano Gratuito');


    const limitUsers = Number(freePlan.Permissions.find(x => x.Identified == functionalitiesEnum.LIMIT_USER_COMPANY).Value);
    const limitAccounts = Number(freePlan.Permissions.find(x => x.Identified == functionalitiesEnum.LIMIT_CURRENT_ACCOUNT).Value);
    const limitCategories = Number(freePlan.Permissions.find(x => x.Identified == functionalitiesEnum.LIMIT_CATEGORY).Value);

    setQuantUsersLimit(limitUsers);
    setQuantAccountsLimit(limitAccounts);
    setQuantCategoriesLimit(limitCategories);
  }

  function groupsFormat(data) {
    return data.map((u) => {
      return (u = {
        ...u,
        groups_format: !!u.grupos
          ? u.grupos
            .map((element) => {
              return element.nome;
            })
            .join(', ')
          : [],
      });
    });
  }

  const concludeDowngrade = async () => {
    setLoading(true)

    const responseUsers = await UpdateStatusUsers(users);
    const responseAccounts = await UpdateStatusAccounts({ bankAccountList: accounts });
    const responseCategories = await UpdateStatusCategories({ categoryList: categories });
    const responseAttachmentsAccounts = await DeleteAllAttachmentsAccounts(company_id);
    const responseAttachmentsContacts = await DeleteAllAttachmentsContacts(company_id);

    const responseCancelSubscription = await ChangeSubscriptionToFree(user_id);

    let goToDashboard = true;

    if (responseCancelSubscription) {
      setResponseStatus({ ...responseStatus, succsess: true, message: 'Plano atualizado com sucesso!' });

      const renew = await RenewPayment();
      localStorage.setItem('token', JSON.stringify(renew.token));

    } else {
      const message = 'Erro ao realizar a regressão para o plano gratuito.';

      setResponseStatus({ ...responseStatus, message: message, succsess: false });
      goToDashboard = false;
    }
    setTimeout(() => {
      setResponseStatus({ ...responseStatus, succsess: false, error: false });
      setLoading(false);
      if (goToDashboard) {

        window.location = '/home/dashboard';
      }
    }, 4000);

  }


  const goFoward = () => {
    if (step == steps.length - 1) {
      concludeDowngrade();
      return;
    }

    setStep(step + 1);
  }

  const stepObj = steps[step];

  const success = <Alert variant="succsess" style={{ marginTop: '20px' }}>{responseStatus.message}</Alert>;
  const error = (
    <div style={{ marginTop: '20px' }} class="alert alert-danger" role="alert">
      {responseStatus.message}
    </div>
  );

  return (
    <>
      <Topbar
        hideLogo={false}
        navCssClasses={'topnav-navbar-dark topnav-term-accept'}
        hideSearch={true}
        hideNotifications={true}
        hideProfile={true}
        hideModule={true}
        hideSimplificTips={true}
        hideTalkToSpecialist={true}
      />
      <div className="limiter" style={{ textAlign: '-webkit-center', overflowY: 'hidden' }}>
        <div className="downgrade-container">
          <div>
            <div className="wrap-login100 downgrade-wrapper">
              <div className="login100-form-title p-b-26" style={{ marginBottom: '26px' }}>
                {stepObj.component ? stepObj.component : null}

                {responseStatus.succsess ? success : null}
                {responseStatus.error ? error : null}

                <hr style={{ height: '1px', backgroundColor: '#d5d3d3' }} />
                <Row>
                  <Col md={3} xs={12} sm={4} className="container-buttons-left">
                    <a href="/account/login">Cancelar Migração</a>
                  </Col>
                  <Col md={9} xs={12} sm={8} className="container-buttons-right">
                    <Button
                      className="k-primary k-button button-grey-text-blue"
                      type="button"
                      onClick={(e => (step == 0 ? window.history.go(-1) : setStep(step - 1)))}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Iniciar">
                      Voltar
                    </Button>
                    <Button
                      className="k-primary k-button"
                      type="button"
                      onClick={(e => (goFoward()))}
                      data-toggle="tooltip"
                      data-placement="top"
                      title={step == steps.length - 1 ? 'Concluir' : 'Avançar'}
                      disabled={!validContinue || loading}
                    >
                      {loading
                        ? <Loader />
                        : step == steps.length - 1 ? 'Concluir' : 'Avançar'}

                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <ShowMessageTerm
        />
      </div>

    </>
  )
}

export default DowngradePayment;