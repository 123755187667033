import api from '../services/api';

import { saveAs } from 'file-saver';


export const UpdateStatusUsers = async (body) => {
  try {
    const data = await api.post(`/entitlement/usuario/update-status-list-users`, body)

    return data;
  } catch (e) {
    return e?.response;
  }

};


export const UpdateStatusAccounts = async (body) => {
  try {
 
    const data = await api.patch(`/finance-erp/bank-account-new/update-status`, body);
    return data;
  } catch (e) {
    return e?.response;
  }
}

export const UpdateStatusCategories = async (body) => {
  try {
    const data = await api.put(`/finance-erp/update-category-status`, body);
    return data;
  } catch (e) {
    return e?.response;
  }
}

export const DeleteAllAttachmentsAccounts = async (companyId) => {
  try {
    const data = await api.delete(`/finance-erp/account/file/delete-all-files/${companyId}`);
    return data;
  } catch (e) {
    return e?.response;
  }
}

export const DeleteAllAttachmentsContacts = async (companyId) => {
  try {
    const data = await api.delete(`/contacts-erp/file/delete-all-files/${companyId}`);
    return data;
  } catch (e) {
    return e?.response;
  }
}

export const GetAllFilesAccounts = async (companyId) => {
  try {
    const url = `/finance-erp/get-all-files-download/${companyId}`;
    
    const { data } = await api.get(url);

    return data;
  } catch (e) {
    return false;
  }
}

export const GetAllFilesContacts = async (companyId) => {
  try {
    const url = `/contacts-erp/file/get-all-files-download/${companyId}`;
    const { data } = await api.get(url);

    return data;
  } catch (e) {
    return false;
  }
}