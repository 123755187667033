const codesEnum = {
  listar_clientes_fornecedores: 7,
  incluir_clientes_fornecedores: 8,
  alterar_clientes_fornecedores: 9,
  listar_contas_pagar: 10,
  incluir_contas_pagar: 11,
  alterar_contas_pagar: 12,
  listar_contas_receber: 13,
  incluir_contas_receber: 14,
  alterar_contas_receber: 15,
  listar_contas_correntes: 16,
  incluir_contas_correntes: 17,
  alterar_contas_correntes: 18,
  listar_transferencia_entre_contas: 19,
  incluir_transferencia_entre_contas: 20,
  alterar_transferencia_entre_contas: 21,
  relatorio_fluxo_caixa: 22,
  relatorio_dre: 23,
  listar_categorias: 24,
  incluir_grupo_categorias: 25,
  alterar_grupo_categorias: 26,
  incluir_categoria_categorias: 27,
  alterar_categoria_categorias: 28,
  listar_projetos: 29,
  incluir_projetos: 30,
  alterar_projetos: 31,
  listar_usuarios: 32,
  incluir_usuarios: 33,
  alterar_usuarios: 34,
  listar_grupos_permissoes: 35,
  incluir_grupos_permissoes: 36,
  alterar_grupos_permissoes: 37,
  listar_empresa: 38,
  editar_empresa: 39,
  login_usuario: 40,
  aceite_termo_uso: 41,
  exibir_passo_a_passo: 42,
  exibir_resumo: 43,
  extrato_conta_corrente: 44, //COLOCAR
  relatorio_extrato_atalho: 45,//COLOCAR
  listar_cadastro_Lote: 46,//COLOCAR
  adicionar_conta_pagar_dashboard: 49,
  adicionar_conta_receber_dashboard: 50,
  adicionar_nova_categoria_scheduler: 51,
  editar_categoria_scheduler: 52,
  excluir_categoria_scheduler: 53,
  adicionar_nova_agenda_scheduler: 54,
  editar_agenda_scheduler: 55,
  excluir_agenda_scheduler: 56,
  atualizar_endereco_empresa: 57,
  listar_boleto: 58,
  adicionar_novo_boleto: 59,
  painel_gerencial:104,

  indicacao_premiada: 99,
};

export default codesEnum;
