import React, { useContext } from 'react';
import { AuthConsumer } from '../context/authContext';
import { Route } from 'react-router-dom';
import { PrivateRoute } from './privateRoute';
import { VerifyRoot } from './verifyRoot';
import { Logout } from '../pages/auth/Logout';
import { SilentRenew } from '../pages/auth/SilentRenew';
import { allFinancialRoutes } from './financialRoutes';
import Login from '../pages/auth/Login';
import FirstLogin from '../pages/auth/firstUser';
import OptionsSignIn from '../pages/auth/OptionsSignIn'
import ActivationUser from '../pages/auth/activationUser';
import LoadingFreeTest from '../pages/auth/loadingFreeTest';
import StepsFreeTest from '../pages/auth/stepsFreeTest/index';
import DeleteCompany from '../pages/auth/company/deleteCompany';
import RecoveryEmailUser from '../pages/auth/recoveryEmailUser';
import NewPasswordUser from '../pages/auth/newPasswordUser';
import ActivationUserSystem from '../pages/auth/activationUserSystem';
import Apresentation from '../pages/auth/company/apresentation';
import Conclusion from '../pages/auth/company/conclusion';
import WizardIncludeCompany from '../pages/auth/company/wizardIncludeCompany';
import UpdatePayment from '../pages/auth/company/updatePayment';
import DowngradePayment from '../pages/auth/company/downgrade/downgradePayment';
import CreateAccountSegment from '../pages/auth/createAccount/index';
import LoadingLinkUserAccountant from '../pages/auth/company/loadingLinkUserAccountant';
const MyProfile = React.lazy(() => import('../pages/auth/myProfile/index'));
const Support = React.lazy(() => import('../pages/auth/support/index'));
const Module = React.lazy(() => import('../pages/modules'));
const TermAccept = React.lazy(() => import('../pages/auth/termAccept'));
const RouteNotFound = React.lazy(() => import('../pages/Homes/financial/routeNotFound/index'))
const PaymentPage = React.lazy(() => import('../pages/Homes/financial/payment/index'));
const CheckoutEnd = React.lazy(() => import('../pages/Homes/financial/payment/CheckoutEnd'));


//TELA INICIAL DE SELEÇÃO DE MODULOS
const rootRoute = {
    path: '/',
    exact: true,
    component: VerifyRoot,
    route: PrivateRoute,
};

const modulesRoutes = {
    path: '/modules',
    name: 'Inicio',
    icon: 'uil-home-alt',
    component: Module,
    route: PrivateRoute,
    header: 'Modules',
};

//Tela de apresentação
const apresentationRoutes = {
    path: '/apresentation',
    name: 'Apresentação',

    component: Apresentation,
    route: PrivateRoute,
    header: 'Apresentation',
};

//Tela de conclusão
const conclusionRoutes = {
    path: '/conclusion',
    name: 'Conclusão',

    component: Conclusion,
    route: PrivateRoute,
    header: 'Conclusion',
};

//Wizard de inclusão de empresa, 1° acesso
const wizardIncludeCompanyRoutes = {
    path: '/wizard-include-company',
    name: 'Inclusão de Empresa',

    component: WizardIncludeCompany,
    route: PrivateRoute,
    header: 'Include Company',
};

//ROTAS DE AUTENTICAÇÃO
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/silentrenew',
            name: 'Logout',
            component: SilentRenew,
            route: Route,
        },
        {
            path: '/account/first-user',
            name: 'FirstUser',
            component: OptionsSignIn,
            route: Route,
        },
        {
            path: '/account/create-first-user',
            name: 'CreateFirstUser',
            component: FirstLogin,
            route: Route
        },
        {
            path: '/entitlement/account/activation-user/:token',
            name: 'ActivationUser',
            component: ActivationUser,
            route: Route,
        },
        {
            path: '/entitlement/account/delete-company/:token',
            name: 'ActivationUser',
            component: DeleteCompany,
            route: Route,
        },
        {
            path: '/entitlement/account/loading-free-test',
            name: 'LoadingFreeTest',
            component: LoadingFreeTest,
            route: Route,
        },

        {
            path: '/entitlement/account/steps-free-test',
            name: 'StepsFreeTest',
            component: StepsFreeTest,
            route: Route,
        },
        {
            path: '/account/recovery-user',
            name: 'RecoveryUser',
            component: RecoveryEmailUser,
            route: Route,
        },
        {
            path: '/entitlement/account/new-password/:token',
            name: 'NewPasswordUser',
            component: NewPasswordUser,
            route: Route,
        },
        {
            path: '/account/profile',
            name: 'Perfil',
            route: PrivateRoute,
            component: MyProfile,
            route: PrivateRoute,
        },
        {
            path: '/account/support',
            name: 'Suporte',
            route: PrivateRoute,
            component: Support,
            route: PrivateRoute,
        },

        {
            path: '/entitlement/confirmar-cadastro/:token',
            name: 'ActivationUser',
            component: ActivationUserSystem,
            route: Route,
        },
        {
            path: '/account/term-accept',
            name: 'TermAccept',
            component: TermAccept,
            route: Route,
        },
        {
            path: '/account/update-payment',
            name: 'UpdatePayment',
            component: UpdatePayment,
            route: Route,
        },
        {
            path: '/account/downgrade-payment',
            name: 'DowngradePayment',
            component: DowngradePayment,
            route: Route,
        },

        {
            path: "/criar-conta/:tipo",
            name: 'Nova Conta',
            component: CreateAccountSegment,
            route: Route
        },
        {
            path: '/entitlement/account/criar-conta/:tipo/:params',
            name: 'SignInAccountant',
            component: CreateAccountSegment,
            route: Route,
        },
        {
            path: '/entitlement/link-user-old-accountant/:params',
            name: 'LinkUserAccountant',
            component: LoadingLinkUserAccountant,
            route: Route
        }


    ],
};

const notFoundRoutes = {
    path: '*',
    name: 'Rota Não Encontrada',
    component: RouteNotFound,
    route: PrivateRoute,
    header: 'Route Not Found',
};



const paymentRoutes = {
    path: '/payment',
    name: 'Pay',
    children: [
        {
            path: '/payment/sign/:ids',
            name: 'Pay',
            component: PaymentPage,
            route: Route,
        },
        // {
        //     path: '/payment/basico',
        //     name: 'Pay',
        //     component: PaymentPage,
        //     route: Route,
        // },
        // {
        //     path: '/payment/pro',
        //     name: 'Pay',
        //     component: PaymentPage,
        //     route: Route,
        // },
        {
            path: '/finish-checkout',
            name: 'Finish Checkout',
            component: CheckoutEnd,
            route: Route,
        }
    ]
}
// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

const allRoutes = [
    rootRoute,
    authRoutes,
    modulesRoutes,
    apresentationRoutes,
    paymentRoutes,
    conclusionRoutes,
    wizardIncludeCompanyRoutes,
    ...allFinancialRoutes,
    notFoundRoutes,
];

const authProtectedRoutes = [...allFinancialRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes, };
