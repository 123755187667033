import React, { useState, useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';

import ImgWorkingOnIt from '../../../assets/images/ImgWorkingOnIt.png'

const FreeTestStep = ({ text, image, widthImg, heightImg }) => {

  return (
    <>
      <img src={image} alt="logo" width={widthImg} height={heightImg} />
      <br />
      <br />
      <br />
      <span className="span-text">{text}</span>
      <br />
      <br />
    </>
  )


}

export default FreeTestStep;