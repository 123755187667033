import api from '../services/api';

export const GetAllCompanies = async () => {
    try {
        const { data } = await api.post(`/entitlement/usuario/get-by-email`);
        return data.data;
    } catch (e) {
        console.log(e)
        return [];
    }
};


export const LoginEmp = async (email, empresa_id) => {
    try {
        const { data } = await api.post(`/entitlement/usuario/counter-login`, { email, empresaId: empresa_id });

        return data.token;
    } catch (e) {
        console.log(e)
        return null;
    }
};

export const SendEmailInvite = async (body) => {
    return await api
        .post(`/entitlement/usuario/enviar-email-convite-cliente-contabilidade`, body)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
}

export const LinkCompanyToAccountant = async (IdCompany, IdCompanyAccountant) => {
    try {
        const { data } = await api.post(`/entitlement/usuario/link-company-to-accountant`, { IdCompany, IdCompanyAccountant });

        return data;
    } catch (e) {
        console.log(e)
        return null;
    }
}


