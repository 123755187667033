import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Label } from '@progress/kendo-react-labels';
import { TextArea } from '@progress/kendo-react-inputs';
import { Hint } from '@progress/kendo-react-labels';
import { Row, Col } from 'reactstrap';

export default function AboutCompanyInsight({ sectorQuestion }) {
    const { control, errors, setValue, formState, trigger } = useFormContext();
    setValue('isValid', formState.isValid);
    const maxCharacters = 200;
    const [lengthResponse, setLengthResponse] = useState(0);

    const pergunta = {
        explanation: 'AntonIA, sua parceira de IA, aguarda para começar com uma primeira análise personalizada do seu negócio.',
        disclaimer: '* Sua descrição será utilizada para gerar sua primeira análise',
        placeholder: 'Tenho uma agência de marketing digital e ofereço serviços de criação de sites, gestão de redes sociais e anúncios online...',
    };

    return (
        <>
            <Row key={sectorQuestion.pergunta_id} className="inputForm">
                <Col lg={12}>
                    <p className="paragraph1">{sectorQuestion.pergunta}</p>
                    <Label style={{fontSize: '1rem'}}>{pergunta.explanation}</Label>
                    <Controller
                        name={'pergunta' + sectorQuestion.pergunta_id}
                        control={control}
                        rules={{ required: true }}
                        render={({ onChange, value }) => (
                            <>
                                <TextArea
                                    name={'pergunta' + sectorQuestion.pergunta_id}
                                    id={'pergunta' + sectorQuestion.pergunta_id}
                                    onChange={(e) => {
                                        onChange(e.value);
                                        setLengthResponse(e.value.length);
                                        //Chama validação
                                        trigger(e.name);
                                    }}
                                    placeholder={pergunta.placeholder}
                                    style={{ width: '100%', resize: 'none' }}
                                    rows={6}
                                    required={errors['pergunta' + sectorQuestion.pergunta_id] ? true : false}
                                    value={value}
                                    maxLength={maxCharacters}
                                />
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Label style={{color: '#3264d1', fontSize: '0.85rem'}}>{pergunta.disclaimer}</Label>
                                    <Hint>
                                        {lengthResponse} / {maxCharacters}
                                    </Hint>
                                </div>
                            </>
                        )}
                    />

                    {errors['pergunta' + sectorQuestion.pergunta_id] && (
                        <span className="requiredValidator">Por favor, informe a descrição do seu negócio.</span>
                    )}
                </Col>
            </Row>
        </>
    );
}
