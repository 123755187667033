import api from '../services/api';

// export const GetCurrentBalance = async (dateNow = GetCurrentDate()) => {
//     try {
//         const { data } = await api.get(`/analytics/dashboards/current_balance/${dateNow}`);
//         return data.data;
//     } catch {
//         return false;
//     }
// };

// export const sendFeedback = async (id, feedback) => {
//     const body = {
//         response: feedback,
//         comment: null,
//     };
//     try {
//         const { data } = await api.post(`/notify-erp/notifications/feedback/${id}`, body);
//         return true;
//     } catch {
//         return false;
//     }
// };

export const SendSectorInsight = (sector, phoneNumber) => {
    const body = {
        company_sector: sector,
        phone_number: phoneNumber,
    };
    try {
        api.post(`/msorchestrator/insights/generate_sector_analysis`, body);
        return true;
    } catch {
        return false;
    }
};
