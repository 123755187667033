import React from 'react';
import { MaskedTextBox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { TagObrigatorio } from './../TagObrigatorio.js';
export class MaskedTextBoxKendo extends React.Component<MaskedTextBoxType> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { onChange, required, name, id, label, width, mask, isRequired, value, maxLength, disabled } = this.props;

        return (
            <>
                <Label>
                    {label} {isRequired ? <TagObrigatorio /> : null}
                </Label>
                <MaskedTextBox
                    name={name}
                    id={id}
                    width="100%"
                    onChange={(e) => onChange(e)}
                    mask={mask}
                    style={{ width: '100%' }}
                    required={required}
                    maxLength={maxLength}
                    value={value}
                    disabled={disabled}
                />
                {required && <span className="requiredValidator">O campo {label} é obrigatório</span>}
            </>
        );
    }
}

type MaskedTextBoxType = {
    onChange: Function,
    required: Boolean,
    red: Boolean,
    disabled: Boolean,
    name: String,
    id: String,
    label: String,
    width: String,
    mask: String,
    isRequired: Boolean,
    value: String,
    maxLength: String,
};
