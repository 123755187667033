

export const modulesSystem = [
    {
        img: require("../assets/images/financials/financas.svg"),
        className: "financial-logo",
        svgClassName: "svg-financial",
        title: "FINANÇAS",
        color: "#0acf97",
        module: "/financial",
        isCounter: false
    },
    {
        img: require("../assets/images/icon/S-agenda.svg"),
        className: "agenda-logo",
        svgClassName: "svg-agenda",
        textClassName: "agenda-logo-text",
        title: "AGENDA",
        module: "/module/scheduler",
        color: "#ff9445",
        isCounter: false
    },
    {
        img: require("../assets/images/icon/contador.svg"),
        className: "counter-logo",
        svgClassName: "svg-counter",
        textClassName: "counter-logo-text",
        title: "CONTADOR",
        module: "/module/counter/dashboard",
        color: "#2b0acf",
        isCounter: true
    }
]