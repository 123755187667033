import React from 'react';
import './../../../assets/scss/custom/pages/Auth/apresentation.scss';
import { Button } from '@progress/kendo-react-buttons';

import Welcome from './../../../assets/images/Welcome.png';
import ConcludedCompany from './../../../assets/images/ConcludedCompany.png';

export default function Apresentation({
    isWelcome,
    onClickContinue,
    onClickIgnore,
}) {

    const getCurrentPage = () => {
        if (isWelcome) {

            return (<div className="apresentation">
                <img src={Welcome} height="200" alt="" style={{ marginTop: '30px' }} />
                <span>Olá!</span>
                <p className="paragraph1">Precisamos concluir o cadastro da sua empresa no Simplific.</p>
                <div style={{ width: '80%' }}>
                    <p className="paragraph2">
                        Queremos conhecer você e seu negócio! Em poucos cliques poderá usar o
                        Simplific Gestão com todas as funcionalidades disponíveis para uma gestão simples.
                    </p>
                </div>

                <div style={{ marginBottom: '20px' }}>
                    {/* <Button
                        className="k-primary k-button button-grey-text-blue"
                        type="button"
                        onClick={(e => (onClickIgnore()))}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Iniciar">
                        Mais Tarde
                    </Button> */}
                    <Button
                        //style={{ marginLeft: '15px' }}
                        className="k-primary k-button"
                        type="button"
                        onClick={(e => (onClickContinue ? onClickContinue() : (window.location = '/home/dashboard')))}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Concluir Agora">
                        Concluir Agora
                    </Button>
                </div>
            </div>);

        } else {

            return (<div className="apresentation">
                <img src={ConcludedCompany} height="200" alt="" style={{ marginTop: '30px' }} />
                <span>Parabéns!</span>
                <p className="paragraph1">Cadastro concluído com sucesso.</p>
                <div style={{ width: '80%' }}>
                    <p className="paragraph2">
                        Vamos juntos simplificar a gestão do seu negócio
                    </p>
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <Button
                        // style={{ marginLeft: '15px' }}
                        className="k-primary k-button pendo_initialize"
                        type="button"
                        //onClick={(e) => (window.location = '/wizard-include-company')}
                        onClick={(e => (onClickIgnore ? onClickIgnore() : (window.location = '/home/dashboard')))}
                        data-toggle="tooltip"
                        data-placement="top"
                        id="pendo_initialize"
                        title="Fechar">
                        Fechar
                    </Button>
                </div>
            </div>)
        }
    }


    return getCurrentPage();
}
