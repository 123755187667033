import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Alert } from "reactstrap";
import ReactHtmlParser from 'react-html-parser';
import RenderHtmlCommunication from './RenderHtmlCommunication';
import RenderJsonCommunication from './RenderJsonCommunication';
import RenderCarouselCommunication from './RenderCarouselCommunication';

import '../../../../src/assets/scss/custom/components/Relevo/relevoCommunicationCard.scss'

const RelevoCommunicationCard = ({
    type, /* tipos do card 2-html 4-json (de acordo com a tabela no banco typecomunication) */
    isOpen,
    toggle, /* função de click fora do modal ou no botão close */
    className,
    modalId,
    content /* conteudo do card, pode ser um html, json e etc*/ //
}) => {
    var [html, setHtml] = useState(null)

    useEffect(() => {
        switch (type) {
            case 2:
            case 'HTML':
                setHtml(<RenderHtmlCommunication html={content} />)
                break;
            case 4:
            case 'JSON':
                setHtml(<RenderJsonCommunication json={content} />)
                break;
            case 99:
                setHtml(<RenderCarouselCommunication />);
                break;
        }

    }, [])


    return (
        <Modal
            zIndex={99999}
            id={modalId}
            className={className}
            toggle={toggle}
            isOpen={isOpen}
        >
            <div style={{ width: '100%', height: '100%', borderRadius: 20 }}>
                <div style={{ width: '100%', padding: 20 }}>
                    <span onClick={toggle} style={{ position: 'absolute', right: -30, top: 0, fontSize: 'xx-large', color: 'white', cursor: 'pointer' }} className={`k-icon k-i-close `}></span>
                </div>

                {html}

            </div>

            {ReactHtmlParser(`
                <style> 
                    .modal-content { 
                        background-color: transparent!important; 
                        border-radius: 
                    }

                <style>
            `)}
        </Modal>
    )

}


export default RelevoCommunicationCard