import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { Checkbox } from '@progress/kendo-react-inputs';

export default function Interests({ interestsAll }) {
    const { control, errors, getValues, setValue, formState } = useFormContext();

    const checkBoxLabel = (item) => {
       if(item.em_breve){
           return (
               <a>{item.interesse} <a style={{color: '#2c60d0'}}>{" (em breve)"}</a></a>
           )
       }else{
           return(
               <a>{item.interesse}</a>
           )
       }
    };

    return (
        <>
            <div className="container">
                <Row className="inputForm">
                    <Col lg={12} 
                    //xs={6}
                    >
                        <h4>Conta pra gente, como podemos simplificar a gestão do seu negócio ?</h4>
                        <a>Escolha até 6 funcionalidades</a>
                    </Col>
                </Row>
                {interestsAll.map((item) => (
                    <Row key={'check' + item.interesse_id} className="inputForm">
                        <Col 
                        //xs={6} 
                        lg={12}>
                            <Controller
                                name={'check' + item.interesse_id}
                                id={'check' + item.interesse_id}
                                control={control}
                                render={({ onChange, value }) => (
                                    <Checkbox
                                        name={'check' + item.interesse_id}
                                        id={'check' + item.interesse_id}
                                        onChange={(e) => {
                                            onChange(e.value);
                                        }}
                                        label={checkBoxLabel(item)}
                                        checked={value}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                ))}
            </div>
        </>
    );
}
