import api from '../services/api';

export const SendMsgToSpecialist = async (phone, message) => {
    if (!phone || !message) return false;
    try {
        const { data, status } = await api.post(`/notify-erp/whatsapp/especialista`,  { phone, message } );
        return { msg: data.data, status };
    } catch (e){
        const { data, status } = e.response
        return { msg: data.data , status }
    }
};

export const SendMsgSupport = async (phone, message) => {
    if (!phone || !message) return false;
    try {
        const { data, status } = await api.post(`/notify-erp/whatsapp/support`,  { phone, message } );
        return { msg: data.data, status };
    } catch (e){
        const { data, status } = e.response
        return { msg: data.data , status }
    }
};
