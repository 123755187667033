import React from 'react';
import './../../../assets/scss/custom/pages/Auth/apresentation.scss';
import { Button } from '@progress/kendo-react-buttons';

export default function Conclusion() {
    return (
        <div className="apresentation">
            <span> Parabéns!</span>
            <p className="paragraph1">Cadastro concluído com sucesso.</p>
            <p className="paragraph2">Vamos juntos, simplificar a gestão do seu negócio.</p>
            <Button
                className="k-primary k-button k-grid-edit-command"
                type="button"
                onClick={(e) => (window.location = '/home/dashboard')}
                data-toggle="tooltip" 
                data-placement="top" 
                title="Acessar">
                Acessar
            </Button>
        </div>
    );
}
