import React from 'react';

import { PrivateRoute } from './privateRoute';
import permissionEnum from '../types/enum/permissionEnum';
import codesEnum from '../types/enum/codesEnum';
import { schedulerRoutes, configurationSchedulerRoutes } from './schedulerRoutes';
import { counterRoutes } from './counterRoutes';
import { MetaPopover } from '../components/Relevo/RelevoPopover/MetaPopover';
import functionalitiesEnum from '../types/enum/functionalitiesEnum';
import freeTrialFuncionalitiesEnum from '../types/enum/freeTrialFuncionalitiesEnum';

const FinancialSteps = React.lazy(() => import('../pages/Homes/financial/first/financial-steps'));
const Contacts = React.lazy(() => import('../pages/Homes/financial/customers&suppliers/include/index'));
const ContactList = React.lazy(() => import('../pages/Homes/financial/customers&suppliers/showAll/index'));
const CheckingAccountList = React.lazy(() => import('../pages/Homes/financial/checkingAccount/showAll/index'));
const BillsToPay = React.lazy(() => import('../pages/Homes/financial/billsToPay/include/index'));
const CreateEditBills = React.lazy(() => import('../pages/Homes/financial/billsToPay/newInclude/index'));
const BillsList = React.lazy(() => import('../pages/Homes/financial/billsToPay/showAll/index'));
const UpdateBillsToPay = React.lazy(() => import('../pages/Homes/financial/billsToPay/edit/index'));
const Transactions = React.lazy(() => import('../pages/Homes/financial/transactions/include/index'));
const InvoiceList = React.lazy(() => import('../pages/Homes/financial/invoices/index'));
const ServiceList = React.lazy(() => import('../pages/Homes/financial/services/index'));
const ServiceConfiguration = React.lazy(() => import('../pages/Homes/financial/services/configuration/index'));
const TicketList = React.lazy(() => import('../pages/Homes/financial/services/tickets/index'));

const FinancialPlanning = React.lazy(() => import('../pages/Homes/financial/financialPlanning/index'));

const TransactionList = React.lazy(() => import('../pages/Homes/financial/transactions/showAll/index'));
const Categories = React.lazy(() => import('../pages/Homes/financial/categories/index'));
const Projects = React.lazy(() => import('../pages/Homes/financial/projects/index'));
const Groups = React.lazy(() => import('../pages/Homes/financial/groups/index'));
const ExtractReport = React.lazy(() => import('../pages/Homes/financial/reports/extract/index'));
//const MyProfile = React.lazy(() => import('../pages/Homes/financial/myProfile/index'));
const CashFlow = React.lazy(() => import('../pages/Homes/financial/reports/cash_flow'));
const Users = React.lazy(() => import('../pages/Homes/financial/users/index'));
const DRE = React.lazy(() => import('../pages/Homes/financial/reports/DRE'));
const Dash = React.lazy(() => import('../pages/Homes/financial/dashboard'));
const DashboardManagement = React.lazy(() => import('../pages/Homes/financial/dashboardManagement'));
const CompanyInsideSystem = React.lazy(() => import('../pages/Homes/financial/companyInsideSystem/index'));
const BatchRegistration = React.lazy(() => import('../pages/Homes/financial/batchRegistration/index'));
const BatchRegistrationContatcsFileUpload = React.lazy(() =>
    import('../pages/Homes/financial/batchRegistration/customersSupliers/fileUpload')
);
const BatchRegistrationBillsFileUpload = React.lazy(() =>
    import('../pages/Homes/financial/batchRegistration/billsToPay/fileUpload')
);
const ExportData = React.lazy(() => import('../pages/Homes/financial/exportData/index'));
const ExportBillsContacts = React.lazy(() => import('../pages/Homes/financial/exportData/exportBillsContacts'));

const CurrentAccount = React.lazy(() => import('../pages/Homes/financial/checkingAccount/newPage'));
const ListAllTickets = React.lazy(() => import('../pages/Homes/financial/ticket/index'));

// dashboards
// COMUNICAR TIME DE MARKETING SE HOUVER ALTERAÇÃO NESSA PARTE DO CÓDIGO.
const dashboardRoutes = {
    path: '/financial',
    component: Dash,
    roles: permissionEnum.AcessarRelatorios,
    route: PrivateRoute,
};

const mktDashboardRoutes = {
    path: '/mkt',
    component: Dash,
    roles: permissionEnum.AcessarRelatorios,
    route: PrivateRoute,
    children: [
        // URL 1 - LOGIN E USO INTERNO
        {
            path: '/mkt/dashboard-1',
            route: PrivateRoute,
            component: Dash,
        },
        // URL 2 - CADASTROS GRATUITOS
        {
            path: '/mkt/dashboard-2',
            route: PrivateRoute,
            component: Dash,
        },
        // URL 3 - CADASTROS PAGOS
        {
            path: '/mkt/dashboard-3',
            route: PrivateRoute,
            component: Dash,
        },
    ],
};

// COMUNICAR TIME DE MARKETING SE HOUVER ALTERAÇÃO NESSA PARTE DO CÓDIGO.
const homePageRoutes = {
    path: '/home',
    name: 'Inicio',
    icon: 'uil-home-alt',
    component: FinancialSteps,
    roles: permissionEnum.AcessarRelatorios,
    route: PrivateRoute,
    children: [
        {
            path: '/home/dashboard',
            name: 'Painel Operacional',
            route: PrivateRoute,
            component: Dash,
        },

        {
            path: '/management/dashboard-management',
            name: 'Painel Gerencial',
            route: PrivateRoute,
            component: DashboardManagement,
            codeFunctionalinity: functionalitiesEnum.BLOCK_MENU_DASHBOARD_MANAGER,
        },
    ],
};

const ClientesFornecedoresRoutes = {
    path: '/contacts',
    id: MetaPopover.button_menu_custommers_and_suppliers,
    name: 'Clientes e Fornecedores',
    icon: 'dripicons-user-group',
    component: Contacts,
    roles: permissionEnum.AcessarClientesFornecedores,
    route: PrivateRoute,

    children: [
        {
            path: '/contacts/create',
            name: 'Incluir',
            component: Contacts,
            route: PrivateRoute,
            id: MetaPopover.button_include_customers,
            roles: permissionEnum.AcessarClientesFornecedores,
        },
        {
            path: '/contacts/list',
            name: 'Exibir Todos',
            component: ContactList,
            roles: permissionEnum.AcessarClientesFornecedores,
            route: PrivateRoute,
            codeId: codesEnum.listar_clientes_fornecedores,
        },
    ],
};

const ContasAPagarRoutes = {
    path: '/accounts/payments',
    name: 'Contas a Pagar',
    id: MetaPopover.button_menu_bills_to_pay,
    icon: 'mdi mdi-trending-down',
    component: BillsToPay,
    roles: permissionEnum.AcessarContasAPagar,
    route: PrivateRoute,
    children: [
        {
            path: '/accounts/payments/create',
            name: 'Incluir',
            id: MetaPopover.button_include_bills_to_pay,
            component: CreateEditBills,
            roles: permissionEnum.AcessarContasAPagar,
            route: PrivateRoute,
        },
        {
            path: '/accounts/payments/list',
            name: 'Exibir Todos',
            component: BillsList,
            roles: permissionEnum.AcessarContasAPagar,
            route: PrivateRoute,
            codeId: codesEnum.listar_contas_pagar,
        },
    ],
};

const ContasAReceberRoutes = {
    path: '/conta/receber',
    name: 'Contas a Receber',
    icon: 'mdi mdi-trending-up',
    component: BillsToPay,
    roles: permissionEnum.AcessarContasAReceber,
    route: PrivateRoute,
    children: [
        {
            path: '/conta/receber/new-incluir',
            name: 'Incluir',
            id: MetaPopover.button_include_bills_to_receive,
            component: CreateEditBills,
            roles: permissionEnum.AcessarContasAReceber,
            route: PrivateRoute,
        },
        {
            path: '/conta/receber/list',
            name: 'Exibir Todos',
            component: BillsList,
            roles: permissionEnum.AcessarContasAReceber,
            route: PrivateRoute,
            codeId: codesEnum.listar_contas_receber,
        },
    ],
};

const ContasCorrentesRoutes = {
    path: '/conta/corrente',
    name: 'Contas Correntes',
    icon: 'mdi mdi-poll',
    roles: permissionEnum.AcessarContasCorrentes,
    route: PrivateRoute,
    children: [
        // {
        //     path: '/conta/corrente/incluir',
        //     name: 'Incluir',
        //     component: Accounts,
        //     roles: permissionEnum.AcessarContasCorrentes,
        //     route: PrivateRoute,
        // },
        {
            path: '/conta/corrente/incluir',
            name: 'Incluir', /// NEW
            component: CurrentAccount,
            id: MetaPopover.button_include_bank_account,
            roles: permissionEnum.AcessarContasCorrentes,
            route: PrivateRoute,
        },
        {
            path: '/conta/corrente/list',
            name: 'Exibir Todos',
            component: CheckingAccountList,
            roles: permissionEnum.AcessarContasCorrentes,
            route: PrivateRoute,
            codeId: codesEnum.listar_contas_correntes,
        },
        {
            path: '/conta/corrente/extrato',
            name: 'Extrato',
            component: ExtractReport,
            roles: permissionEnum.AcessarContasCorrentes,
            route: PrivateRoute,
        },
    ],
};

const EditarRoutes = {
    children: [
        // {
        //     path: '/conta/corrente/editar/:id',
        //     component: Accounts,
        //     roles: permissionEnum.AcessarContasCorrentes,
        //     route: PrivateRoute,
        // },
        {
            path: '/conta/corrente/editar/:id',
            component: CurrentAccount,
            roles: permissionEnum.AcessarContasCorrentes,
            route: PrivateRoute,
        },
        {
            path: '/contacts/editar/:id',
            component: Contacts,
            roles: permissionEnum.AcessarClientesFornecedores,
            route: PrivateRoute,
        },
        {
            path: '/accounts/payments/edit-old/:id',
            component: UpdateBillsToPay,
            roles: permissionEnum.AcessarContasAPagar,
            route: PrivateRoute,
        },
        {
            path: '/accounts/payments/edit/:id',
            component: CreateEditBills,
            roles: permissionEnum.AcessarContasAPagar,
            route: PrivateRoute,
        },
        {
            path: '/conta/receber/edit/:id',
            component: CreateEditBills,
            roles: permissionEnum.AcessarContasAReceber,
            route: PrivateRoute,
        },
        {
            path: '/conta/receber/edit-old/:id',
            component: UpdateBillsToPay,
            roles: permissionEnum.AcessarContasAPagar,
            route: PrivateRoute,
        },
        {
            path: '/movimentacoes/edit/:id',
            component: Transactions,
            roles: permissionEnum.AcessarMovimentacoes,
            route: PrivateRoute,
        },
    ],
};

const MovimentacoesContasCorrentesRoutes = {
    path: '/movimentacoes',
    name: 'Transferência entre Contas',
    icon: 'uil-exchange-alt',
    component: Transactions,
    roles: permissionEnum.AcessarMovimentacoes,
    route: PrivateRoute,
    children: [
        {
            path: '/movimentacoes/incluir',
            name: 'Incluir',
            component: Transactions,
            id: MetaPopover.button_include_transactions,
            roles: permissionEnum.AcessarMovimentacoes,
            route: PrivateRoute,
            codeFunctionalinity: functionalitiesEnum.BLOCK_MENU_BANK_TRANSACTION,
        },
        {
            path: '/movimentacoes/list',
            name: 'Exibir Todos',
            component: TransactionList,
            roles: permissionEnum.AcessarMovimentacoes,
            route: PrivateRoute,
            codeId: codesEnum.listar_transferencia_entre_contas,
        },
    ],
};

const NotasFiscaisRoutes = {
    path: '/invoices',
    name: 'Notas Fiscais',
    icon: 'uil-file-edit-alt',
    component: InvoiceList,
    roles: permissionEnum.AcessarMovimentacoes, //TO-DO: Colocar permissão
    route: PrivateRoute,
    //blocked: userService.getFunctionalityValue(functionalitiesEnum.BLOCK_MENU_INVOICE)
    codeFunctionalinity: functionalitiesEnum.BLOCK_MENU_INVOICE,
    // children:
};

const ServicosRoutes = {
    path: '/services',
    name: 'Serviços',
    icon: 'uil-clipboard-alt',
    component: ServiceList,
    roles: permissionEnum.AcessarMovimentacoes, //TO-DO: Colocar permissão
    route: PrivateRoute,
    children: [
        {
            path: '/invoices',
            name: 'Notas Fiscais',
            // icon: 'uil-file-edit-alt',
            component: InvoiceList,
            roles: permissionEnum.AcessarMovimentacoes, //TO-DO: Colocar permissão
            route: PrivateRoute,
            codeFunctionalinity: functionalitiesEnum.BLOCK_MENU_INVOICE,
            notCpfOpen: true,
            specificIdBadge: 'blocked-menu-invoices',
        },
        {
            path: '/services/list',
            name: 'Serviços',
            component: ServiceList,
            roles: permissionEnum.AcessarMovimentacoes,
            codeFunctionalinity: functionalitiesEnum.BLOCK_MENU_INVOICE,
            route: PrivateRoute,
            notCpfOpen: true,
            specificIdBadge: 'blocked-menu-list-services',
        },
        // {
        //     path: '/services/tickets/list',
        //     name: 'Boletos',
        //     component: ListAllTickets,
        //     roles: permissionEnum.AcessarMovimentacoes,
        //     route: PrivateRoute,
        //     codeFunctionalinity: functionalitiesEnum.BLOCK_MENU_TICKET,
        //     notCpfOpen: true
        // },
        {
            path: '/services/configuration',
            name: 'Configurações',
            roles: permissionEnum.AcessarMovimentacoes,
            route: PrivateRoute,
            component: ServiceConfiguration,
            codeFunctionalinity: functionalitiesEnum.BLOCK_MENU_INVOICE,
            notCpfOpen: true,
            specificIdBadge: 'blocked-menu-configuration',
        },
    ],
};

const RelatoriosRoutes = {
    path: '/report',
    id: MetaPopover.button_menu_reports,
    name: 'Relatórios',
    icon: 'uil-presentation-line',
    component: FinancialSteps,
    roles: permissionEnum.AcessarRelatorios,
    route: PrivateRoute,
    children: [
        {
            path: '/report/cashflow',
            name: 'Fluxo de Caixa',
            roles: permissionEnum.AcessarRelatorios,
            route: PrivateRoute,
            component: CashFlow,
            codeId: codesEnum.relatorio_fluxo_caixa,
        },
        {
            path: '/report/dre',
            name: 'Apuração de Resultados',
            roles: permissionEnum.AcessarRelatorios,
            route: PrivateRoute,
            component: DRE,
            codeId: codesEnum.relatorio_dre,
        },
        {
            path: '/conta/corrente/relatorio-extrato/',
            name: 'Extrato',
            component: ExtractReport,
            roles: permissionEnum.AcessarContasCorrentes,
            route: PrivateRoute,
        },
    ],
};

const FinancialPlanningRoutes = {
    path: '/financial-planning',
    name: 'Planejamento Financeiro',
    icon: 'uil-sliders-v-alt',
    component: FinancialPlanning,
    //roles: permissionEnum.AcessarMovimentacoes, //TO-DO: Colocar permissão
    route: PrivateRoute,
    //blocked: userService.getFunctionalityValue(functionalitiesEnum.BLOCK_MENU_INVOICE)
    //codeFunctionalinity: freeTrialFuncionalitiesEnum.FINANCIAL_GOALS
    codeFunctionalinity: functionalitiesEnum.BLOCK_FINANCIAL_PLANNING,
    // children:
};

const ConfiguracoesRoutes = {
    path: '/configuracoes',
    name: 'Configurações',
    icon: 'mdi mdi-settings-outline',
    component: FinancialSteps,
    roles: permissionEnum.AcessarConfiguracoes,
    route: PrivateRoute,
    children: [
        {
            path: '/configuracoes/categories',
            name: 'Categorias',
            roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: Categories,
            codeId: codesEnum.listar_categorias,
        },
        {
            path: '/configuracoes/projects',
            name: 'Projetos',
            route: PrivateRoute,
            roles: permissionEnum.AcessarConfiguracoes,
            component: Projects,
            codeId: codesEnum.listar_projetos,
        },
        {
            path: '/configuracoes/users',
            name: 'Usuários',
            roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: Users,
            codeId: codesEnum.listar_usuarios,
        },
        {
            path: '/configuracoes/groups',
            name: 'Grupos e Permissões',
            roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: Groups,
            codeId: codesEnum.listar_grupos_permissoes,
        },
        {
            path: '/configuracoes/company',
            name: 'Empresa',
            roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: CompanyInsideSystem,
            codeId: codesEnum.listar_empresa,
        },
        {
            path: '/configuracoes/batchregistration',
            name: 'Cadastro em Lote',
            roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: BatchRegistration,
            codeId: codesEnum.listar_cadastro_Lote,
        },
        {
            path: '/configuracoes/exportdata',
            name: 'Exportação de Dados',
            // roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: ExportData,
        },
    ],
};

const CadastroLoteRoutes = {
    children: [
        {
            path: '/batchRegistration/customersSupliers/fileUpload',
            roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: BatchRegistrationContatcsFileUpload,
        },
        {
            path: '/batchRegistration/billsToPay/fileUpload',
            roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: BatchRegistrationBillsFileUpload,
        },
    ],
};

const ExportDataRoutes = {
    children: [
        {
            path: '/exportData/customersSupliers/:type',
            roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: ExportBillsContacts,
        },
        {
            path: '/exportData/billsToPay/:type',
            roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: ExportBillsContacts,
        },
        {
            path: '/exportData/billsToReceive/:type',
            roles: permissionEnum.AcessarConfiguracoes,
            route: PrivateRoute,
            component: ExportBillsContacts,
        }
    ],
};

const allFinancialRoutes = [
    dashboardRoutes,
    homePageRoutes,
    mktDashboardRoutes,
    ClientesFornecedoresRoutes,
    ContasAPagarRoutes,
    ContasAReceberRoutes,
    ContasCorrentesRoutes,
    MovimentacoesContasCorrentesRoutes,
    ServicosRoutes,
    RelatoriosRoutes,
    FinancialPlanningRoutes,
    ConfiguracoesRoutes,
    EditarRoutes,
    CadastroLoteRoutes,
    schedulerRoutes,
    configurationSchedulerRoutes,
    counterRoutes,
    ExportDataRoutes,
];

export { allFinancialRoutes };
