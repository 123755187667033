import React from 'react';
import { Redirect } from 'react-router-dom';

import { Route } from 'react-router-dom';
import { AuthConsumer } from '../context/authContext';

export const VerifyRoot = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ getIsFirstAccess }) => {
                // if (getIsFirstAccess() === true) {
                //     return <Redirect to="/apresentation" />;
                // } else {
                    return <Redirect to="/financial" />;
                //}
            }}
        </AuthConsumer>
    );

    return <Route {...rest} render={renderFn(component)} />;
};
