import { GetCurrentDate } from '../helpers/Utils';
import api from "../services/api"

export const GetCurrentBalance = async (dateNow = GetCurrentDate()) => {
    try {
        const { data } = await api.get(`/analytics/dashboards/current_balance/${dateNow}`);
        return data.data;
    }
    catch {
        return false;
    }
}

export const GetCurrentRevenues = async (dateNow = GetCurrentDate()) => {
    try {
        const { data } = await api.get(`/analytics/dashboards/mensal_revenue/${dateNow}`);
        return data.data;
    }
    catch {
        return false;
    }
}

export const GetBillsAging = async (dateNow = GetCurrentDate()) => {
    try {
        const { data } = await api.get(`/analytics/dashboards/bills_aging/${dateNow}`);
        return data.data;
    }
    catch {
        return false;
    }
}

export const GetBillsToPay = async (type, date) => {
    try {
        const red = `/analytics/dashboards/bills/${type}/${date}`;
        const { data } = await api.get(red);
        return data.data;
    }
    catch {
        return false;
    }
}

export const GetChartProject = async (dateNow = GetCurrentDate()) => {
    try {
        const { data } = await api.get(`/analytics/dashboards/bills_projection/${dateNow}`);
        return data.data;
    }
    catch {
        return false;
    }
}

export const GetMargins = async (dateNow = GetCurrentDate()) => {
    try {
        const { data } = await api.get(`/analytics/dashboards/margin/${dateNow}`);
        return data.data;
    }
    catch {
        return false;
    }
}

export const GetChartProjectPerDate = async (start_date, end_date) => {
    try {
        const { data } = await api.get(`/analytics/dashboards/bills_projection_custom/${start_date}/${end_date}`);
        return data.data;
    }
    catch {
        return false;
    }
}

export const GetNotifications = async (companyId, type = 1) => {
    try {
        const { data } = await api.get(`/notify-erp/notifications/index/${companyId}/${type}`);
        return data.data;
    }
    catch {
        return [];
    }
}

export const GetEducationalNotifications = async (companyId) => {
    try {
        const { data } = await api.get(`/notify-erp/notifications/educational/${companyId}`);
        return data.data;
    }
    catch {
        return [];
    }
}

export const GetPerformanceData = async (startDate, endDate) => {
    try {
        const { data } = await api.get(`/analytics/desempenho/${startDate}/${endDate}`);
        return data;
    }
    catch {
        return [];
    }
}


export const GetRunWay = async (startDate, endDate) => {
    try {
        const { data } = await api.get(`/analytics/desempenho/runway/${startDate}/${endDate}`);
        return data;
    }
    catch {
        return [];
    }
}

export const GetPerformanceReportData = async (date) => {
    try {
        const { data } = await api.get(`/analytics/performance/report/${date}`);
        return data;
    }
    catch {
        return [];
    }
}

export const UpdateVisualizedDate = async (companyId) => {
    try {
        const { data } = await api.get(`/notify-erp/notifications/index/readNotifications/${companyId}`);
        return true;
    }
    catch {
        return false;
    }
}

export const GetNotificationsPerPeriod = async (companyId, start_date = GetCurrentDate(), end_date = GetCurrentDate()) => {
    try {
        const { data } = await api.get(`/notify-erp/notifications/index/dates/${companyId}/${start_date}/${end_date}`)
        return data.data;
    } catch {
        return [];
    }
}

export const UpdateVisualizedDateByNotificationType = async (companyId, notificationType) => {
    try {
        const { data } = await api.get(`/notify-erp/notifications/index/readNotifications/${companyId}/${notificationType}`);
        return true;
    }
    catch {
        return false;
    }
}


export const sendFeedback = async (id, feedback) => {
    const body = {
        "response": feedback,
        "comment": null
    }
    try {
        const { data } = await api.post(`/notify-erp/notifications/feedback/${id}`, body);
        return true;
    }
    catch {
        return false;
    }
}


export const GetCashPosition = async (start_date, end_date) => {
    try {
        const { data } = await api.get(`analytics/insight/balancePosition/${start_date}/${end_date}`)
        return data;
    } catch {
        return null;
    }
}

export const GetInsights = async (start_date, end_date) => {
    try {
        const { data } = await api.get(`analytics/insight/${start_date}/${end_date}`)
        return data;
    } catch {
        return null;
    }
}


export const GetManagementValues = async (year,month,type,status) =>{
    try{
        const { data } = await api.get(`analytics/dashboards/bills_indicator/${year}/${month}/${type}/${status}`)
        return data
    }
    catch{
        return null;
    }
}

export const GetBalanceProjection = async (year,month) =>{
    try{
        const { data } = await api.get(`analytics/dashboards/balance_projection/${year}/${month}`)
        return data
    }
    catch{
        return null;
    }
}

export const getGerencialProfit = async (year,month) =>{
    try{
        const {data} = await api.get(`analytics/extrato/lucro_gerencial/${year}/${month}`)
        return data
    }
    catch{
        return null
    }
}