import React, { useState, useContext } from 'react';
import RelevoModal from '../components/Relevo/RelevoModal';
import TalkToSpecialistForm from './TalkToSpecialistForm';
import { Alert } from 'reactstrap';
// import '../assets/scss/custom/pages/financial/talkToSpecialist.scss'
import AuthContext from '../context/authContext';
import { RelevoFreeUserWarn } from '../components/Relevo/UpgradePlan/RelevoFreeUserWarn';
import functionalitiesEnum from '../types/enum/functionalitiesEnum';
import { Loader } from 'react-bootstrap-typeahead';

const ModalTalkToEspecialist = () => {

  const [talk, setTalk] = useState('')
  const [phone, setPhone] = useState('')
  const [openAlert, setOpenAlert] = useState('')

  const [authorization, setAuthorization] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [errorConfirm, setErrorConfirm] = useState(false)
  const [errorTalk, setErrorTalk] = useState(false)
  const [loadRequest, setLoadRequest] = useState(false)

  const [responseTalk, setResponseTalk] = useState('')
  const [responseStatusTalk, setResponseStatusTalk] = useState(null)
  const [openCollapse, setOpenCollapse] = useState(false);
  const [showWarn, setShowWarn] = useState(false);

  const userService = useContext(AuthContext);

  const callbackTalkSpecialist = (msg, status) => {
    setResponseTalk(msg)
    setResponseStatusTalk(status)
    if (status == 200) setOpenCollapse(false)

    setTimeout(() => {
      setResponseTalk('')
      setResponseStatusTalk(null);
      setOpenModal(false);
    }, 3000)
  }

  const close = () => {
    setOpenModal(false);
  }
  const open = () => {
    if (checkBlockedFunctionality()) {
      return;
    }

    setOpenModal(true)
  }

  function checkBlockedFunctionality() {
    
    // const isBlocked = await userService.blockedFunctionality(functionalitiesEnum.BLOCK_TALK_SPECIALIST)
    const isBlocked = userService.getFunctionalityValue(functionalitiesEnum.BLOCK_SUPPORT_CHAT);
    setShowWarn(isBlocked);
    return isBlocked;
  }
  
  return (
    <>
      <li className="notification-list">
        <button class="nav-link dropdown-toggle arrow-none btn btn-link" onClick={open}>
          <span data-toggle="tooltip" data-placement="left" title="Fale com especialista" id="fale_especialista">
            <i className="mdi mdi-whatsapp noti-icon"></i>
          </span>
        </button>
      </li>

      <RelevoModal
        modalId="talk-modal"
        confirmButtonId="confirm-talk-modal"
        cancelButtonId="cancel-talk-modal"
        isOpen={openModal}
        isError={errorConfirm}
        isOpenAlert={openAlert}
        messageAlert={errorConfirm ? openAlert : 'Enviado com sucesso!'}
        onCancel={close}
        // onConfirm={confirm}
        load={loadRequest}
        showFooter={false}
        className="modal-dialog-centered modal-first-talk-specialist"
      >
        <div style={{ padding: '10px' }}>
          <p style={{ fontWeight: 'bold', color: '#2c60d0' }}>Tem alguma dúvida?</p>
          <p style={{ color: '#2c60d0' }}>No canal "Fale com o Especialista" você pode tirar dúvidas e consultar um especialista em gestão financeira.</p>
          {responseTalk && <Alert style={{ marginTop: 20, marginBottom: 20 }} color={responseStatusTalk == 200 ? 'success' : 'danger'}>
            {responseTalk}
          </Alert>}
          <TalkToSpecialistForm callbackConfirm={callbackTalkSpecialist} onCancel={close} />
        </div>
      </RelevoModal>

      <RelevoFreeUserWarn showWarn={showWarn} setShowWarn={setShowWarn} />
    </>
  )
}

export default ModalTalkToEspecialist;