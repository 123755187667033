import React from 'react'
import { convertNodeToElement } from 'react-html-parser'

export const htmlParserTransform = (node, index) => {
    /*
        função para verificar as tags html q vao ser renderizada pelo 'ReactHtmlParser'.
        Uma função para q qnd tiver um onclick ou href ele funione o evento dos mesmos, pois no 'ReactHtmlParser' renderiza apenas os texto com classes e estilos e não os eventos,
        então essa função faz funcionar os eventos inseridos nas tags htmls
    */
    if (node.type == "tag") { // a tag named a
        const { href, onclick, target } = node.attribs
        var ComponenteAux = null
        switch (node.name) {
            case 'button':
                ComponenteAux = (props) => (<button {...props}></button>)
                break
            case 'a':
                ComponenteAux = (props) => (<a {...props}></a>)
                break
            case 'div':
                ComponenteAux = (props) => (<div {...props}></div>)
                break
        }

        return ComponenteAux ? (
            <ComponenteAux
                target={target}
                className={node.attribs.class}
                href={href} // show it as the actual url, e.g. when hovered over, ..
                onClick={(event) => {
                    event.preventDefault() // .. but prevent the default behaviour
                    //	now, do whatever you want with href, for example:
                    if (['button', 'div'].includes(node.name)) {
                        try {
                            new Function(onclick)()
                        } catch (e) {
                            // console.log('ERROR', e)
                            return null
                        }
                    }
                    else if (node.name == 'a')
                        return window.open(href, "_blank")
                }}
            >
                <span pointerEvents="none">
                    {convertNodeToElement(node, index, htmlParserTransform)}
                </span>
            </ComponenteAux>
        ) : convertNodeToElement(node, index)
    }
}