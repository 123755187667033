const stepsEnum = {
    login_simplific:'login_simplific',
    cadastro_simplific:'cadastro_simplific',
    concluiu_wizard:'wizard_concluido',
    cadastro_conta_corrente:'cadastro_conta_corrente',
    cadastro_cliente_fornecedor:'cadastro_cliente_fornecedor',
    cadastro_conta_pagar_receber:'cadastro_conta_pagar_receber',
    registro_pagamento:'registro_pagamento'
};

export default stepsEnum;
