import React from 'react';

import notDesktopImg from '../../../../assets/images/not-desktop-img.png'
import logoRelevo from '../../../../assets/images/simplific.png';
// import SocialMediaIcon from '../../../../assets/images/simplific-social-media-icon.png';
// import SimplificGooglePlay from '../../../../assets/images/simplific-google-play.png';
// import SimplificAppStore from '../../../../assets/images/simplific-app-store.png';
// import SimplificAppStore from '../../../../assets/images/DownloadAppleStore.png';
// import SimplificImgMobile from '../../../../assets/images/simplific-img-mobile.png';



import '../../../../assets/scss/custom/pages/Auth/apresentation.scss';

export const NotDesktopAlert = () => {

  return (
    <>
      <div className="apresentation" style={{ minHeight: '70vh', marginTop: '80px' }}>
        <img src={logoRelevo} alt="logo" height="63" width="244" />
        <img src={notDesktopImg} height="100" alt="" style={{ marginTop: '30px' }} />
        <br />
        <span className='title-main'>Ops!</span>
        <p className="paragraph2" style={{marginLeft: '10px', marginRight: '10px', marginTop: '10px'}}>O Sistema do Simplific Gestão só está disponível acessando pelo desktop.</p>
        <p className="paragraph2">
          Entre pelo computador e acesse:
          <br />
          <a className="linksLogin" href="https://app.simplificgestao.com/">app.simplificgestao.com</a>
        </p>
      </div>
    </>
  )
}

export default NotDesktopAlert;