import React, { useState, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ModalBody, ModalHeader } from 'reactstrap';
import { htmlParserTransform } from '../../../utils/htmlParseUtils';
import AuthContext from '../../../context/authContext';
const parseJson = (json) => {
    try{
        return JSON.parse(json)
    } catch(e){
        console.log('ERROR PARSE JSON', e)
        return {}
    }
}

const  replaceUserName = (title,user) =>{
    return title.replace('nome_do_usuario_',user.nome)
}

const RenderJsonCommunication = ({ json }) => {
    json = parseJson(json)
    const userService = useContext(AuthContext);
    const user = userService.getUser()

    return (
        json ? 
        <>
            <ModalHeader style={{ padding: 0, width: '100%', height: '100%'}}>
                <img style={{ width: '100%', backgroundSize: '100% auto', borderTopLeftRadius: 20, borderTopRightRadius: 20 }} src={json.img} />
            </ModalHeader>
            <ModalBody style={{ background: '#fff', borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
                <div style={{ padding: 15}}>
                {json.title && <p style={{ fontWeight: 'bold'}}> {replaceUserName(json.title,user)} </p>}

                {json.text && <p> {json.text} </p>}
                {/* tranform chama um função para que qnd tiver o atributo onclick ou href ele adapta para q qnd renderizar o html os mesmos tbm funionem */}
                {json.html && ReactHtmlParser(json.html, { transform: htmlParserTransform})}
                </div>
                

            </ModalBody>
            
        </>
    : null
    )
}

export default RenderJsonCommunication