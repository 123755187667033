// @flow
import React, { useState } from 'react';
import { RelevoInputTextArea } from '../components/Relevo/RelevoInputTextArea';
import RelevoModal from '../components/Relevo/RelevoModal';
import { MaskedTextBoxKendo } from './Relevo/kendo/MaskedTextBox';
import { Alert, CustomInput, ModalBody, ModalHeader, Button, Spinner, Row, Col } from 'reactstrap';
import { SendMsgToSpecialist } from '../models/talkToSpecialist';

// import '../assets/scss/custom/pages/financial/talkToSpecialist.scss';


type TalkToSpecialistFormProps = {
    condensed: false,
    callbackConfirm: (msg: '', status: 400) => null,
};

const TalkToSpecialistForm = (props: TalkToSpecialistProps) => {

    const [talk, setTalk] = useState('')
    const [phone, setPhone] = useState('')
    const [openAlert, setOpenAlert] = useState('')

    const [authorization, setAuthorization] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [errorConfirm, setErrorConfirm] = useState(false)
    const [errorTalk, setErrorTalk] = useState(false)
    const [loadRequest, setLoadRequest] = useState(false)

    const submit = (e) => {
        if (!talk) {
            setErrorTalk(true)
            setTimeout(() => setErrorTalk(false), 3000)
            return
        }
        setOpenModal(true)
    }

    const confirm = async () => {
        if (!phone || !authorization) {
            setErrorConfirm(true)
            if (!authorization) {
                setOpenAlert('Clique no checkbox para autorizar o envio de informações!')
                setTimeout(() => {
                    setOpenAlert('')
                    setErrorConfirm(false)
                }, 3000)
            }
            return
        }
        await setLoadRequest(true)
        const { msg, status } = await SendMsgToSpecialist(`+55${phone.replace(/[^\w\s]|( )/g, '')}`, talk)
        setOpenAlert(msg)

        if (props.callbackConfirm) {
            props.callbackConfirm(msg, status)
            close()
            return
        }

        if (status != 200) {
            setErrorConfirm(true)
            setTimeout(() => {
                setOpenAlert('')
                setLoadRequest(false)
            }, 3000)
        } else
            setTimeout(() => close(), 2500)
    }

    const close = () => {
        setOpenModal(false)
        setErrorConfirm(false)
        setAuthorization(false)
        setLoadRequest(false)
        setPhone('')
        setOpenAlert('')
        setTalk('')
    }

    return (
        <>
            <RelevoInputTextArea
                name="talk-specialist"
                maxLength="2000"
                value={talk}
                label=""
                onChange={({ target: { value } }) => setTalk(value)}
                required={false}
                placeholder=""
            />

            {errorTalk && <Alert style={{ marginTop: 20 }} color="danger">
                Preencha o campo para prosseguir!
            </Alert>}
            <div style={{ textAlign: 'right' }}>
                <Button color="gray-200" onClick={props.onCancel} data-toggle="tooltip" data-placement="top" title={'Cancelar'}
                    style={{ marginRight: '10px' }}>
                    Cancelar
                </Button>
                <Button id="btnSend" look="outline" color="primary" onClick={submit} data-toggle="tooltip" data-placement="top" title="Enviar">
                    Enviar
                </Button>
            </div>
            <RelevoModal
                modalId="talk-modal"
                confirmButtonId="confirm-talk-modal"
                cancelButtonId="cancel-talk-modal"
                isOpen={openModal}
                isError={errorConfirm}
                isOpenAlert={openAlert}
                messageAlert={errorConfirm ? openAlert : 'Enviado com sucesso!'}
                onCancel={close}
                onConfirm={confirm}
                load={loadRequest}
                className="modal-dialog-centered"
            >
                <ModalHeader toggle={close}>Fale com um especialista</ModalHeader>

                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <ModalBody id="talk-modal-body">

                        <p> Obrigado pelo seu interesse em falar com um especialista!</p>
                        <p> Você receberá em breve uma resposta pelo WhatsApp!</p>
                        <p> Por favor, confirme o número para contato</p>

                        <MaskedTextBoxKendo
                            name="celular"
                            id="celular"
                            label="Número do WhatsApp"
                            isRequired={true}
                            width="100%"
                            onChange={({ target: { value } }) => setPhone(value)}
                            mask="(00) 00000-0000"
                            style={{ width: '100%' }}
                            required={!phone && errorConfirm}
                            maxLength="15"
                            value={phone}
                        />

                        <div style={{ marginTop: 5, fontSize: 'small' }}>
                            <CustomInput
                                type="checkbox"
                                id={`authorization-msg`}
                                label={'Autorizo o envio de informações sensíveis para este número'}
                                defaultChecked={false}
                                onClick={() => setAuthorization(!authorization)}
                                required={true}
                            />
                        </div>
                    </ModalBody>

                </div>


            </RelevoModal>
        </>
    )
}

export default TalkToSpecialistForm