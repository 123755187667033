import api from '../services/api';

const controller = '/mslogger/logs';

const controller_useflow = '/mslogger';


export const RegisterLog = async (log) => {
    return await api
        .post(`${controller}/save/${log.code_id}/${log.microservice_id}/${log.text}/${log.user_id}`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};



export const RegisterUseFlowStep = async (step) => {
    return await api
        .post(`${controller_useflow}/rd/index/mkt/useflow`, step)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
};

export const ConvertUserOnRdFlow = async () => {
    return await api
        .post(`${controller_useflow}/rd/index/mkt/deleteUser`)
        .then((p) => {
            return p;
        })
        .catch((error) => {
            return error.response;
        });
}
