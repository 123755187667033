const permissionEnum = {
    AcessarClientesFornecedores: 'erp:cliente-fornecedor',
    AcessarContasAPagar: 'erp:contas-pagar',
    AcessarContasAReceber: 'erp:contas-receber',
    AcessarContasCorrentes: 'erp:contas-corrente',
    AcessarMovimentacoes: 'erp:movimentacao-contas-correntes',
    AcessarRelatorios: 'erp:relatorios',
    AcessarConfiguracoes: 'erp:configuracoes',
};

export default permissionEnum;
