
import React, { useEffect, useContext, useState } from 'react';
import functionalitiesEnum from '../../../types/enum/functionalitiesEnum';
import AuthContext from '../../../context/authContext';



export const GetMessagePlan = (functionality) => {

  const userService = useContext(AuthContext);

  let defaultMessage = 'Para ter acesso a essa funcionalidade, você precisa subir de plano!';
  if (!functionality) {
    return defaultMessage;
  }

  const funcs = userService.getFunctionalities();
  let limit = funcs.find(x => x.Identified == functionality)?.Value || -1;
  try {
    limit = Number(limit);
  } catch (err) { }

  
  switch (functionality) {
    case functionalitiesEnum.LIMIT_ATTACHMENT:
      if (limit == 0) {
        return defaultMessage;
      }
      return `Seu plano permite apenas ${limit} anexo${limit > 1 ? 's' : ''}. Para conseguir usar mais anexos, você precisa subir de plano!`;

    case functionalitiesEnum.LIMIT_APPOINTMENT_BOOK:
      return `Seu plano permite apenas ${limit} agenda${limit > 1 ? 's' : ''}. Para conseguir usar mais agendas, você precisa subir de plano!`;

    case functionalitiesEnum.LIMIT_CATEGORY:
      return `Seu plano permite apenas ${limit} categoria${limit > 1 ? 's' : ''}. Para conseguir usar mais categorias, você precisa subir de plano!`;

    case functionalitiesEnum.LIMIT_CURRENT_ACCOUNT:
      return `Seu plano permite apenas ${limit} conta${limit > 1 ? 's' : ''} corrente${limit > 1 ? 's' : ''}. 
        Para conseguir usar mais contas correntes, você precisa subir de plano!`;

    case functionalitiesEnum.LIMIT_USER_COMPANY:
      return `Seu plano permite apenas ${limit} usuário${limit > 1 ? 's' : ''}. Para conseguir usar mais usuários, você precisa subir de plano!`;


    case functionalitiesEnum.LIMIT_NOTE_ISSUES:
      if (limit!=0){
        return `Seu plano permite apenas a emissão de ${limit} nota${limit > 1 ? 's' : ''} fisca${limit > 1 ? 'is' : 'l'} por mês. 
        Para conseguir emitir mais notas fiscais esse mês, você precisa subir de plano!`;
      }
      else{
        return `Seu plano não permite a emissão de notas fiscais. Para seguir com essa emissão, você precisa subir de plano!`
      }

    default:
      return defaultMessage;

  }


}