import { observable } from 'mobx';
import React from 'react';

const contact = {
    Name: null,
    Document: null,
    TradeMark: null,
    ContactName: null,
    phone1: null,
    phone2: null,
    email: null,
    website: null,
    companyId: null,

    address: null,

    banksAccount: null,
    registrations: null,
    contactRecommendations: null,
    billingInfo: null,
    status: true,
    documentos: [],
};

const detailsForm = {
    details: null,
};
const address_form = {
    Street: null,
    Number: null,
    Neighborhood: null,
    Details: null,
    StateId: null,
    CityId: null,
    PostalCode: null,
};

const recommendation_form = {
    InstallmentsSalesNumber: null,
    DefaultSeller: null,
    EmailInvoice: null,
    GenerateInvoice: null,
};

const bankDetails_form = {
    BankId: null,
    Agency: null,
    Account: null,
    TitDocument: null,
    TitName: null,
};

const credit_form = {
    amountReceivable: 0,
    creditLimit: 0,
    blockBilling: false,
};

const cnae_form = {
    StateRegistration: null,
    MunicipalRegistration: null,
    SuframaRegistration: null,
    CNAEId: null,
    RuralProductor: null,
    SimplesNacional: null,
    Notes: null,
};

class ContactStore extends React.Component {
    constructor() {
        super();
        this._contact = observable.box(contact);
        this._address_form = observable.box(address_form);
        this._recommendation_form = observable.box(recommendation_form);
        this._bankDetails_form = observable.box(bankDetails_form);
        this._credit_form = observable.box(credit_form);
        this._cnae_form = observable.box(cnae_form);
        this._detailsForm = observable.box(detailsForm);

        //Field Control
        this._disabledBankDetail = observable.box(true);
        this._invalidTitDocument = observable.box(false);
    }

    get contact() {
        return this._contact.get();
    }
    set contact(value) {
        this._contact.set(value);
    }

    get address_form() {
        return this._address_form.get();
    }
    set address_form(value) {
        this._address_form.set(value);
    }

    get recommendation_form() {
        return this._recommendation_form.get();
    }
    set recommendation_form(value) {
        this._recommendation_form.set(value);
    }

    get bankDetails_form() {
        return this._bankDetails_form.get();
    }
    set bankDetails_form(value) {
        this._bankDetails_form.set(value);
    }

    get credit_form() {
        return this._credit_form.get();
    }
    set credit_form(value) {
        this._credit_form.set(value);
    }

    get disabledBankDetail() {
        return this._disabledBankDetail.get();
    }
    set disabledBankDetail(value) {
        this._disabledBankDetail.set(value);
    }

    get invalidTitDocument() {
        return this._invalidTitDocument.get();
    }
    set invalidTitDocument(value) {
        this._invalidTitDocument.set(value);
    }

    get cnae_form() {
        return this._cnae_form.get();
    }
    set cnae_form(value) {
        this._cnae_form.set(value);
    }
    detailsForm;

    get detailsForm() {
        return this._detailsForm.get();
    }
    set detailsForm(value) {
        this._detailsForm.set(value);
    }
}

const store = new ContactStore();
export default store;
