import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React from "react";
import { Label } from "reactstrap";
import { TagObrigatorio } from "./TagObrigatorio";
import "../../assets/scss/custom/components/_RelevoInputSimple.scss";


export class RelevoInputTextArea extends React.Component<RelevoInputTextAreaType>{
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {
            onChange,
            required,
            name,
            label,
            maxLength,
            maxRow,
            placeholder,
            value
        } = this.props;
 
        return (
            <div className="form-group">
                <Label className="bolder contact_label">{label}{required ? <TagObrigatorio /> : null}</Label>
                <textarea
                    className="form-control"
                    name={name}
                    value={value}
                    id={name}
                    onChange={(e) => onChange(e)}
                    rows={maxRow ? maxRow : "3"}
                    placeholder={placeholder ? placeholder : null}
                    maxLength={maxLength ? maxLength : "100"}/> 
            </div>
        )
    }
}


type RelevoInputTextAreaType = {
    onChange: Function,
    required: Boolean,
    name: Boolean,
    label: String,
    maxLength: Number,
    placeholder: String,
    maxRow: Number,
    value: String
}