import React, { useState, useEffect } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import { useForm, Controller } from 'react-hook-form';
import firstUser from './../../types/auth/firstUser';
import { Row, Col } from 'reactstrap';
import './../../assets/scss/custom/pages/Auth/login.scss';
// import { default as logo, default as logoDarkRelevo, default as logoSm } from './../../assets/images/logo-beta.svg';
import logoRelevo from './../../assets/images/simplific.png';
import { Button } from '@progress/kendo-react-buttons';
import { PostIncludeUser, PostResendMail } from '../../models/auth';
import { GetLastVersionActive } from '../../models/termAccept';
import { Modal, ModalBody, ModalFooter, ModalHeader, Alert } from "reactstrap";
import { Loader } from 'react-bootstrap-typeahead';
import { Input } from '@progress/kendo-react-inputs';
import { Checkbox } from '@progress/kendo-react-inputs';
import RegexValidateOnlySpace from './../../utils/regexValidateOnlySpace';
import SpacesForRequiredFields from './../../utils/spaceForRequired';
import { Label } from '@progress/kendo-react-labels';
import productEnum from './../../types/enum/productEnum';
import { RegisterLog } from './../../models/log';
import log from './../../types/log/log';
import codesEnum from './../../types/enum/codesEnum';
import RelevoModal from '../../components/Relevo/RelevoModal';
import { PasswordValidationMeta, ConfirmPasswordValidationMeta } from './passwordValidationMeta';
import ShowMessageTerm from './ShowMessageTerm';
import { SendModalRelatorioVazio } from './../../models/notifications';

export default function NewLogin() {
    const form = useForm({
        criteriaMode: 'all',
        shouldUnregister: false,
        defaultValues: firstUser,
    });
    const [responseStatus, setResponseStatus] = useState({
        message: '',
        succsess: false,
        error: false,
    });

    const [successModal, setSuccessModal] = useState(false)
    const [resendModal, setResendModal] = useState(false)
    const [isOpenAlert, setIsOpenAlert] = useState(false)
    const [isErrorResend, setIsErrorResend] = useState(false)
    const [errorResendMessage, setErrorResendMessage] = useState(false)
    const [mailUser, setMailUser] = useState();
    const [resendLoad, setResendLoad] = useState();

    const [passwordValidation, setPasswordValidation] = useState(PasswordValidationMeta);
    const [confirmPasswordValidation, setConfirmPasswordValidation] = useState(ConfirmPasswordValidationMeta);

    const [configPassword, setConfigPassword] = useState({
        show: false,
        class: 'mdi mdi-eye-off-outline'
    })


    // const [errorConfirmPassword, setErrorConfirmPassword] = useState({ visible: false, text: '' });
    // const [errorPassword, setErrorPassword] = useState({ visible: false, text: '' });


    const [load, setLoad] = useState(false);
    const [termAccept, setTermAccept] = useState(false);

    const { handleSubmit, control, getValues, errors, trigger } = form;

    var onSubmit = async function (data, e) {
        e.preventDefault();
        setLoad(true);
        const cupom = data.indicacaoPremiada
        delete (data.indicacaoPremiada)
        if (!!termAccept) {
            data.login = data.email;
            data.termo_aceite_id = termAccept.termo_aceite_id;
            data.idsProdutos.push(productEnum.Simplific);
            data.termoAceite = true;

            if (!validatePassword(data.senha, true)) {
                setLoad(false);
                return;
            }

            if (!validateConfirmPassword(data.confirmarSenha, data.senha, true)) {
                setLoad(false);
                return;
            }

            setMailUser(data.email)
            const response = await PostIncludeUser(data);
            if (response.status === 200) {
                const data = response.data;
                try {
                    await SendModalRelatorioVazio(data.data.usuario_id);
                } catch { }

                await registerLog(codesEnum.aceite_termo_uso, data.data.usuario_id);
                // indicação premiada remover dia 23/06
                await registerLog(codesEnum.indicacao_premiada, data.data.usuario_id, cupom);
                setResponseStatus({ ...responseStatus, message: data.message, succsess: true });
                resetState(true);
            } else {
                const message = response ? response.data.message : 'Erro ao cadastrar usuário';
                setResponseStatus({ ...responseStatus, message: message, error: true });
                resetState(false);
            }
        } else {
            setResponseStatus({
                ...responseStatus,
                message: 'Não existe nenhum termo cadastrado.Não será possível cadastrar este usuário!',
                error: true,
            });
            resetState(false);
        }
    };

    const success = <Alert variant="succsess">{responseStatus.message}</Alert>;
    const customBody = <p className={'resend-modal-text-blue'}>Por favor, verifique seu e-mail e ative seu usuário.</p>

    const error = (
        <div class="alert alert-danger" role="alert">
            {responseStatus.message}
        </div>
    );

    function resetState(success) {
        setTimeout(() => {
            if (success) {
                setSuccessModal(true)
            } else {
                setResponseStatus({ ...responseStatus, succsess: false, error: false });
                setLoad(false);
            }
        }, 3000);
    }

    async function getTerm() {
        setLoad(true);
        const response = await GetLastVersionActive();

        if (response.status === 200) {
            setTermAccept(response.data.data);
        } else {
            const message = response ? response.data.message : 'Erro ao obter termo';
            setResponseStatus({ ...responseStatus, message: message, error: true });
        }

        resetState(false);
    }

    useEffect(() => {
        (async () => {
            await getTerm();
        })();
    }, []);

    function isBlank(str) {
        return (!str || /^\s*$/.test(str));
    }

    async function registerLog(code_id, user_id, text = null) {
        log.user_id = user_id;
        log.code_id = code_id;
        isBlank(text) ? log.text = 'null' : log.text = text
        await RegisterLog(log);

    }


    async function confirmResendMail(mail) {
        setResendLoad(true)
        const data = await PostResendMail(mail)
        if (data.status == 200) {
            setIsOpenAlert(true)
            setTimeout(() => {
                setResendModal(false)
                setIsOpenAlert(false)
                setResendLoad(false)
            }, 2000);
        }
        else {
            setIsOpenAlert(true)
            setIsErrorResend(true)
            setErrorResendMessage(data.data.message)
            setTimeout(() => {
                setResendLoad(false)
                setIsOpenAlert(false)
                setIsErrorResend(false)

            }, 2000);
        }

    }

    const validatePassword = (text, visible = true, update = true) => {
        let retorno = true;
        if (text.length == 0) {
            let aux = { ...PasswordValidationMeta };
            aux.value = '';
            aux.visible = true;
            aux.validations.forEach(x => {
                x.class = 'none-validated-field';
            })
            if (update) {
                setPasswordValidation(aux);
            }
            retorno = false;
        } else {
            let aux = { ...passwordValidation };
            aux.visible = true;
            aux.value = text;
            aux.validations.forEach(y => {
                let isValidated = y.validate(text);
                y.class = isValidated ? 'validated-field' : 'not-validated-field';
                retorno = retorno && isValidated;

            });
            if (update) {
                setPasswordValidation(aux);
            }
            if (update && aux.confirmPassword.length > 0 && text.length > 0) {
                let validatedConfirmPassword = getValidatedConfirmPassword(aux.confirmPassword, text);
                setConfirmPasswordValidation(validatedConfirmPassword);
            }

        }

        return retorno;
    }

    const getValidatedConfirmPassword = (confirmPassword, password) => {
        let retorno = '';
        let aux = { ...confirmPasswordValidation };
        aux.visible = true;
        aux.validations.forEach(y => {
            let isValidated = y.validate(password || passwordValidation.value, confirmPassword);
            y.class = isValidated ? 'validated-field' : 'not-validated-field';
            retorno = retorno && isValidated;
            aux.visible = !isValidated;
        });

        return aux;
    }

    const validateConfirmPassword = (text, pass = undefined, update = true) => {
        let retorno = true;
        if (text.length == 0) {
            if (update) {
                setConfirmPasswordValidation({ ...ConfirmPasswordValidationMeta, visible: false });
            }
            retorno = false;
        } else {
            let aux = getValidatedConfirmPassword(text, pass || passwordValidation.value);
            retorno = !aux.visible;
            if (update) {
                setConfirmPasswordValidation(aux);
            }
        }
        let password = { ...passwordValidation, confirmPassword: text };
        if (update) {
            setPasswordValidation(password);
        }
        return retorno;
    }

    const togglePassword = () => {
        let aux = { ...configPassword };
        aux.visible = !aux.visible;
        aux.class = aux.visible ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline';
        setConfigPassword(aux);
    }

    return (
        <>
            <div className="limiter">
                <div className="container-login100" style={{ minHeight: '95vh' }}>
                    <div>
                        <div className="wrap-login100">
                            <AvForm className="login100-form validate-form" onValidSubmit={handleSubmit(onSubmit)}>
                                <span className="login100-form-title p-b-26" style={{ marginBottom: '26px' }}>
                                    <img src={logoRelevo} alt="logo" height="63" width="244" />
                                    <br />
                                    <br />
                                    <h3 className="titleForm">Novo Cadastro</h3>
                                </span>
                                {responseStatus.error ? error : null}

                                <Row className="inputForm">
                                    <Col lg={12}>
                                        <Label>
                                            E-mail <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => (
                                                <Input
                                                    name="email"
                                                    id="email"
                                                    onChange={(e) => {
                                                        onChange(SpacesForRequiredFields(e));
                                                        //Chama validação
                                                        trigger(e.target.name);
                                                    }}
                                                    style={{ width: '100%' }}
                                                    pattern={RegexValidateOnlySpace()}
                                                    required={errors.email ? true : false}
                                                    placeholder="E-mail de cadastro"
                                                    maxLength="150"
                                                />
                                            )}
                                        />
                                        {errors.email && (
                                            <span className="requiredValidator">O campo E-mail é obrigatório</span>
                                        )}
                                    </Col>
                                </Row>

                                <Row className="inputForm">
                                    <Col lg={12}>
                                        <Label>
                                            Nome <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="nome"
                                            id="nome"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => (
                                                <Input
                                                    name="nome"
                                                    onChange={(e) => {
                                                        onChange(SpacesForRequiredFields(e));
                                                        //Chama validação
                                                        trigger(e.target.name);
                                                    }}
                                                    style={{ width: '100%' }}
                                                    pattern={RegexValidateOnlySpace()}
                                                    required={errors.nome ? true : false}
                                                    placeholder="Nome de cadastro"
                                                    maxLength="100"
                                                />
                                            )}
                                        />
                                        {errors.nome && (
                                            <span className="requiredValidator">O campo Nome é obrigatório</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="inputForm">
                                    <Col lg={12}>
                                        {/* <div className="alterPassword">
                                            <i
                                                title=" Sua senha deve conter no mínimo 8 caracteres, letra maiúscula, letra
                                            minúscula, numeral e pelo menos um caracter especial."
                                                className="mdi mdi-help-circle"></i>
                                        </div> */}
                                        <Label>
                                            Senha <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="senha"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => {
                                                if (value && value.toString().trim().length >= 1 && !passwordValidation.visible) {
                                                    validatePassword(value);
                                                }
                                                return (
                                                    <>
                                                        <Input
                                                            name="senha"
                                                            type={configPassword.visible ? 'text' : 'password'}
                                                            style={{ width: '100%' }}
                                                            placeholder="********"
                                                            onChange={(e) => {
                                                                onChange(SpacesForRequiredFields(e));
                                                                //Chama validação
                                                                validatePassword(e.target.value);
                                                                trigger(e.target.name);
                                                            }}
                                                            maxLength="30"
                                                            pattern={RegexValidateOnlySpace()}
                                                            required={errors.senha ? true : false}
                                                        />
                                                        <span
                                                            class={configPassword.class + ' eye-show-password'}
                                                            onClick={() => togglePassword()}
                                                        >

                                                        </span>
                                                    </>

                                                )
                                            }}
                                        />
                                        {passwordValidation.visible &&
                                            passwordValidation.validations.map(x => {
                                                return (<>
                                                    <span className={x.class}>{x.text}</span><br />
                                                </>)
                                            })
                                        }

                                        {/* {errorPassword.visible &&
                                            <span className="requiredValidator">{errorPassword.text}</span>
                                        } */}
                                        {/* {errors.senha && (
                                            <span className="requiredValidator">O campo Senha é obrigatório</span>
                                        )} */}
                                    </Col>
                                </Row>

                                <Row className="inputForm">
                                    <Col lg={12}>
                                        <Label>
                                            Confirmar Senha <span className="requiredValidator">*</span>
                                        </Label>
                                        <Controller
                                            name="confirmarSenha"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ onChange, value }) => (
                                                <>
                                                    <Input
                                                        name="confirmarSenha"
                                                        type={configPassword.visible ? 'text' : 'password'}
                                                        style={{ width: '100%' }}
                                                        placeholder="********"
                                                        onChange={(e) => {
                                                            onChange(SpacesForRequiredFields(e));
                                                            //Chama validação
                                                            validateConfirmPassword(e.target.value)
                                                            trigger(e.target.name);
                                                        }}
                                                        maxLength="30"
                                                        pattern={RegexValidateOnlySpace()}
                                                        required={errors.confirmarSenha ? true : false}
                                                    />
                                                    <span
                                                        class={configPassword.class + ' eye-show-password'}
                                                        onClick={() => togglePassword()}
                                                    ></span>
                                                </>
                                            )}
                                        />

                                        {confirmPasswordValidation.visible &&
                                            confirmPasswordValidation.validations.map(x => {
                                                return (<>
                                                    <span className={x.class}>{x.text}</span><br />
                                                </>)
                                            })
                                        }
                                        {/* {errorConfirmPassword.visible &&
                                            <span className="requiredValidator">{errorConfirmPassword.text}</span>
                                        } */}
                                        {errors.confirmarSenha && (
                                            <span className="requiredValidator">
                                                O campo Confirmar senha é obrigatório
                                            </span>
                                        )}
                                    </Col>
                                </Row>


                                {/* <Row className="inputForm">
                                    <Col lg={12}>
                                        <Label>Cupom de Indicação ( Se houver ) </Label>
                                        <Controller
                                            name="indicacaoPremiada"
                                            id="indicacaoPremiada"
                                            control={control}
                                            rules={{
                                                required: false,
                                            }}
                                            render={({ onChange, value }) => (
                                                <Input
                                                    name="indicacaoPremiada"
                                                    style={{ width: '100%' }}
                                                    onChange={(e) => {
                                                        onChange(e.value);
                                                    }}
                                                    maxLength="150"
                                                    required={false}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row> */}


                                {/* <Row className="inputForm"> */}
                                {/* <Col lg={1}>
                                        <Controller
                                            name="termoAceite"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ onChange, value }) => (
                                                <Checkbox
                                                    name="termoAceite"
                                                    onChange={(e) => {
                                                        onChange(e.value);
                                                    }}
                                                    label={''}
                                                    required={errors.termoAceite ? true : false}
                                                />
                                            )}
                                        />
                                    </Col> */}
                                {/* <Col lg={11}>
                                        <p className="termoAceite">
                                            Ao se cadastrar, você concorda com os{' '}
                                            <a href="/account/term-accept" target="_blank" className="linkTermo">
                                                Termos de Uso
                                            </a>
                                        </p>
                                    </Col> */}
                                {/* </Row> */}

                                <div className="container-login100-form-btn">
                                    <Button
                                        className="k-primary k-button k-grid-edit-command"
                                        type="submit"
                                        disabled={load}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Cadastrar">
                                        {!load ? 'Cadastrar' : <Loader />}
                                    </Button>
                                </div>
                            </AvForm>
                        </div>
                        <div style={{ marginTop: '5px', position: 'absolute' }}>
                            <a className="linksLogin" href="/account/first-user">
                                Voltar
                            </a>
                        </div>
                        <div className="regUser">
                            <a className="linksLogin" href="/account/login">
                                Já tenho um cadastro
                            </a>
                        </div>
                    </div>
                </div>
                <ShowMessageTerm />
            </div>

            <Modal
                zIndex={9999}
                id={'modal-confirm-mail'}
                className={'modal-confirm-mail'}
                isOpen={successModal}>

                <ModalBody className="relevo-modal-confirm">
                    <span className={'relevo-modal-confirm-img'}>
                        <img src={logoRelevo} alt="logo" height="63" width="244" />

                    </span>


                    <p className={'relevo-modal-confirm-header'}>
                        Validação de e-mail
                    </p>

                    <p className={'relevo-modal-confirm-subheader'}>
                        Bem vindo(a) ao Simplific.
                    </p>
                    <p className={'relevo-modal-confirm-text'}>
                        Você receberá um e-mail para ativação e conclusão do cadastro.
                    </p>
                    <p className={'relevo-modal-confirm-text-blue k-primary '}>
                        Por favor, verifique seu e-mail e ative seu usuário.
                    </p>
                    <a href={'#'} onClick={() => { setResendModal(true) }} className={'relevo-modal-confirm-link'}>
                        Não recebi o e-mail de ativação
                    </a>

                    <RelevoModal
                        modalId="resend-modal"
                        confirmButtonId="confirm-resend-modal"
                        headerMessage="Enviar e-mail de ativação"
                        bodyMessage={"Você receberá um e-mail para ativação e conclusão do cadastro."}
                        isOpen={resendModal}
                        onConfirm={() => { confirmResendMail(mailUser) }}
                        onCancel={() => { setResendModal(false) }}
                        confirmText={'Enviar Novamente'}
                        isOpenAlert={isOpenAlert}
                        isError={isErrorResend}
                        messageAlert={isOpenAlert && isErrorResend ? errorResendMessage : 'E-mail enviado com sucesso!'}
                        load={resendLoad}
                        customBody={customBody}
                    />

                    <div className={'relevo-modal-confirm-footer'}>
                        <Button color='primary' className={"modal-confirm-mail-button k-primary k-button"} id={'confirm-mail'} onClick={() => { window.location = '/account/login'; }} data-toggle="tooltip" data-placement="top" title="Ok">
                            {'Ok'}
                        </Button>
                    </div>

                </ModalBody>

            </Modal>
        </>
    );
}
