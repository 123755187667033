import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressBar } from "@progress/kendo-react-progressbars";
import { Loader } from 'react-bootstrap-typeahead';

import { DeleteWholeCompany } from './../../../models/company';
import { ConvertUserOnRdFlow } from './../../../models/log'
import AuthService from '../../../helpers/authService';

// import { default as logo, default as logoDarkRelevo, default as logoSm } from './../../../assets/images/logo-beta.svg';
import logoRelevo from './../../../assets/images/simplific.png';

export default function DeleteCompany() {

  const token = useParams().token;

  const { value, start, stop, reset } = useInterval(0, 50);
  const [load, setLoad] = useState(true);
  const [responseStatus, setResponseStatus] = useState({
    message: '',
    succsess: false,
    error: false,
  });

  useEffect(() => {
    deleteCompany();
  }, []);

  const deleteCompany = async () => {
    const authService = new AuthService();

    const isAuthenticated = authService.isAuthenticated()
    
    if (!isAuthenticated) {
      setResponseStatus({ ...responseStatus, message: "Você precisa estar logado para realizar esta ação. Faça login e tente novamente.", succsess: false });
      setLoad(false);
      setTimeout(() => {
        window.location = '/account/login';
      }, 4000)

    } else {
      const response = await DeleteWholeCompany(token);

      if (response.status === 200) {
        localStorage.removeItem('token');
        const result_logger = await ConvertUserOnRdFlow();
        setResponseStatus({ ...responseStatus, succsess: true });
        start();
        setTimeout(() => {
          window.location = 'https://simplificgestao.com/';

        }, 3200)
      } else {

        const message = response ? response.data.message : 'Não foi possível completar esta ação. Tente novamente mais tarde.';
        setResponseStatus({ ...responseStatus, succsess: false });
      }
      setLoad(false);
    }
  }

  function useInterval(initialValue, ms) {
    const [value, setValue] = React.useState(initialValue);
    const intervalRef = React.useRef(null);
    let val = initialValue
    const start = React.useCallback(() => {
      if (intervalRef.current !== null) {
        return;
      }

      intervalRef.current = setInterval(() => {
        if (val < 100) {
          setValue((v) => v + 1.6);
          val = val + 1.6;
        }
      }, ms);
    }, []);
    const stop = React.useCallback(() => {
      if (intervalRef.current === null) {
        return;
      }

      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }, []);
    const reset = React.useCallback(() => {
      setValue(0);
      val = initialValue;
    }, []);
    return {
      value,
      start,
      stop,
      reset,
    };
  }

  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div>
            <div className="wrap-login100">
              <span className="login100-form-title p-b-26">
                <img src={logoRelevo} alt="logo" height="63" width="244" />
                {!load && responseStatus.succsess &&
                  <>
                    <br />
                    <br />
                    <span class="k-icon k-i-check-circle icon-check"></span>
                    <br />
                    <br />
                    <span className="span-success">SUCESSO!</span>
                    <br />
                    <span className="span-text">Conta excluida com sucesso!</span>
                    <br />
                    <br />
                    <ProgressBar value={value} labelVisible={false}
                      progressClassName="progress-bar-reload"
                    />
                  </>}
                {!load && !responseStatus.succsess &&
                  <>
                    <br />
                    <br />
                    <span class="k-icon k-i-warning icon-warning"></span>
                    <br />
                    <br />
                    <span className="span-error">ERRO!</span>
                    <br />
                    <span className="span-text">{responseStatus.message || 'Houve um erro ao excluir a conta. Tente novamente mais tarde.'}</span>
                  </>}


                {load &&
                  <>
                    <br />
                    <br />
                    <span className="span-text">Excluindo a conta!</span>
                    <br />
                    <br />
                    <Loader />
                  </>
                }
              </span>

            </div>
            {!load && !responseStatus.succsess &&
              <div className="regUser">
                <a className="linksLogin" href="/">
                  Voltar para página inicial
                </a>
              </div>
            }
          </div>
        </div>

      </div>
    </>
  )
}