import React, { useState, useEffect } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import '../../../assets/scss/custom/pages/Auth/login.scss';
// import { default as logo, default as logoDarkRelevo, default as logoSm } from './../../assets/images/logo-beta.svg';
import SimplificLogoFinal from '../../../assets/images/SimplificLogoFinal.png'
import ImgWorkingOnIt from '../../../assets/images/ImgWorkingOnIt.png'
import { Button } from '@progress/kendo-react-buttons';

import { Alert } from 'reactstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { useParams } from 'react-router-dom';
import { ProgressBar } from "@progress/kendo-react-progressbars";
import '../../../pages/auth/activationUserStyle.scss';

import { LinkCompanyToAccountant } from '../../../models/counter';



const initialTimer = 500;

const LoadingLinkUserAccountant = (props) => {

  const { value, start, stop, reset, getVal } = useInterval(0, initialTimer);

  const { tipo, params } = props.match.params;


  var objInviteAccount = undefined;
  if (params) {
    objInviteAccount = JSON.parse(decodeURIComponent(params))
  }

  useEffect(() => {
    linkUserToAccountant();
  }, []);

  const linkUserToAccountant = async () => {
    var obj = objInviteAccount;
    const response = await LinkCompanyToAccountant(obj.idCompanyInvited, obj.companyAccountId);
    const message = 'Empresa vinculada com a empresa de contabilidade com sucesso. Você será redirecionado.';

    if (response != null) {
      setResponseStatus({ ...responseStatus, succsess: true, message });
      setTimeout(() => {
        window.location.href = '/mkt/dashboard-1';
      }, 4000)
    } else {
      setResponseStatus({ ...responseStatus, succsess: false, message });
    }
    setLoad(false);

  }

  const [load, setLoad] = useState(true);
  const [responseStatus, setResponseStatus] = useState({
    message: '',
    succsess: false,
    error: false,
  });


  const success = <Alert variant="succsess">{responseStatus.message}</Alert>;
  const error = (
    <div class="alert alert-danger" role="alert">
      {responseStatus.message}
    </div>
  );



  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          {/* <div style={{ width: '100%', textAlign: '-webkit-center', marginTop: '10%' }}>
            <img src={SimplificLogoFinal} alt="logo" height="63" width="244" />
          </div> */}
          <div style={{ textAlign: 'center' }}>
            <img style={{ marginBottom: '15px' }} src={SimplificLogoFinal} alt="logo" height="63" width="244" />
            <div className="wrap-login100">
              <span className="login100-form-title p-b-26" style={{ marginBottom: '0px' }}>
                <img src={ImgWorkingOnIt} alt="logo" height="244" width="244" />
                {!load && responseStatus.succsess &&
                  <>
                    <br />
                    <br />
                    <span className="span-text">Empresa vinculada com a empresa de contabilidade com sucesso. Você será redirecionado.</span>
                    <br />
                    <br />
                    <Loader />
                    <br />
                    <br />

                  </>}
                {!load && !responseStatus.succsess &&
                  <>
                    <br />
                    <br />
                    <span class="k-icon k-i-warning icon-warning"></span>
                    <br />
                    <br />
                    <span className="span-error">ERRO!</span>
                    <br />
                    <span className="span-text">Houve um erro. Tente novamente mais tarde.</span>
                    <br />
                    <br />
                    {/* <ProgressBar value={value} labelVisible={false}
                      progressClassName="progress-bar-reload"
                    /> */}

                  </>}


                {load &&
                  <>
                    <br />
                    <br />
                    <span className="span-text">Estamos vinculando sua empresa com a empresa de contabilidade e logo você poderá acessar o Simplific Gestão.</span>
                    <br />
                    <br />
                    <Loader />
                    <br />
                    <br />
                    {/* <ProgressBar value={value} labelVisible={false}
                      progressClassName="progress-bar-reload"
                    /> */}
                  </>
                }
              </span>

              {/* {responseStatus.succsess ? success : null}
                        {responseStatus.error ? error : null} */}

              {/* <div className="container-login100-form-btn">
                            <Button
                                className="k-primary k-button k-grid-edit-command"
                                type="button"
                                disabled={load}
                                onClick={(e) => activateToken()}>
                                {!load ? 'Ativar' : <Loader />}
                            </Button>
                        </div> */}
            </div>
            {!load && !responseStatus.succsess &&
              <div className="regUser">
                <a className="linksLogin" href="https://www.simplificgestao.com/">
                  Voltar para página inicial
                </a>
              </div>
            }
          </div>
        </div>

      </div>
    </>
  )




}


function useInterval(initialValue, ms) {
  const [value, setValue] = React.useState(initialValue);
  const intervalRef = React.useRef(null);
  let val = initialValue
  const start = React.useCallback((directMs) => {
    if (intervalRef.current !== null) {
      return;
    }

    intervalRef.current = setInterval(() => {
      if (val < 100) {
        setValue((v) => v + 1);
        val = val + 1;
      }
    }, directMs || ms);
  }, []);
  const stop = React.useCallback(() => {
    if (intervalRef.current === null) {
      return;
    }

    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }, []);
  const reset = React.useCallback(() => {
    setValue(0);
    val = initialValue;
  }, []);
  const getVal = React.useCallback(() => {
    return val;
  }, []);
  return {
    value,
    start,
    stop,
    reset,
    getVal
  };
}

export default LoadingLinkUserAccountant;