export const MetaPopover = {
  button_account_creation_home: 'button_account_creation_home',
  button_menu_custommers_and_suppliers: 'button_menu_custommers_and_suppliers',
  button_menu_bills_to_pay: 'button_menu_bills_to_pay',
  button_menu_reports: 'button_menu_reports',
  button_dashboard_pay_today_value: 'button_dashboard_pay_today_value',
  button_dashboard_receive_today_value: 'button_dashboard_receive_today_value',

  button_include_customers : 'button_include_customers',
  button_include_bank_account : 'button_include_bank_account',
  button_include_bills_to_pay : 'button_include_bills_to_pay',
  button_include_bills_to_receive : 'button_include_bills_to_receive',
  button_include_transactions : 'button_include_transactions'

}