const getEndpoints = () => {
    switch (baseUrl) {
        case 'http://localhost:3000':
            return {
                gateway: 'https://playground.gateway.relevolab.com',
                // gateway: 'http://localhost:80',
            };

        case 'https://playground.relevolab.com':
            return {
                gateway: 'https://playground.gateway.relevolab.com',
            };
        case 'https://staging.erp.relevolab.com':
            return {
                gateway: 'https://staging.gateway.relevolab.com',
            };
        case 'https://preprod.simplificgestao.com':
            return {
                gateway: 'https://preprod.gateway.simplificgestao.com',
            };

        case 'https://app.simplificgestao.com':
            return {
                gateway: 'https://simplific.gateway.relevolab.com',
            };
        default:
            return {
                gateway: 'https://simplific.gateway.relevolab.com',
            };
    }
};

const getOidcConfig = () => {
    switch (baseUrl) {
        case 'http://localhost:3000':
            return {
                authority: 'https://msentitlement',
                client_id: 'erp-production',
                redirect_uri: 'https://app.simplificgestao.com/account/login',
                scope: 'openid profile erp',
                post_logout_redirect_uri: 'https://app.simplificgestao.com',
                silent_redirect_uri: 'https://app.simplificgestao.com/account/silentrenew',
            };
        case 'https://playground.relevolab.com':
            return {
                authority: 'https://account.playground.relevolab.com',
                client_id: 'erp-playground',
                redirect_uri: 'https://playground.relevolab.com/account/login',
                scope: 'openid profile erp',
                post_logout_redirect_uri: 'https://playground.relevolab.com/',
                silent_redirect_uri: 'https://playground.relevolab.com/account/silentrenew',
            };
        case 'https://staging.erp.relevolab.com':
            return {
                authority: 'https://staging.account.erp.relevolab.com',
                client_id: 'erp-staging',
                redirect_uri: 'https://staging.erp.relevolab.com/account/login',
                scope: 'openid profile erp',
                post_logout_redirect_uri: 'https://staging.erp.relevolab.com',
                silent_redirect_uri: 'https://staging.erp.relevolab.com/account/silentrenew',
            };
        case 'https://preprod.simplificgestao.com':
            return {
                authority: 'https://msentitlement',
                client_id: 'erp-preprod',
                redirect_uri: 'https://preprod.simplificgestao.com/account/login',
                scope: 'openid profile erp',
                post_logout_redirect_uri: 'https://preprod.simplificgestao.com',
                silent_redirect_uri: 'https://preprod.simplificgestao.com/account/silentrenew',
            };


        case 'https://app.simplificgestao.com':
            return {
                authority: 'https://msentitlement',
                client_id: 'erp-production',
                redirect_uri: 'https://app.simplificgestao.com/account/login',
                scope: 'openid profile erp',
                post_logout_redirect_uri: 'https://app.simplificgestao.com',
                silent_redirect_uri: 'https://app.simplificgestao.com/account/silentrenew',
            };
        default:
            return {
                authority: 'https://account.playground.relevolab.com',
                client_id: 'erp-playground',
                redirect_uri: 'https://playground.relevolab.com/account/login',
                scope: 'openid profile erp',
                post_logout_redirect_uri: 'https://playground.relevolab.com/',
                silent_redirect_uri: 'https://playground.relevolab.com/account/silentrenew',
            };
    }
};

export const baseUrl = `${window.location.protocol}//${window.location.host}`;
export const gatewayUrl = getEndpoints().gateway;
export const authority = getOidcConfig().authority;
export const client_id = getOidcConfig().client_id;
export const redirect_uri = getOidcConfig().redirect_uri;
export const scope = getOidcConfig().scope;
export const post_logout_redirect_uri = getOidcConfig().post_logout_redirect_uri;
export const silent_redirect_uri = getOidcConfig().silent_redirect_uri;
