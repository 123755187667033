
import React, { useState, useEffect } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import './../../../assets/scss/custom/pages/Auth/login.scss';
// import { default as logo, default as logoDarkRelevo, default as logoSm } from './../../../assets/images/logo-beta.svg';
import SimplificLogoFinal from './../../../assets/images/SimplificLogoFinal.png'
import ImgWorkingOnIt from './../../../assets/images/ImgWorkingOnIt.png'
import { Button } from '@progress/kendo-react-buttons';
import { PostLoginFreeSimulation } from '../../../models/auth';
import { PopulateFreeTestDatabase } from '../../../models/freeTest';
import { Alert, Row, Col, Card, CardBody } from 'reactstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { useParams } from 'react-router-dom';
import { ProgressBar } from "@progress/kendo-react-progressbars";
import '../activationUserStyle.scss';

import Step1 from '../../../assets/images/steps-free-test/Step1_PainelOperacional.png';
import Step2 from '../../../assets/images/steps-free-test/Step2_PainelGerencial.png';
import Step3 from '../../../assets/images/steps-free-test/Step3_FluxoCaixa.png';
import Step4 from '../../../assets/images/steps-free-test/Step4_PagamentosRecebimentos.png';
import Step5 from '../../../assets/images/steps-free-test/Step5_SimplificDicas.png';
import Step6 from '../../../assets/images/steps-free-test/Step6_ModuloAgenda.png';


import FreeTestStep from './freeTestStep';

const steps = [
  {
    // text: `Este é o painel operacional. Nele, você tem acesso à várias informações, como suas principais contas correntes e seus saldos,
    //  suas contas à pagar e receber hoje e um resumo para os próximo dias.`,
    text: `No painel operacional, você tem acesso às suas contas correntes e seus principais compromissos financeiros do dia, 
    além de uma projeção de fluxo de caixa para os próximos dias.`,
    image: Step1,
    widthImg: "783",
    heightImg: "500",
  },
  {
    // text: `Este é o painel gerencial. Nele você consegue ver seu faturamento e sua lucratividade com uma perspectiva mais ampla, 
    // podendo fazer comparações com períodos passados.`,
    text: `No painel gerencial, trazemos seus principais indicadores de faturamento e lucratividade para você analisar sua operação financeira.`,
    image: Step2,
    widthImg: "783",
    heightImg: "500",
  },
  {
    // text: `Este é o relatório de fluxo de caixa. Nele você consegue analisar o fluxo de entrada e saída do seu caixa em um 
    // determinado período. Também é possível fazer a análise entre o fluxo de caixa previsto e o fluxo de caixa realizado.`,
    text: `No Simplific você tem acesso a diversos relatórios que te permitem entender seu histórico financeiro para tomar decisões mais assertivas.`,
    image: Step3,
    widthImg: "783",
    heightImg: "500",
  },
  {
    // text: `No painel operacional, temos estes cards que te lembram dos seus principais compromissos financeiros, 
    // que são as contas que devem ser pagas e recebidas hoje.`,
    text: `Cadastre seus principais compromissos financeiros diretamente pelo painel operacional e tenha visibilidade de tudo 
    que precisa ser feito no dia.`,
    image: Step4,
    widthImg: "783",
    heightImg: "500",
  },
  {
    // text: `Estes são os insights. Com eles você consegue analisar a posição do seu caixa em um determinado período. 
    // Neste card você consegue ver o saldo inicial, todos os pagamentos, recebimentos e o saldo final da sua empresa no período determinado.
    // Além disso, este card também mostra suas receitas por categoria, 
    // receitas por cliente, despesas por categoria e despesas por fornecedor em forma de gráficos.`,
    text: `Na seção Simplific Dicas você consegue analisar períodos específicos da sua operação e receber insights direcionados ao seu negócio.`,
    image: Step5,
    widthImg: "783",
    heightImg: "500",
  },
  {
    text: `Temos também o módulo de agenda, onde você pode cadastrar seus principais compromissos organizados por categorias.`,
    image: Step6,
    widthImg: "783",
    heightImg: "500",
  }
]

const stepItems = steps.map(x => {
  return <FreeTestStep {...x} />
})

const timer = 6000;

const StepsFreeTest = ({ }) => {

  const [step, setStep] = useState(0);
  const [load, setLoad] = useState(false);

  const clickStep = () => {
    if (step + 1 == steps.length) {
      window.location.href = '/home/dashboard';
    } else {
      setStep(step + 1);
     
    }
  }

  return (
    <>

      <div className="limiter">
        <div className="container-login100">
          <div style={{ textAlign: 'center' }}>
            <img style={{ marginBottom: '15px' }} src={SimplificLogoFinal} alt="logo" height="63" width="244" />
            <div className="wrap-login100" style={{
              width: '883px',
              //minWidth: '500px', maxWidth: (Number(steps[step].widthImg) + 200) + "px"
            }}>
              <span className="login100-form-title p-b-26" style={{ marginBottom: '0px' }}>

                {stepItems[step]}

                <Button
                  className="k-primary k-button"
                  type="button"
                  onClick={clickStep}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Avançar"
                  id={step + 1 == steps.length ? 'start_simulation' : 'advance_step_simulation'}
                  disabled={load}>
                  {load
                    ? <><Loader /><br /></>
                    : step + 1 == steps.length ? 'Iniciar Simulação' : 'Avançar'}
                </Button>

                {/* {getStep()} */}

              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default StepsFreeTest;